import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import axios from "axios";

const checkLocationIp = () => {
    return new Promise(resolve => {
        axios.get("https://www.cloudflare.com/cdn-cgi/trace").then(res => {
            var result = [];
            if (res.data.length > 0) {
                result = res.data.split("\n").filter(v => {
                    return v === "loc=VN";
                });
            }
            if (result.length > 0) {
               resolve("vi");
            } else {
               resolve("vi");
            }
        }).catch(err => {
            resolve("vi");
        });
    });
};

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        // lng: 'vi',
        fallbackLng: 'vi',
        debug: true,
        load: 'languageOnly',

        interpolation: {
           escapeValue: false, // not needed for react as it escapes by default
        }
    });

(async () => {i18n.changeLanguage(await checkLocationIp())})();

export default i18n;