const initialState = {
	isOpenSidebar: true,
	theme_using: 0,
	listallserver: [],
	listallconfig: [],
	listmenus: []
};

function websiteReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_IS_OPEN_SIDEBAR':
			return {
				...state,
				isOpenSidebar: !state.isOpenSidebar,
			};
		case 'LIST_ALL_SERVER':
			action.payload.servers.push({checktime: Math.floor(Date.now() / 1000)});
			localStorage.setItem("listallserver", JSON.stringify(action.payload.servers));
			return {
				...state,
				listallserver: action.payload.servers,
			};
		case 'LIST_ALL_SERVER_FROM_CACHE':
			return {
				...state,
				listallserver: action.payload
			};
		case 'LIST_ALL_CONFIG':
			action.payload.configs = {
				...action.payload.configs,
				checktime: Math.floor(Date.now() / 1000)
			};
			localStorage.setItem("listallconfig", JSON.stringify(action.payload.configs));
			return {
				...state,
				listallconfig: action.payload.configs,
			};
		case 'LIST_ALL_CONFIG_FROM_CACHE':
			return {
				...state,
				listallconfig: action.payload
			};
		case 'LIST_MENU':
			action.payload.menus.push({checktime: Math.floor(Date.now() / 1000)});
			localStorage.setItem("listmenu", JSON.stringify(action.payload.menus));
			return {
				...state,
				listmenus: action.payload.menus,
			};
		case 'LIST_MENU_FROM_CACHE':
			return {
				...state,
				listmenus: action.payload
			};
		default:
			return state;
	}
}

export default websiteReducer;