import { useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import { Link } from "react-router-dom";
import Listusers from "./listusers/Listusers";
import Listuserroles from "./listuserroles/Listuserroles";
import Loading from "components/theme_default/Loading";

function Main(props) {
	const [tab, setTab] = useState("listusers");

	const onClick = (data) => {
		setTab(data);
	}

	var loading = false;
	if (tab === "listuserroles") {
		loading = props.user.isLoadingRole;
	} else {
		loading = props.user.isLoading;
	}

	return (
		<div className="pd-20 bg-white border-radius-4 box-shadow mb-30">
			<div className="tab">
				<ul className="nav nav-tabs" role="tablist">
					<li className="nav-item">
						<Link className={"nav-link text-blue " + (tab === "listusers"? " active" : "")} to="#" onClick={(e) => {onClick("listusers")}}>{t("Danh sách tài khoản")}</Link>
					</li>
					<li className="nav-item">
						<Link className={"nav-link text-blue " + (tab === "listuserroles"? " active" : "")} to="#" onClick={(e) => {onClick("listuserroles")}}>{t("Loại tài khoản")}</Link>
					</li>
				</ul>
				<div className="tab-content">
					<Loading isLoading={loading}/>
					<div className="tab-pane fade active show">
						<div className="pd-20">
							{
								tab === "listuserroles" ?
									<Listuserroles />
								: <Listusers />
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	user: state.user
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);