import { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import Maintheme from "./components/Maintheme";
import Preloader from "./components/Preloader";
import { loadUser } from "./actions/userActions";
import store from "./store";

store.dispatch(loadUser());

const sleep = (ms) => {
	return new Promise((res) => setTimeout(res, ms));
}
const MainthemeLoaded = lazy(async () => {
	await sleep(1000);
	return { default: Maintheme }
})

function App() {
    return (
        <Provider store={store}>
            <Suspense fallback={<Preloader />}>
                <MainthemeLoaded />
            </Suspense>
        </Provider>
    );
}

export default App;
