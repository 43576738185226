import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListRole } from "actions/userActions";
import { getListService, saveAddServer } from "actions/serviceActions";
import { formatterSocial, optionProviderType } from "components/theme_default/Function";
import Loading from "components/theme_default/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaladdserver(props) {
    const [services_id, setServicesId] = useState("");
    const [provider, setProvider] = useState("");
    const [provider_type, setProviderType] = useState("");
    const [provider_price, setProviderPrice] = useState(0);
    const [server_name, setServerName] = useState("");
    const [description, setDescription] = useState("");
    const [object_id, setObjectId] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [quantity_min, setQuantityMin] = useState(0);
    const [quantity_max, setQuantityMax] = useState(0);
    const [multiples, setMultiples] = useState(0);
    const [emoji_reaction, setEmojiReaction] = useState(0);
    const [content_comment, setContentComment] = useState(0);
    const [content_comment_image, setContentCommentImage] = useState(0);
    const [keyword, setKeyword] = useState(0);
    const [refundable, setRefundable] = useState(0);
    const [time_buff, setTimeBuff] = useState(0);
    const [maintenance, setMaintenance] = useState(0);
    var [prices, setPrices] = useState({});
    var { isOpenModalAddServer, openModal, saveAddServer, getListService, getListRole, formatterSocial, optionProviderType } = props;
    var { isLoadingServer, listservices } = props.service;
    var { listuserroles } = props.user;
    var { role } = props.user.user;

	useEffect(() => {
		getListService();
	}, [getListService]);

	useEffect(() => {
		getListRole();
	}, [getListRole]);

	const onClickSaveAddServer = () => {
		saveAddServer({ services_id, provider, provider_type, provider_price, server_name, description, object_id, quantity, quantity_min, quantity_max, multiples, emoji_reaction, content_comment, content_comment_image, keyword, refundable, time_buff, maintenance, prices }, openModal);
	};

	const onOpenModal = () => {
		openModal();
	};

	const onChange = (e) => {
        if (e.target.name === "services_id") {
            setServicesId(e.target.value);
        }
        if (e.target.name === "provider") {
            setProvider(e.target.value);
            setProviderType("");
        }
        if (e.target.name === "provider_type") {
            setProviderType(e.target.value);
        }
        if (e.target.name === "provider_price") {
            setProviderPrice(e.target.value);
        }
        if (e.target.name === "server_name") {
            setServerName(e.target.value);
        }
        if (e.target.name === "description") {
            setDescription(e.target.value);
        }
        if (e.target.name === "object_id") {
            setObjectId(prev => prev === 0 ? 1 : 0);
        }
        if (e.target.name === "quantity") {
            setQuantity(prev => prev === 0 ? 1 : 0);
        }
        if (e.target.name === "quantity_min") {
            setQuantityMin(e.target.value);
        }
        if (e.target.name === "quantity_max") {
            setQuantityMax(e.target.value);
        }
        if (e.target.name === "multiples") {
            setMultiples(e.target.value);
        }
        if (e.target.name === "prices") {
            prices[e.currentTarget.getAttribute('data-value')] = e.target.value;
            prices = JSON.stringify(prices);
            prices = JSON.parse(prices);
            setPrices(prices);
        }
        if (e.target.name === "emoji_reaction") {
            setEmojiReaction(prev => prev === 0 ? 1 : 0);
        }
        if (e.target.name === "content_comment") {
            setContentComment(prev => prev === 0 ? 1 : 0);
        }
        if (e.target.name === "content_comment_image") {
            setContentCommentImage(prev => prev === 0 ? 1 : 0);
        }
        if (e.target.name === "keyword") {
            setKeyword(prev => prev === 0 ? 1 : 0);
        }
        if (e.target.name === "refundable") {
            setRefundable(prev => prev === 0 ? 1 : 0);
        }
        if (e.target.name === "time_buff") {
            setTimeBuff(prev => prev === 0 ? 1 : 0);
        }
        if (e.target.name === "maintenance") {
            setMaintenance(prev => prev === 0 ? 1 : 0);
        }
	};
	
	const onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    const optionServices = () => {
        return listservices.map((v, i) => {
            return (
                <option value={v.id} key={i}>{formatterSocial(v)} - {v.name}</option>
            );
        });
    }

    const userRolesPrices = () => {
        return listuserroles.map((v, i) => {
            return (
                <div className="form-group row" key={i}>
                    <label className="col-sm-12 col-md-6 col-form-label">{v.name}</label>
                    <div className="col-sm-12 col-md-3 input-group bootstrap-touchspin al-input-group">
                        <input className="form-control" value={prices[v.id] ? prices[v.id] : 0} data-value={v.id} type="text" name="prices" placeholder={t("0")} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                        <span className="input-group-addon bootstrap-touchspin-postfix input-group-append al-postfix">
                            <span className="input-group-text">{t("vnđ")}</span>
                        </span>
                    </div>
                </div>
            );
        });
    }

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={() => {onOpenModal()}} isOpen={isOpenModalAddServer}>
                <Loading isLoading={isLoadingServer}/>
                <div className="modal-header">
                    <p className="mb-0 text-dark">{t("Thêm server")}</p>
                    <button onClick={() => {onOpenModal()}} type="button" className="close">×</button>
                </div>
                <ModalBody>
                    {
                        role === 0 ?
                            <Fragment>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Dịch vụ")}</label>
                                    <div className="col-sm-12 col-md-9">
                                        <select className="form-control" value={services_id} name="services_id" onChange={(e) => {onChange(e)}}>
                                            <option value="">{t("---Chọn dịch vụ---")}</option>
                                            {optionServices()}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Nhà cung cấp")}</label>
                                    <div className="col-sm-12 col-md-9">
                                        <select className="form-control" value={provider} name="provider" onChange={(e) => {onChange(e)}}>
                                            <option value="">{t("---Chọn nhà cung cấp---")}</option>
                                            <option value="0">{t("Tự chạy")}</option>
                                            <option value="1">{t("Mfb.vn")}</option>
                                            <option value="2">{t("FES")}</option>
                                        </select>
                                    </div>
                                </div>
                                {
                                    parseInt(provider) === 1 || parseInt(provider) === 2 ?
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-md-3 col-form-label">{t("Server nhà cung cấp")}</label>
                                            <div className="col-sm-12 col-md-9">
                                                <select className="form-control" value={provider_type} name="provider_type" onChange={(e) => {onChange(e)}}>
                                                    <option value="">{t("---Chọn server nhà cung cấp---")}</option>
                                                    {optionProviderType(provider)}
                                                </select>
                                            </div>
                                        </div>
                                    : ""
                                }
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Tên server")}</label>
                                    <div className="col-sm-12 col-md-9">
                                        <input className="form-control" value={server_name} type="text" name="server_name" placeholder="" onChange={(e) => {onChange(e)}}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Mô tả")}</label>
                                    <div className="col-sm-12 col-md-9">
                                        <textarea className="form-control al-form-control-textarea" value={description} type="text" name="description" placeholder="" rows="2" onChange={(e) => {onChange(e)}}></textarea>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Link hoặc ID bài")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <div className="custom-control custom-checkbox mb-5">
                                                <input type="checkbox" name="object_id" value={object_id} className="custom-control-input" id="object_id" onChange={(e) => {onChange(e)}} defaultChecked={object_id} />
                                                <label className="custom-control-label" htmlFor="object_id"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Số lượng")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <div className="custom-control custom-checkbox mb-5">
                                                <input type="checkbox" name="quantity" value={quantity} className="custom-control-input" id="quantity" onChange={(e) => {onChange(e)}} defaultChecked={quantity} />
                                                <label className="custom-control-label" htmlFor="quantity"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Số lượng min-max")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <input className="form-control" value={quantity_min} type="text" name="quantity_min" placeholder={t("min")} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}}/>
                                        </div>
                                        <label className="col-form-label">-</label>
                                        <div className="col-sm-12 col-md-3">
                                            <input className="form-control" value={quantity_max} type="text" name="quantity_max" placeholder={t("max")} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Bội số")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <input className="form-control" value={multiples} type="text" name="multiples" placeholder={t("0")} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Cảm xúc")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <div className="custom-control custom-checkbox mb-5">
                                                <input type="checkbox" name="emoji_reaction" value={emoji_reaction} className="custom-control-input" id="emoji_reaction" onChange={(e) => {onChange(e)}} defaultChecked={emoji_reaction} />
                                                <label className="custom-control-label" htmlFor="emoji_reaction"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Nội dung bình luận")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <div className="custom-control custom-checkbox mb-5">
                                                <input type="checkbox" name="content_comment" value={content_comment} className="custom-control-input" id="content_comment" onChange={(e) => {onChange(e)}} defaultChecked={content_comment} />
                                                <label className="custom-control-label" htmlFor="content_comment"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Nội dung bình luận + hình ảnh")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <div className="custom-control custom-checkbox mb-5">
                                                <input type="checkbox" name="content_comment_image" value={content_comment_image} className="custom-control-input" id="content_comment_image" onChange={(e) => {onChange(e)}} defaultChecked={content_comment_image} />
                                                <label className="custom-control-label" htmlFor="content_comment_image"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Từ khóa")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <div className="custom-control custom-checkbox mb-5">
                                                <input type="checkbox" name="keyword" value={keyword} className="custom-control-input" id="keyword" onChange={(e) => {onChange(e)}} defaultChecked={keyword} />
                                                <label className="custom-control-label" htmlFor="keyword"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Hủy đơn")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <div className="custom-control custom-checkbox mb-5">
                                                <input type="checkbox" name="refundable" value={refundable} className="custom-control-input" id="refundable" onChange={(e) => {onChange(e)}} defaultChecked={refundable} />
                                                <label className="custom-control-label" htmlFor="refundable"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Thời gian")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <div className="custom-control custom-checkbox mb-5">
                                                <input type="checkbox" name="time_buff" value={time_buff} className="custom-control-input" id="time_buff" onChange={(e) => {onChange(e)}} defaultChecked={time_buff} />
                                                <label className="custom-control-label" htmlFor="time_buff"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Bảo trì")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3">
                                            <div className="custom-control custom-checkbox mb-5">
                                                <input type="checkbox" name="maintenance" value={maintenance} className="custom-control-input" id="maintenance" onChange={(e) => {onChange(e)}} defaultChecked={maintenance} />
                                                <label className="custom-control-label" htmlFor="maintenance"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-3 col-form-label">{t("Giá nhà cung cấp")}</label>
                                    <div className="col-sm-12 col-md-9 row">
                                        <div className="col-sm-12 col-md-3 input-group bootstrap-touchspin al-input-group">
                                            <input className="form-control" value={provider_price} type="text" name="provider_price" placeholder={t("0")} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}}/>
                                            <span className="input-group-addon bootstrap-touchspin-postfix input-group-append al-postfix">
                                                <span className="input-group-text">{t("vnđ")}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        : ""
                    }
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Giá")}</label>
                        <div className="col-sm-12 col-md-9 al-user-roles-prices">
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-6 col-form-label">{t("Loại tài khoản")}</label>
                                <label className="col-sm-12 col-md-6 col-form-label">{t("Giá bán")}</label>
                            </div>
                            {userRolesPrices()}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveAddServer()}} >{t("Lưu")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => {onOpenModal()}} >{t("Huỷ")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaladdserver.propTypes = {
	getListService: PropTypes.func.isRequired,
	getListRole: PropTypes.func.isRequired,
	saveAddServer: PropTypes.func.isRequired,
	formatterSocial: PropTypes.func.isRequired,
	optionProviderType: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	user: state.user,
	service: state.service
});
const mapDispatchToProps = {
	getListService,
	getListRole,
	saveAddServer,
	formatterSocial,
	optionProviderType
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaladdserver);