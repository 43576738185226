import React, { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListServer, deleteServer, saveSetPricesAutoUserRolesFromXLSX, exportXLSX } from "actions/serviceActions";
import { ReactTable, formatterProvider, formatterSocial, formatterCreateAt } from "components/theme_default/Function";
import { Modaladdserver, Modaleditserver, Modalsetpricesauto, Modalsetpricesautouserroles } from "./modal";
import { selectFilter } from 'react-bootstrap-table2-filter';
import { read, utils, writeFile } from "xlsx";
import Swal from "sweetalert2";

function Listservers(props) {
	const [isOpenModalAddServer, setIsOpenModalAddServer] = useState(false);
	const [isOpenModalEditServer, setIsOpenModalEditServer] = useState(false);
	const [isOpenModalSetPricesAuto, setIsOpenModalSetPricesAuto] = useState(false);
	const [isOpenModalSetPricesAutoUserRoles, setIsOpenModalSetPricesAutoUserRoles] = useState(false);
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(10);
	const [searchText, setSearchText] = useState("");
	const [filterStatus, setFilterStatus] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [editserver, setEditServer] = useState([]);
	const { listservers, totalsize_admin_list_service_server, export_xlsx } = props.service;
    const { role } = props.user.user;
	const { getListServer, deleteServer, formatterProvider, formatterSocial, saveSetPricesAutoUserRolesFromXLSX, exportXLSX, formatterCreateAt } = props;
	var fileInputRef = React.createRef();
	const selectOptions = {
		0: t("Facebook"),
		1: t("Instagram"),
		2: t("Youtube"),
		3: t("Tiktok"),
		4: t("Shopee"),
		5: t("Google"),
		6: t("Telegram"),
		7: t("Twitter"),
		8: t("Tripadvisor"),
		9: t("FES")
	};

	useEffect(() => {
		getListServer(page, sizePerPage, searchText, filterStatus);
	}, [getListServer, page, sizePerPage, searchText, filterStatus]);

	useEffect(() => {
		setTotalSize(totalsize_admin_list_service_server);
	}, [totalsize_admin_list_service_server]);

	useEffect(() => {
		if (export_xlsx.length > 0) {
			const worksheet = utils.json_to_sheet(export_xlsx);
			const workbook = utils.book_new();
			utils.book_append_sheet(workbook, worksheet, "Sheet1");
			writeFile(workbook, "bảng giá_" + formatterCreateAt(Math.floor((new Date()).getTime() / 1000), "dmy") + ".xlsx");
		}
	}, [export_xlsx, formatterCreateAt]);

	const onClickOpenModalAddServer = () => {
		setIsOpenModalAddServer(!isOpenModalAddServer);
	};

	const onClickOpenModalSetPricesAuto = () => {
		setIsOpenModalSetPricesAuto(!isOpenModalSetPricesAuto);
	};

	const onClickOpenModalSetPricesAutoUserRoles = () => {
		setIsOpenModalSetPricesAutoUserRoles(!isOpenModalSetPricesAutoUserRoles);
	};

	const onClickOpenModalEditServer = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText,
				filterStatus: formatExtraData.filterStatus
			}
		}
		setIsOpenModalEditServer(!isOpenModalEditServer);
		setEditServer(row);
    };

	const onClickDeleteServer = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText,
				filterStatus: formatExtraData.filterStatus
			}
		}
		deleteServer(row);
	};

	const onChange = (e) => {
		e.preventDefault();
		if (e.target.files) {
			if (e.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
				Swal.fire(t("Lỗi"), t("Định dạng file không hợp lệ! Vui lòng kiểm tra lại!"), 'error');
				return false;
			}
			const file = e.target.files[0];
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const workbook = read(data, { type: "array" });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json  = utils.sheet_to_json(worksheet);
				saveSetPricesAutoUserRolesFromXLSX(json);
			};
			reader.readAsArrayBuffer(file);
		}
    };

	const onClick = (e) => {
		e.target.value = null;
    };

	const onClickExportXLSX = () => {
		exportXLSX();
    };

	const columns = [{
		dataField: 'id',
		text: t("ID"),
		sort: true,
    }, {
		dataField: 'social',
		text: "",
		sort: true,
        filter: selectFilter({
            placeholder: t("Mạng xã hội..."),
            options: selectOptions
        }),
		formatter: (cell, row) =>  formatterSocial(row)
	}, {
		dataField: 'name',
		text: t("Tên dịch vụ"),
		sort: true,
	}, {
		dataField: 'server_name',
		text: t("Tên server"),
		sort: true,
		formatter: (cell, row) => <span> {cell.length > 45 ? cell.substring(0, 45) + "..." : cell}</span>
	}, {
		dataField: 'description',
		text: t("Mô tả"),
		sort: true,
		formatter: (cell, row) => <span> {cell.length > 45 ? cell.substring(0, 45) + "..." : cell}</span>
	}, {
		dataField: 'quantity_min',
		text: t("Số lượng"),
		sort: true,
		formatter: (cell, row) => <span>{row.quantity_min} - {row.quantity_max}</span>
	}, {
		dataField: 'multiples',
		text: t("Bội số"),
		sort: true,
	}, {
		text: t("Thao tác"),
		dataField: '-',
		formatExtraData: {page, sizePerPage, searchText, filterStatus},
		formatter: (cell, row, rowIndex, formatExtraData) => 
			<Fragment>
				<button onClick={() => {onClickOpenModalEditServer(row, formatExtraData)}} className="btn btn-primary btn-sm mr-1">
					<i className="fa fa-pencil-square-o"></i>
				</button>
				{
					role === 0 ?
						<button onClick={() => {onClickDeleteServer(row, formatExtraData)}} className="btn btn-danger btn-sm mr-1">
							<i className="fa fa-trash-o"></i>
						</button>
					: ""
				}
			</Fragment>
	}];

	if (role === 0) {
		var column_provider = {
			dataField: 'provider',
			text: t("Nhà cung cấp"),
			formatter: (cell, row) =>  formatterProvider(row)
		};
		columns.splice(1, 0, column_provider);
	}

	const groupButton = () => {
		return (
			<div>
				{
					window.location.hostname !== "localhost" && window.location.hostname !== "app.fesads.com" ?
						<button onClick={() => {onClickOpenModalSetPricesAuto()}} type="button" className="btn btn-sm btn-success mr-1">
							<i className="fa fa-fw fa-cogs mr-1"></i> {t("Cài đặt giá tự động server")}
						</button>
					:
						<Fragment>
							<button onClick={() => {onClickOpenModalAddServer()}} type="button" className="btn btn-sm btn-success mr-1">
								<i className="fa fa-fw fa-plus mr-1"></i> {t("Thêm server")}
							</button>
						</Fragment>
				}
				<button onClick={() => {fileInputRef.current.click()}} type="button" className="btn btn-sm btn-info mr-1">
					<i className="fa fa-fw fa-file-excel-o mr-1"></i> {t("Cập nhật theo XLSX")}
				</button>
				<button onClick={() => {onClickExportXLSX()}} type="button" className="btn btn-sm btn-secondary mr-1">
					<i className="fa fa-fw fa-file-excel-o mr-1"></i> {t("Xuất XLSX")}
				</button>
				<input type="file" className="d-none" name="file_xlsx" ref={fileInputRef} onClick={(e) => {onClick(e)}} onChange={(e) => {onChange(e)}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
				<button onClick={() => {onClickOpenModalSetPricesAutoUserRoles()}} type="button" className="btn btn-sm btn-primary mr-1">
					<i className="fa fa-fw fa-cogs mr-1"></i> {t("Cài đặt giá tự động loại tài khoản")}
				</button>
			</div>
		);
	}
	
	const handleTableChange = (type, { page, sizePerPage, searchText, filters }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(10);
			setSearchText(searchText);
		} else if (type === "filter") {
            var filterVal = "";
            if (filters.social) {
                filterVal = filters.social.filterVal;
            }
            setPage(1);
            setSizePerPage(10);
            setFilterStatus(filterVal);
		} else {
			setPage(page);
			setSizePerPage(sizePerPage);
		}
	}

	return (
		<Fragment>
			<ReactTable columns={columns} data={listservers} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" />
			{isOpenModalAddServer ? <Modaladdserver openModal={onClickOpenModalAddServer} isOpenModalAddServer={isOpenModalAddServer} /> : '' }
			{isOpenModalEditServer ? <Modaleditserver openModal={onClickOpenModalEditServer} isOpenModalEditServer={isOpenModalEditServer} editserver={editserver} /> : '' }
			{isOpenModalSetPricesAuto ? <Modalsetpricesauto openModal={onClickOpenModalSetPricesAuto} isOpenModalSetPricesAuto={isOpenModalSetPricesAuto} /> : '' }
			{isOpenModalSetPricesAutoUserRoles ? <Modalsetpricesautouserroles openModal={onClickOpenModalSetPricesAutoUserRoles} isOpenModalSetPricesAutoUserRoles={isOpenModalSetPricesAutoUserRoles} /> : '' }
		</Fragment>
	);
}

Listservers.propTypes = {
	getListServer: PropTypes.func.isRequired,
	deleteServer: PropTypes.func.isRequired,
	formatterProvider: PropTypes.func.isRequired,
	formatterSocial: PropTypes.func.isRequired,
	saveSetPricesAutoUserRolesFromXLSX: PropTypes.func.isRequired,
	exportXLSX: PropTypes.func.isRequired,
	formatterCreateAt: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	user: state.user,
	service: state.service
});
const mapDispatchToProps = {
	getListServer,
	deleteServer,
	formatterProvider,
	formatterSocial,
	saveSetPricesAutoUserRolesFromXLSX,
	exportXLSX,
	formatterCreateAt
}

export default connect(mapStateToProps, mapDispatchToProps)(Listservers);