import { useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import { Link } from "react-router-dom";
import Listbanks from "./listbanks/Listbanks";
import Loading from "components/theme_default/Loading";

function Main(props) {
	const [tab, setTab] = useState("listbanks");
	var { isLoading } = props.bank;

	const onClick = (data) => {
		setTab(data);
	}

	return (
		<div className="pd-20 bg-white border-radius-4 box-shadow mb-30">
			<div className="tab">
				<ul className="nav nav-tabs" role="tablist">
					<li className="nav-item">
						<Link className={"nav-link text-blue " + (tab === "listbanks"? " active" : "")} to="#" onClick={(e) => {onClick("listbanks")}}>{t("Ngân hàng")}</Link>
					</li>
				</ul>
				<div className="tab-content">
					<Loading isLoading={isLoading}/>
					<div className="tab-pane fade active show">
						<div className="pd-20">
							<Listbanks />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	bank: state.bank
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);