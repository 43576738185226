import { t } from "i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";

const saveAgencyDomain = (data) => (dispatch, getState) => {
	var body = {
		agency_domain: data.agency_domain,
		username: data.username,
		password: data.password,
		repeatpassword: data.repeatpassword
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn sử dụng tên miền này để tích hợp đại lý?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_AGENCY', payload: true});
			axios.post('/api/agency/save-domain', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_AGENCY', payload: false});
				if (res.data.status === 200) {
					dispatch({
						type: 'AGENCY_NAMESERVERS',
						payload: res.data.data
					});
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_AGENCY', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const getNameserversFromDomain = (data) => (dispatch, getState) => {
	var body = {
		nameservers_from_domain: data.nameservers_from_domain
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn lấy nameservers?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_AGENCY', payload: true});
			axios.post('/api/agency/get-nameservers', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_AGENCY', payload: false});
				if (res.data.status === 200) {
					dispatch({
						type: 'AGENCY_NAMESERVERS',
						payload: res.data.data
					});
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_AGENCY', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

export {
	saveAgencyDomain,
	getNameserversFromDomain
}
