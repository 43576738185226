import {  Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { t } from "i18next";
import { loginSocialUser } from "actions/userActions";

function RedirectLoginSocial(props) {
    const { loginSocialUser } = props;
    var [searchParams] = useSearchParams();

	useEffect(() => {
        loginSocialUser({token: searchParams.get("token"), user: searchParams.get("user")});
	}, [loginSocialUser, searchParams]);

	return (
        <Fragment>
            {t("Đang chuyển hướng...")}
        </Fragment>
	);
}

RedirectLoginSocial.propTypes = {
	loginSocialUser: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    loginSocialUser
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectLoginSocial);
