import {  Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { loginUser } from "actions/userActions";

function Login(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_verifycode, setConfirmVerifyCode] = useState("");
    const { handleTab, loginUser } = props;
    const { is_enable_2fa } = props.user;

    const handleLogin = () => {
        loginUser(username, password, confirm_verifycode);
    }

    const onChange = (e) => {
        if (e.target.name === "username") {
            setUsername(e.target.value);
        }
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
        if (e.target.name === "confirm_verifycode") {
            setConfirmVerifyCode(e.target.value);
        }
    }

	const onKeyPress = (e) => {
		if (e.charCode === 13) {
			loginUser(username, password, confirm_verifycode);
		}
	}

	const google = () => {
		window.open("/auth/google", "_self");
        // var newwindow = window.open("/auth/google", "", "height=500, width=500");
        // if (window.focus) {
        //     newwindow.focus();
        // }
        // return false;
	}

	const facebook = () => {
		window.open("/auth/facebook", "_self");
	}

	return (
        <Fragment>
            <div className="login-title">
                <h2 className="text-center text-primary">{t("Đăng nhập tài khoản")}</h2>
            </div>
            <div className="input-group custom">
                <input type="text" className="form-control form-control-lg" placeholder={t("Tên tài khoản")} name="username" value={username} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                <div className="input-group-append custom">
                    <span className="input-group-text"><i className="dw dw-user1"></i></span>
                </div>
            </div>
            <div className="input-group custom">
                <input type="password" className="form-control form-control-lg" placeholder={t("Mật khẩu")} name="password" value={password} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                <div className="input-group-append custom">
                    <span className="input-group-text"><i className="dw dw-padlock1"></i></span>
                </div>
            </div>
            {
                is_enable_2fa ?
                    <div className="input-group custom">
                        <input type="text" className="form-control form-control-lg" placeholder={t("Mã xác minh")} name="confirm_verifycode" value={confirm_verifycode} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                        <div className="input-group-append custom">
                            <span className="input-group-text"><i className="dw dw-padlock1"></i></span>
                        </div>
                    </div>
                : ""
            }
            <div className="row">
                <div className="col-sm-12">
                    <div className="input-group mb-3">
                        <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={() => {handleLogin()}}>{t("Đăng nhập")}</button>
                    </div>
                    <div className="input-group mb-3">
                        <Link to="#" className="btn btn-lg btn-block al-button-google" onClick={() => {google()}}>{t("Đăng nhập với Google")}</Link>
                    </div>
                    <div className="input-group mb-0">
                        <Link to="#" className="btn btn-lg btn-block al-button-facebook" onClick={() => {facebook()}}>{t("Đăng nhập với Facebook")}</Link>
                    </div>
                    <div className="font-16 weight-600 pt-10 pb-10 text-center" data-color="#707373" style={{"color": "rgb(112, 115, 115)"}}>
                        {t("HOẶC")}
                    </div>
                    <div className="input-group mb-0">
                        <Link to="#" className="btn btn-outline-primary btn-lg btn-block" onClick={() => {handleTab("register")}}>{t("Đăng ký tài khoản mới")}</Link>
                    </div>
                </div>
            </div>
        </Fragment>
	);
}

Login.propTypes = {
	loginUser: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    loginUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
