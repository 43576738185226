import { t } from "i18next";
import styles from "./ExternalNode.module.css";
import { formatterSocial, IconServices } from "components/theme_default/Function";

export const ExternalNode = (props) => {
    const { id, droppable } = props.node;

    const handleDragStart = (e) => {
        e.dataTransfer.setData("text", JSON.stringify(props.node));
    };

    return (
        <div draggable className={styles.root} onDragStart={handleDragStart}>
            <div className={styles.filetype}>
                <IconServices id={id} type="menu" />
            </div>
            <div className={styles.label}>
                {
                    droppable ?
                        t(props.node.text)
                    : formatterSocial(props.node.data)() + (props.node.data.social === "-" ? "" : " - ") + t(props.node.text)
                }
            </div>
        </div>
    );
};
