import {  Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { t } from "i18next";
import Login from './Login';
import Register from './Register';
import Loading from "../Loading";
import Swal from "sweetalert2";

function Main(props) {
    const [tab, setTab] = useState("login");
    const { user } = props;
    var [searchParams] = useSearchParams();

	useEffect(() => {
        if (searchParams.get("error")) {
            Swal.fire(t("Lỗi"), t(searchParams.get("error")), 'error');
        }
	}, [searchParams]);

    const onClick = (data) => {
        setTab(data)
    }

	return (
        <Fragment>
            <div className="login-header box-shadow">
                <div className="container-fluid d-flex justify-content-between align-items-center">
                    <div className="brand-logo">
                        <Link to="/" className="text-dark">
                            {
                                window.location.hostname !== "localhost" && window.location.hostname !== "app.fesads.com" ?
                                    window.location.hostname.toUpperCase()
                                :
                                    <img src={require("../assets/vendors/images/deskapp-logo.png")} alt="" className="dark-logo" />
                            }
                        </Link>
                    </div>
                    <div className="login-menu">
                        <ul>
                            {
                                tab === "login" ?
                                    <li><Link to="#" onClick={() => {onClick("register")}}>{t("Đăng ký")}</Link></li>
                                : <li><Link to="#" onClick={() => {onClick("login")}}>{t("Đăng nhập")}</Link></li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg-7">
                            {
                                window.location.hostname !== "localhost" && window.location.hostname !== "app.fesads.com" ?
                                    <img src={tab === "login" ? require("../assets/vendors/images/login-page-img-agency.png") : require("../assets/vendors/images/register-page-img-agency.png") } alt="" />
                                :
                                    <img src={tab === "login" ? require("../assets/vendors/images/login-page-img.png") : require("../assets/vendors/images/register-page-img.png") } alt="" />
                            }
                        </div>
                        <div className="col-md-6 col-lg-5">
                            <div className="login-box bg-white box-shadow border-radius-10 al-login">
                                <Loading isLoading={user.isLoading} />
                                {
                                    tab === "login" ?
                                        <Login handleTab={onClick} />
                                    : <Register handleTab={onClick} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
	);
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
