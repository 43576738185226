import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListUser, deleteUser } from "actions/userActions";
import { ReactTable, formatterCreateAt, FormatterMoney } from "components/theme_default/Function";
import { Modaledituserrole, Modaleditusermoney, Modaladduser } from "./modal";

function Listusers(props) {
	const [isOpenModalEditUserRole, setIsOpenModalEditUserRole] = useState(false);
	const [isOpenModalEditUserMoney, setIsOpenModalEditUserMoney] = useState(false);
	const [isOpenModalAddUser, setIsOpenModalAddUser] = useState(false);
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(10);
	const [searchText, setSearchText] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [edituser, setEditUser] = useState([]);
	const { getListUser, deleteUser, formatterCreateAt, FormatterMoney } = props;
	const { listusers, totalsize_admin_list_user } = props.user;
	
	useEffect(() => {
		getListUser(page, sizePerPage, searchText);
	}, [getListUser, page, sizePerPage, searchText]);

	useEffect(() => {
		setTotalSize(totalsize_admin_list_user);
	}, [totalsize_admin_list_user]);
	
	const onClickOpenModalEditUserRole = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		setIsOpenModalEditUserRole(!isOpenModalEditUserRole);
		setEditUser(row);
    };
	
	const onClickOpenModalEditUserMoney = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		setIsOpenModalEditUserMoney(!isOpenModalEditUserMoney);
		setEditUser(row);
    };

	const onClickDeleteUser = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		deleteUser(row);
	};
	
	const onClickOpenModalAddUser = () => {
		setIsOpenModalAddUser(!isOpenModalAddUser);
    };

	const columns = [{
		dataField: 'id',
		text: t("ID"),
		sort: true,
	}, {
		dataField: 'username',
		text: t("Tên tài khoản"),
		sort: true,
	}, {
		dataField: 'fullname',
		text: t("Họ và tên"),
		sort: true,
	}, {
		dataField: 'email',
		text: t("Email"),
		sort: true,
	}, {
		dataField: 'rolename',
		text: t("Loại tài khoản"),
		sort: true,
	}, {
		dataField: 'money',
		text: t("Số dư"),
		sort: true,
		formatter: (cell, row) =>  FormatterMoney({money: cell})
	}, {
		dataField: 'money_deposited',
		text: t("Tiền đã nạp"),
		sort: true,
		formatter: (cell, row) =>  FormatterMoney({money: cell})
	}, {
		dataField: 'create_at',
		text: t("Ngày tạo"),
		sort: true,
		formatter: (cell, row) =>  formatterCreateAt(cell)
	}, {
		text: t("Thao tác"),
		dataField: '-',
		formatExtraData: {page, sizePerPage, searchText},
		formatter: (cell, row, rowIndex, formatExtraData) => 
			<Fragment>
				<button onClick={() => {onClickOpenModalEditUserRole(row, formatExtraData)}} className="btn btn-primary btn-sm mr-1">
					<i className="fa fa-pencil-square-o"></i>
				</button>
				<button onClick={() => {onClickOpenModalEditUserMoney(row, formatExtraData)}} className="btn btn-warning btn-sm mr-1">
					<i className="fa fa-money"></i>
				</button>
				<button onClick={() => {onClickDeleteUser(row, formatExtraData)}} className="btn btn-danger btn-sm mr-1">
					<i className="fa fa-trash-o"></i>
				</button>
			</Fragment>
	}];

	const groupButton = () => {
		return (
			<div>
				<button onClick={() => {onClickOpenModalAddUser()}} type="button" className="btn btn-sm btn-success mr-1">
					<i className="fa fa-plus mr-1"></i> {t("Thêm tài khoản")}
				</button>
			</div>
		);
	}

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(10);
			setSearchText(searchText);
		} else {
			setPage(page);
			setSizePerPage(sizePerPage);
		}
	}

	return (
		<Fragment>
			<ReactTable columns={columns} data={listusers} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" />
			{isOpenModalEditUserRole ? <Modaledituserrole openModal={onClickOpenModalEditUserRole} isOpenModalEditUserRole={isOpenModalEditUserRole} edituser={edituser} /> : '' }
			{isOpenModalEditUserMoney ? <Modaleditusermoney openModal={onClickOpenModalEditUserMoney} isOpenModalEditUserMoney={isOpenModalEditUserMoney} edituser={edituser} /> : '' }
			{isOpenModalAddUser ? <Modaladduser openModal={onClickOpenModalAddUser} isOpenModalAddUser={isOpenModalAddUser} /> : '' }
		</Fragment>
	);
}

Listusers.propTypes = {
	getListUser: PropTypes.func.isRequired,
	deleteUser: PropTypes.func.isRequired,
	formatterCreateAt: PropTypes.func.isRequired,
	FormatterMoney: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	user: state.user
});
const mapDispatchToProps = {
	getListUser,
	deleteUser,
	formatterCreateAt,
	FormatterMoney
}

export default connect(mapStateToProps, mapDispatchToProps)(Listusers);