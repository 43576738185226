import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { createOrder, getListServersOfServices } from "actions/orderActions";
import { convertObjectIdFromUrl } from "actions/websiteActions";
import { FormatterMoney, convertIdServiceTypeToText, getObjectIdFromLink } from "components/theme_default/Function";
import { useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect';

function Createorder(props) {
    const [server_selected, setServerSelected] = useState({});
    const [object_id, setObjectId] = useState("");
    const [link, setLink] = useState("");
    const [quantity, setQuantity] = useState(server_selected.quantity === 1 ? "" : 1);
    const [emoji_reaction, setEmojiReaction] = useState("like");
    const [content_comment, setContentComment] = useState("");
    const [content_comment_image, setContentCommentImage] = useState([
        {
            "content_comment_image-0": "",
            "content_comment_image-1": ""
        }
    ]);
    const [content_comment_image_preview, setContentCommentImagePreview] = useState([
        {
            "content_comment_image-0": "",
            "content_comment_image-1": ""
        }
    ]);
    const [html_content_comment_image, setHtmlContentCommentImage] = useState("");
    const [time_buff, setTimeBuff] = useState("");
    const [note, setNote] = useState("");
    const [isOpenServiceInfoBox, setIsOpenServiceInfoBox] = useState(isMobile ? false : true);
    const [keyword, setKeyword] = useState("");
    const [keyword_image, setKeywordImage] = useState("");
    const [keyword_image_preview, setKeywordImagePreview] = useState("");
	const { getListServersOfServices, convertIdServiceTypeToText, createOrder, getObjectIdFromLink, convertObjectIdFromUrl } = props;
	const { listallserver } = props.website;
	const { listserverofservice } = props.order;
    const location = useLocation();

    useEffect(() => {
        if (listserverofservice.length > 0) {
            setServerSelected(listserverofservice[0]);
        }
    }, [listserverofservice]);

    useEffect(() => {
        getListServersOfServices(listallserver);
        setObjectId("");
        setQuantity(server_selected.quantity === 1 ? "" : 1);
        setEmojiReaction("like");
        setContentComment("");
        setTimeBuff("");
        setNote("");
    }, [location, getListServersOfServices, listallserver]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        var html_content_comment_image_tmp = (
            <Fragment>
                {
                    content_comment_image_preview.length > 0 ?
                    content_comment_image_preview.map(function(value,index){
                            return (
                                <Fragment key={index}>
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-md-2 col-form-label">{index === 0 ? t("Nội dung bình luận") : ""}</label>
                                        <div className="col-sm-12 col-md-10">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-9">
                                                    <input className="form-control" value={value["content_comment_image-0"]} type="text" name="content_comment_image-0" placeholder="" onChange={(e) => {onChangeContentCommentImage(e)}} data-index={index} />
                                                </div>
                                                <div className={"col-sm-12 col-md-2" + (value["content_comment_image-1"] === "" ? "" : " text-center")}>
                                                    {
                                                        value["content_comment_image-1"] === "" ?
                                                            <div className="custom-file">
                                                                <input type="file" className="custom-file-input" name="content_comment_image-1" onChange={(e) => {onChangeFile(e)}} data-index={index} />
                                                                <label className="custom-file-label">{t("Chọn ảnh")}</label>
                                                            </div>
                                                        :
                                                            <Fragment>
                                                                <span className="position-relative">
                                                                    <button type="button" className="btn-delete-image" onClick={() => {onClickDeleteImage(index)}} ><i className="fa fa-trash"></i></button>
                                                                    <img src={value["content_comment_image-1"]} alt="" width="110" height="110" className="border-radius-10" />
                                                                </span>
                                                            </Fragment>
                                                    }
                                                </div>
                                                <div className="col-sm-12 col-md-1">
                                                    <button type="button" className="btn btn-danger" onClick={() => {onClickDeleteContentCommentImage(index)}} ><i className="fa fa-trash-o"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        })
                    : ""
                }
            </Fragment>
        );
        setQuantity(content_comment_image_preview.length);
        setHtmlContentCommentImage(html_content_comment_image_tmp);
    }, [content_comment_image_preview]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = async (e) => {
        if (e.target.name === "object_id") {
            if (e.target.value === "") {
                return false;
            }
            var link_tmp = e.target.value;
            var object_id_tmp = getObjectIdFromLink(server_selected, e.target.value);
            if (server_selected.social === 0) {
                if (server_selected.service_type === 0 || server_selected.service_type === 1 || server_selected.service_type === 2 || server_selected.service_type === 4 || server_selected.service_type === 5 || server_selected.service_type === 6 || server_selected.service_type === 10) {
                    object_id_tmp = await convertObjectIdFromUrl(server_selected, e.target.value);
                }
            }
            if (server_selected.social === 3) {
                if (server_selected.service_type === 0 || server_selected.service_type === 1 || server_selected.service_type === 4 || server_selected.service_type === 8 || server_selected.service_type === 11) {
                    object_id_tmp = await convertObjectIdFromUrl(server_selected, object_id_tmp);
                }
            }

            setLink(link_tmp);
            setObjectId(object_id_tmp);
        }
        if (e.target.name === "quantity") {
            setQuantity(e.target.value);
        }
        if (e.target.name === "emoji_reaction") {
            setEmojiReaction(e.target.value);
        }
        if (e.target.name === "content_comment") {
            var value = e.target.value.trim();
            var quantity = 0;
            if (value !== "") {
                quantity = value.split("\n").length;
            }
            setQuantity(quantity);
            setContentComment(e.target.value);
        }
        if (e.target.name === "time_buff") {
            setTimeBuff(e.target.value);
        }
        if (e.target.name === "note") {
            setNote(e.target.value);
        }
        if (e.target.name === "keyword") {
            setKeyword(e.target.value);
        }
        if (e.target.name === "keyword_image") {
            setKeywordImage(e.target.files);

            var image_to_base64 = await toBase64(e.target.files[0]);
            setKeywordImagePreview(image_to_base64);
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const onChangeServer = (data) => {
        setServerSelected(data)
    }

    const onClickToggle = () => {
        setIsOpenServiceInfoBox(!isOpenServiceInfoBox);
    }

    const onClick = () => {
        var data = {id: server_selected.id, object_id, link, quantity, note};
        if (server_selected.emoji_reaction === 1) {
            data = {...data, emoji_reaction};
        }
        if (server_selected.content_comment === 1) {
            data = {...data, content_comment};
        }
        if (server_selected.content_comment_image === 1) {
            data = {...data, content_comment: content_comment_image.filter(v => v["content_comment_image-0"] !== "" && v["content_comment_image-1"] !== "")};
        }
        if (server_selected.time_buff === 1) {
            data = {...data, time_buff};
        }
        if (server_selected.keyword === 1) {
            data = {...data, keyword, keyword_image};
        }
        createOrder(data, server_selected);
    }
	
	const onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

	const onClickDeleteKeywordImage = () =>{
        setKeywordImage("");
        setKeywordImagePreview("");
	}
    
	const onChangeContentCommentImage = (e) =>{
		var new_content_comment_image = content_comment_image[e.target.getAttribute('data-index')];
		new_content_comment_image[e.target.name] = e.target.value;
		content_comment_image[e.target.getAttribute('data-index')] = new_content_comment_image;
		setContentCommentImage(content_comment_image);

		var new_content_comment_image_preview = content_comment_image_preview[e.target.getAttribute('data-index')];
		new_content_comment_image_preview[e.target.name] = e.target.value;
		content_comment_image_preview[e.target.getAttribute('data-index')] = new_content_comment_image_preview;
        var content_comment_image_preview_tmp = JSON.stringify(content_comment_image_preview);
		setContentCommentImagePreview(JSON.parse(content_comment_image_preview_tmp));
	}

	const onClickContentCommentImage = () =>{
        var content_comment_image_tmp = content_comment_image;
        setContentCommentImage([
            ...content_comment_image_tmp,
            {
                "content_comment_image-0": "",
                "content_comment_image-1": ""
            }
        ]);

        var content_comment_image_preview_tmp = content_comment_image_preview;
        setContentCommentImagePreview([
            ...content_comment_image_preview_tmp,
            {
                "content_comment_image-0": "",
                "content_comment_image-1": ""
            }
        ]);
	}

	const onClickDeleteImage = (index) =>{
		var new_content_comment_image = content_comment_image[index];
		new_content_comment_image["content_comment_image-1"] = "";
		content_comment_image[index] = new_content_comment_image;
		setContentCommentImage(content_comment_image);

		var new_content_comment_image_preview = content_comment_image_preview[index];
		new_content_comment_image_preview["content_comment_image-1"] = "";
		content_comment_image_preview[index] = new_content_comment_image_preview;
        var content_comment_image_preview_tmp = JSON.stringify(content_comment_image_preview);
		setContentCommentImagePreview(JSON.parse(content_comment_image_preview_tmp));
	}

	const onClickDeleteContentCommentImage = (index) =>{
		delete content_comment_image[index];
		var content_comment_image_tmp = content_comment_image.filter(item => item);
		setContentCommentImage(content_comment_image_tmp);
        
		delete content_comment_image_preview[index];
		var content_comment_image_preview_tmp = content_comment_image_preview.filter(item => item);
        content_comment_image_preview_tmp = JSON.stringify(content_comment_image_preview_tmp);
		setContentCommentImagePreview(JSON.parse(content_comment_image_preview_tmp));
	}

	const onChangeFile = async (e) =>{
        var new_content_comment_image = content_comment_image[e.target.getAttribute('data-index')];
		new_content_comment_image[e.target.name] = e.target.files;
		content_comment_image[e.target.getAttribute('data-index')] = new_content_comment_image;
		setContentCommentImage(content_comment_image);

        var new_content_comment_image_preview = content_comment_image_preview[e.target.getAttribute('data-index')];
        var image_to_base64 = await toBase64(e.target.files[0]);
		new_content_comment_image_preview[e.target.name] = image_to_base64;
		content_comment_image_preview[e.target.getAttribute('data-index')] = new_content_comment_image_preview;
        var content_comment_image_preview_tmp = JSON.stringify(content_comment_image_preview);
		setContentCommentImagePreview(JSON.parse(content_comment_image_preview_tmp));
	}
	
	const ListServers = () => {
        return (
            <Fragment>
                <div className="form-group row">
                    <label className="col-sm-12 col-md-2 col-form-label">{t("Chọn server")}</label>
                    <div className="col-sm-12 col-md-10">
                        {
                            listserverofservice.length > 0 ?
                                listserverofservice.map((v, i) => {
                                    return (
                                        <div className="custom-control custom-radio mb-5" key={v.id}>
                                            <input type="radio" id={"server_"+v.id} value={v.id} name="server" className="custom-control-input" onChange={() => {onChangeServer(v)}} defaultChecked={i === 0 ? true : false} />
                                            <label className="custom-control-label" htmlFor={"server_"+v.id}>
                                                <span className="al-vaal-s">{t(v.server_name)}</span>
                                                <span className="badge badge-primary al-badge ml-1"><FormatterMoney money={v.prices}/></span>
                                                {
                                                    v.maintenance ?
                                                        <span className="text-danger al-vaal-s ml-1">({t("Bảo trì")})</span>
                                                    : ""
                                                }
                                            </label>
                                        </div>
                                    )
                                })
                            :
                                <label className="text-danger col-form-label">{t("Hiện chưa có server!")}</label>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
	
	const HtmlEmojiReaction = () => {
        return (
            <Fragment>
                {
                    server_selected.emoji_reaction === 1 ?
                        <div className="form-group row">
                            <label className="col-sm-12 col-md-2 col-form-label">{t("Cảm xúc")}</label>
                            <div className="col-sm-12 col-md-10">
                                <label className="al-emoji-reaction">
                                    <input type="radio" value="like" name="emoji_reaction" className="d-none" onChange={(e) => {onChange(e)}} defaultChecked={emoji_reaction === "like" ? true : false} />
                                    <img src={require("components/theme_default/assets/vendors/images/emoji_reaction/like.svg").default} alt="" className="ml-2"/>
                                </label>
                                <label className="al-emoji-reaction">
                                    <input type="radio" value="love" name="emoji_reaction" className="d-none" onChange={(e) => {onChange(e)}} defaultChecked={emoji_reaction === "love" ? true : false} />
                                    <img src={require("components/theme_default/assets/vendors/images/emoji_reaction/love.svg").default} alt="" className="ml-2"/>
                                </label>
                                <label className="al-emoji-reaction">
                                    <input type="radio" value="care" name="emoji_reaction" className="d-none" onChange={(e) => {onChange(e)}} defaultChecked={emoji_reaction === "care" ? true : false} />
                                    <img src={require("components/theme_default/assets/vendors/images/emoji_reaction/care.svg").default} alt="" className="ml-2"/>
                                </label>
                                <label className="al-emoji-reaction">
                                    <input type="radio" value="haha" name="emoji_reaction" className="d-none" onChange={(e) => {onChange(e)}} defaultChecked={emoji_reaction === "haha" ? true : false} />
                                    <img src={require("components/theme_default/assets/vendors/images/emoji_reaction/haha.svg").default} alt="" className="ml-2"/>
                                </label>
                                <label className="al-emoji-reaction">
                                    <input type="radio" value="wow" name="emoji_reaction" className="d-none" onChange={(e) => {onChange(e)}} defaultChecked={emoji_reaction === "wow" ? true : false} />
                                    <img src={require("components/theme_default/assets/vendors/images/emoji_reaction/wow.svg").default} alt="" className="ml-2"/>
                                </label>
                                <label className="al-emoji-reaction">
                                    <input type="radio" value="sad" name="emoji_reaction" className="d-none" onChange={(e) => {onChange(e)}} defaultChecked={emoji_reaction === "sad" ? true : false} />
                                    <img src={require("components/theme_default/assets/vendors/images/emoji_reaction/sad.svg").default} alt="" className="ml-2"/>
                                </label>
                                <label className="al-emoji-reaction">
                                    <input type="radio" value="angry" name="emoji_reaction" className="d-none" onChange={(e) => {onChange(e)}} defaultChecked={emoji_reaction === "angry" ? true : false} />
                                    <img src={require("components/theme_default/assets/vendors/images/emoji_reaction/angry.svg").default} alt="" className="ml-2"/>
                                </label>
                            </div>
                        </div>
                    : ""
                }
            </Fragment>
        )
    }
	
	const HtmlContentComment = () => {
        return (
            <Fragment>
                {
                    server_selected.content_comment === 1 ?
                        <div className="form-group row">
                            <label className="col-sm-12 col-md-2 col-form-label">{t("Nội dung bình luận")}</label>
                            <div className="col-sm-12 col-md-10">
                                <textarea className="form-control al-form-control-textarea" value={content_comment} type="text" name="content_comment" placeholder="" rows="5" onChange={(e) => {onChange(e)}}></textarea>
                            </div>
                        </div>
                    : ""
                }
            </Fragment>
        )
    }
	
	const HtmlContentCommentImage = () => {
        return (
            <Fragment>
                {
                    server_selected.content_comment_image === 1 ?
                        <Fragment>
                            {html_content_comment_image}
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-2 col-form-label"></label>
                                <div className="col-sm-12 col-md-10">
                                    <button type="button" className="btn btn-primary" onClick={() => {onClickContentCommentImage()}} >{t("Thêm mới")}</button>
                                </div>
                            </div>
                        </Fragment>
                    : ""
                }
            </Fragment>
        )
    }
	
	const HtmlTimeBuff = () => {
        var options = [<option value="">{t("---Chọn thời gian---")}</option>];
        var i = 5;
        if (server_selected.social === 3 && server_selected.time_buff === 1) {
            i = 5;
        }
        if (server_selected.social === 2 && server_selected.time_buff === 1) {
            i = 45;
        }
        for (i; i <= 120; i++) {
            options.push(<option value={i} key={i}>{i} {t("Giây")}</option>);
        }

        return (
            <Fragment>
                {
                    server_selected.social === 0 && server_selected.time_buff === 1 ?
                        <div className="form-group row">
                            <label className="col-sm-12 col-md-2 col-form-label">{t("Thời gian duy trì mắt")}</label>
                            <div className="col-sm-12 col-md-10">
                                <select className="form-control" value={time_buff} name="time_buff" onChange={(e) => {onChange(e)}}>
                                    <option value="">{t("---Chọn thời gian---")}</option>
                                    <option value="30">30 {t("Phút")}</option>
                                    <option value="45">45 {t("Phút")}</option>
                                    <option value="60">60 {t("Phút")}</option>
                                    <option value="90">90 {t("Phút")}</option>
                                    <option value="120">120 {t("Phút")}</option>
                                    <option value="150">150 {t("Phút")}</option>
                                    <option value="180">180 {t("Phút")}</option>
                                </select>
                            </div>
                        </div>
                    : (server_selected.social === 3 && server_selected.time_buff === 1) || (server_selected.social === 2 && server_selected.time_buff === 1) ?
                        <div className="form-group row">
                            <label className="col-sm-12 col-md-2 col-form-label">{t("Thời gian xem video")}</label>
                            <div className="col-sm-12 col-md-10">
                                <select className="form-control" value={time_buff} name="time_buff" onChange={(e) => {onChange(e)}}>
                                    {options}
                                </select>
                            </div>
                        </div>
                    : ""
                }
            </Fragment>
        )
    }
	
	const HtmlKeyword = () => {
        return (
            <Fragment>
                {
                    server_selected.keyword === 1 ?
                        <Fragment>
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-2 col-form-label">{t("Từ khóa (nằm ở 1-20 thứ tự đầu tiên để user tìm thấy)")}</label>
                                <div className="col-sm-12 col-md-10">
                                    <textarea className="form-control al-form-control-textarea" value={keyword} type="text" name="keyword" placeholder="" rows="5" onChange={(e) => {onChange(e)}}></textarea>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-2 col-form-label">{t("Thêm hình ảnh mô tả")}</label>
                                <div className="col-sm-12 col-md-10">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            {
                                                keyword_image_preview === "" ?
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="keyword_image" onChange={(e) => {onChange(e)}} />
                                                        <label className="custom-file-label">{t("Chọn ảnh")}</label>
                                                    </div>
                                                :
                                                    <Fragment>
                                                        <span className="position-relative">
                                                            <button type="button" className="btn-delete-image" onClick={() => {onClickDeleteKeywordImage()}} ><i className="fa fa-trash"></i></button>
                                                            <img src={keyword_image_preview} alt="" width="110" height="110" className="border-radius-10" />
                                                        </span>
                                                    </Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    : ""
                }
            </Fragment>
        )
    }

	return (
        <Fragment>
            {
                server_selected.object_id === 1 ?
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-2 col-form-label">{t("Link hoặc ID bài")}</label>
                        <div className="col-sm-12 col-md-10">
                            <input className="form-control" value={object_id} type="text" name="object_id" placeholder="" onChange={(e) => {onChange(e)}}/>
                        </div>
                    </div>
                : ""
            }
            {ListServers()}
            {
                server_selected.quantity === 1 ?
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-2 col-form-label">{t("Số lượng")}</label>
                        <div className="col-sm-12 col-md-10">
                            <input className="form-control" value={quantity} type="text" name="quantity" placeholder={t("0")} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} disabled={server_selected.content_comment === 1 || server_selected.content_comment_image === 1}/>
                        </div>
                    </div>
                : ""
            }
            {HtmlEmojiReaction()}
            {HtmlKeyword()}
            {HtmlContentComment()}
            {HtmlContentCommentImage()}
            {HtmlTimeBuff()}
            <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">{t("Ghi chú")}</label>
                <div className="col-sm-12 col-md-10">
                    <textarea className="form-control al-form-control-textarea" value={note} type="text" name="note" placeholder="" rows="2" onChange={(e) => {onChange(e)}}></textarea>
                </div>
            </div>
            <button type="button" className="btn btn-sm btn-primary" onClick={() => {onClickToggle()}}><i className={"bi mr-1" + (isOpenServiceInfoBox ? " bi-chevron-up" : " bi-chevron-down")}></i>{t("Thông tin dịch vụ")}</button>
            <div className={"form-group row" + (isOpenServiceInfoBox ? "" : " d-none")}>
                <div className="col-md-4 mt-20">
                    <div className="card al-card-select">
                        <div className="card-body al-card-body-select">
                            <p className="mb-0 mt-3 text-wrap fs-14">- {t("Đơn giá")}: <span className="font-weight-bold"><FormatterMoney money={server_selected.prices}/></span></p>
                            <span className="badge al-badge position-absolute al-badge-top-left badge-primary al-badge-pill">{t("Đơn giá")}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-20">
                    <div className="card al-card-select">
                        <div className="card-body al-card-body-select">
                            <p className="mb-0 mt-3 text-wrap fs-14">- {t("Số lượng tối thiểu")}: <span className="font-weight-bold">{server_selected.quantity_min}</span></p>
                            <span className="badge al-badge position-absolute al-badge-top-left badge-primary al-badge-pill">{t("Số lượng tối thiểu")}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-20">
                    <div className="card al-card-select">
                        <div className="card-body al-card-body-select">
                            <p className="mb-0 mt-3 text-wrap fs-14">- {t("Số lượng tối đa")}: <span className="font-weight-bold">{server_selected.quantity_max}</span></p>
                            <span className="badge al-badge position-absolute al-badge-top-left badge-primary al-badge-pill">{t("Số lượng tối đa")}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-20">
                    <div className="card al-card-select">
                        <div className="card-body al-card-body-select">
                            <p className="mb-0 mt-3 fs-14 text-white-space-pre">
                                {
                                    window.location.hostname !== "localhost" && window.location.hostname !== "app.fesads.com" ?
                                        server_selected.description ? server_selected.description.replace(/fesads|fesads.com|app.fesads.com/gi, "") : ""
                                    :
                                        server_selected.description
                                }
                            </p>
                            <span className="badge al-badge position-absolute al-badge-top-left badge-primary al-badge-pill">{t("HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG!")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group row mt-20">
                <div className="col-12 text-center">
                    <div className="card card-info">
                        <div className="card-body">
                            <h5 className="h5 mb-0">{t("Số tiền cần phải thanh toán")}: <span className="text-danger"><FormatterMoney money={server_selected.time_buff === 1 ? server_selected.prices*quantity*time_buff : server_selected.prices*quantity}/></span></h5>
                            <p className="mb-0">{t("Bài của bạn sẽ được tăng")} <span className="text-success">{quantity} {convertIdServiceTypeToText(server_selected.service_type)}</span> {t("với giá")} <span className="text-success"><FormatterMoney money={server_selected.prices}/></span> / <span className="text-success">{convertIdServiceTypeToText(server_selected.service_type)}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-12">
                    <button type="button" className="btn btn-sm btn-primary btn-block" onClick={() => {onClick()}} >{t("Tạo đơn")}</button>
                </div>
            </div>
        </Fragment>
	);
}

Createorder.propTypes = {
	FormatterMoney: PropTypes.func.isRequired,
	getListServersOfServices: PropTypes.func.isRequired,
	convertIdServiceTypeToText: PropTypes.func.isRequired,
	createOrder: PropTypes.func.isRequired,
	getObjectIdFromLink: PropTypes.func.isRequired,
	convertObjectIdFromUrl: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    website: state.website,
    order: state.order
});
const mapDispatchToProps = {
    FormatterMoney,
    getListServersOfServices,
    convertIdServiceTypeToText,
    createOrder,
    getObjectIdFromLink,
    convertObjectIdFromUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(Createorder);