import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import { Link } from "react-router-dom";
import Settings from "./settings/Settings";
import Information from "./information/Information";
import Changepassword from "./changepassword/Changepassword";
import Twofa from "./twofa/Twofa";
import Loading from "components/theme_default/Loading";

function Main(props) {
	const [tab, setTab] = useState("settings");
    const { isLoading } = props.user;
    const { login_provider } = props.user.user;

	const onClick = (data) => {
		setTab(data);
	}

	return (
        <Fragment>
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-30">
                    <div className="pd-20 card-box height-100-p">
                        <Information />
                    </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-30">
                    <div className="card-box height-100-p overflow-hidden">
                        <div className="profile-tab height-100-p">
                            <div className="tab height-100-p">
                                <ul className="nav nav-tabs customtab" role="tablist">
                                    <li className="nav-item">
                                        <Link className={"nav-link text-blue " + (tab === "settings"? " active" : "")} to="#" onClick={(e) => {onClick("settings")}}>{t("Cài đặt")}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={"nav-link text-blue " + (tab === "changepassword"? " active" : "")} to="#" onClick={(e) => {onClick("changepassword")}}>{t("Đổi mật khẩu")}</Link>
                                    </li>
                                    {
                                        login_provider === "" ?
                                            <li className="nav-item">
                                                <Link className={"nav-link text-blue " + (tab === "twofa"? " active" : "")} to="#" onClick={(e) => {onClick("twofa")}}>{t("2FA")}</Link>
                                            </li>
                                        : ""
                                    }
                                </ul>
                                <div className="tab-content">
					                <Loading isLoading={isLoading}/>
                                    <div className="tab-pane fade height-100-p active show" id="setting" role="tabpanel">
                                        <div className="profile-setting">
                                            {
                                                tab === "changepassword" ?
                                                    <Changepassword />
                                                : tab === "twofa" ?
                                                    <Twofa />
                                                : <Settings />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
	);
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);