import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { saveEditNotice } from "actions/noticeActions";
import Loading from "components/theme_default/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaleditnotice(props) {
    const [id] = useState(props.editnotice.id);
    const [title, setTitle] = useState(props.editnotice.title);
    const [content, setContent] = useState(props.editnotice.content);
    const [video, setVideo] = useState(props.editnotice.video);
    const [image, setImage] = useState(props.editnotice.image);
    var { isOpenModalEditNotice, openModal, saveEditNotice } = props;
    var { isLoading } = props.notice;

	const onClickSaveEditNotice = () => {
		saveEditNotice({ id, title, content, video, image, page: props.editnotice.page, sizePerPage: props.editnotice.sizePerPage, searchText: props.editnotice.searchText }, openModal);
	};

    const onChange = (e) => {
        if (e.target.name === "title") {
            setTitle(e.target.value);
        }
        if (e.target.name === "content") {
            setContent(e.target.value);
        }
        if (e.target.name === "video") {
            setVideo(e.target.value);
        }
        if (e.target.name === "image") {
            setImage(e.target.value);
        }
    }

	const onOpenModal = () => {
		openModal();
	};

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => {onOpenModal()}} isOpen={isOpenModalEditNotice}>
                <Loading isLoading={isLoading}/>
                <div className="modal-header">
                    <p className="mb-0 text-dark">{t("Sửa thông báo")}</p>
                    <button onClick={() => {onOpenModal()}} type="button" className="close">×</button>
                </div>
                <ModalBody>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label">{t("Tiêu đề")}</label>
                        <div className="col-sm-12 col-md-8">
                            <input className="form-control" value={title} type="text" name="title" placeholder="" onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label">{t("Nội dung")}</label>
                        <div className="col-sm-12 col-md-8">
                            <textarea className="form-control al-form-control-textarea" value={content} type="text" name="content" placeholder="" rows="2" onChange={(e) => {onChange(e)}}></textarea>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label">{t("ID Video Youtube")}</label>
                        <div className="col-sm-12 col-md-8">
                            <input className="form-control" value={video} type="text" name="video" placeholder="" onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label">{t("Link ảnh")}</label>
                        <div className="col-sm-12 col-md-8">
                            <textarea className="form-control al-form-control-textarea" value={image} type="text" name="image" placeholder="" rows="2" onChange={(e) => {onChange(e)}}></textarea>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveEditNotice()}} >{t("Lưu")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => {onOpenModal()}} >{t("Huỷ")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaleditnotice.propTypes = {
	saveEditNotice: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
	notice: state.notice
});
const mapDispatchToProps = {
	saveEditNotice
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaleditnotice);