import {  Fragment } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/vendors/styles/custom.css";
import "./assets/vendors/styles/core.css";
import "./assets/vendors/styles/icon-font.min.css";
import "./assets/vendors/styles/style.css";
import Header from './Header';
import Navbar from './Navbar';
import Body from './Body';
import Mainlogin from './login/Main';
import RedirectLoginSocial from './login/RedirectLoginSocial';
import { Helmet } from "react-helmet";

function Maincontainer(props) {
    var { isAuthenticated } = props.user;
    var { isOpenSidebar } = props.website;

	return (
		<Router>
            <Fragment>
                {
                    window.location.hostname !== "localhost" && window.location.hostname !== "app.fesads.com" ?
                        <Helmet>
                            <link rel="icon" href={require("components/theme_default/assets/vendors/images/favicon_agency.png")} />,
                            <title>{window.location.hostname.toUpperCase()}</title>
                        </Helmet>
                    :
                        <Helmet>
                            <link rel="icon" href={require("components/theme_default/assets/vendors/images/favicon.ico")} />,
                            <title>FES Ads</title>
                        </Helmet>
                }
                <div id="main-wrapper" className={"header-white sidebar-light" + (isOpenSidebar ? "" : " sidebar-shrink")} >
                    {
                        isAuthenticated ?
                            <Fragment>
                                <Header/>
                                <Navbar/>
                                <Body/>
                            </Fragment>
                        :
                            <Fragment>
                                <Routes>
                                    <Route path="/*" element={<Mainlogin />} />
                                    <Route path="/redirect-login-social" element={<RedirectLoginSocial />} />
                                </Routes>
                            </Fragment>
                    }
                </div>
            </Fragment>
        </Router>
	);
}

const mapStateToProps = state => ({
	user: state.user,
	website: state.website
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Maincontainer);
