import { Fragment, useState } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { saveAgencyDomain, getNameserversFromDomain } from "actions/agencyActions";
import copy from 'copy-to-clipboard';
import Swal from "sweetalert2";

function Agencyconnect(props) {
    const [agency_domain, setAgencyDomain] = useState("");
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [repeatpassword, setRepeatPassword] = useState("");
    const [nameservers_from_domain, setNameserversFromDomain] = useState("");
    const [nameservers_1, setNameservers1] = useState(false);
    const [nameservers_2, setNameservers2] = useState(false);
	var { saveAgencyDomain, getNameserversFromDomain } = props;
	var { listnameservers } = props.agency;

    const onChange = (e) => {
        if (e.target.name === "agency_domain") {
            setAgencyDomain(e.target.value);
        }
        if (e.target.name === "username") {
            setUserName(e.target.value);
        }
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
        if (e.target.name === "repeatpassword") {
            setRepeatPassword(e.target.value);
        }
        if (e.target.name === "nameservers_from_domain") {
            setNameserversFromDomain(e.target.value);
        }
    }

	const onClickSaveAgencyDomain = () => {
		saveAgencyDomain({ agency_domain, username, password, repeatpassword });
	};

	const onClickGetNameserversFromDomain = () => {
		getNameserversFromDomain({ nameservers_from_domain });
	};

	const onClickCopy = (data) => {
		copy(listnameservers[data]);
		if (data === 0) {
			setNameservers1(true);
		}
		if (data === 1) {
			setNameservers2(true);
		}
		Swal.fire(t("Đã Copy"), listnameservers[data], 'success');
	};

	return (
		<Fragment>
			<div className="form-group">
				<div className="card card-box bg-danger text-white">
					<div className="card-header">{t("Các bước tích hợp đại lý")}</div>
					<div className="card-body">
						<p>{t("- Nhập tên miền muốn tích hợp đại lý và Tài khoản admin đại lý")}</p>
						<p>{t("- Bấm nút Tích hợp đại lý")}</p>
						<p>{t("- Sửa lại Nameservers của tên miền theo Nameservers 1 và Nameservers 2 hiện ra ở mục Nameservers")}</p>
						<p>{t("- Chờ khoảng 1-5 phút để hệ thống sẽ tự nhận diện và cài đặt site đại lý cho bạn")}</p>
						<p>{t("- Đăng nhập vào đại lý với Tài khoản admin đại lý vừa tạo là hoàn thành")}</p>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="card card-box">
					<div className="card-header">{t("Nhập tên miền muốn tích hợp đại lý")}</div>
					<div className="card-body">
						<div className="form-group row">
							<label className="col-sm-12 col-md-3 col-form-label">{t("Tên miền")}</label>
							<div className="col-sm-12 col-md-9">
								<input className="form-control" value={agency_domain} type="text" name="agency_domain" placeholder={t("tenmien.com")} onChange={(e) => {onChange(e)}} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-12 col-md-3 col-form-label">{t("Tài khoản admin đại lý")}</label>
							<div className="col-sm-12 col-md-9">
								<input className="form-control" value={username} type="text" name="username" placeholder="" onChange={(e) => {onChange(e)}} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-12 col-md-3 col-form-label">{t("Mật khẩu")}</label>
							<div className="col-sm-12 col-md-9">
								<input className="form-control" value={password} type="password" name="password" placeholder={t("••••••••")} onChange={(e) => {onChange(e)}} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-12 col-md-3 col-form-label">{t("Nhập lại mật khẩu")}</label>
							<div className="col-sm-12 col-md-9">
								<input className="form-control" value={repeatpassword} type="password" name="repeatpassword" placeholder={t("••••••••")} onChange={(e) => {onChange(e)}} />
							</div>
						</div>
					</div>
					<div className="card-footer text-muted text-right">
						<button className="btn btn-primary" onClick={() => {onClickSaveAgencyDomain()}}>{t("Tích hợp đại lý")}</button>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="card card-box">
					<div className="card-header">{t("Nameservers")}</div>
					<div className="card-body">
						<div className="form-group row">
							<label className="col-sm-12 col-md-3 col-form-label">{t("Tên miền đã tích hợp đại lý")}</label>
							<div className="col-sm-12 col-md-9">
								<input className="form-control" value={nameservers_from_domain} type="text" name="nameservers_from_domain" placeholder={t("tenmien.com")} onChange={(e) => {onChange(e)}} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-12 col-md-3 col-form-label">{t("Nameservers 1")}</label>
							<div className="col-sm-12 col-md-3 input-group bootstrap-touchspin al-input-group">
								<input className="form-control" value={listnameservers[0]||""} type="text" name="nameservers_1" placeholder="" disabled/>
								<span className="input-group-btn input-group-append">
									<button className={"btn bootstrap-touchspin-down " + (nameservers_1 ? "btn-success" : "btn-primary")} type="button" onClick={() => {onClickCopy(0)}}>
										{
											nameservers_1 ?
												<Fragment>
													<i className="bi bi-check2-circle mr-1"></i>{t("Đã Copy")}
												</Fragment>
											:
												<Fragment>
													<i className="bi bi-files mr-1"></i>{t("Copy")}
												</Fragment>
										}
									</button>
								</span>
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-12 col-md-3 col-form-label">{t("Nameservers 2")}</label>
							<div className="col-sm-12 col-md-3 input-group bootstrap-touchspin al-input-group">
								<input className="form-control" value={listnameservers[1]||""} type="text" name="nameservers_2" placeholder="" disabled/>
								<span className="input-group-btn input-group-append">
									<button className={"btn bootstrap-touchspin-down " + (nameservers_2 ? "btn-success" : "btn-primary")} type="button" onClick={() => {onClickCopy(1)}}>
										{
											nameservers_2 ?
												<Fragment>
													<i className="bi bi-check2-circle mr-1"></i>{t("Đã Copy")}
												</Fragment>
											:
												<Fragment>
													<i className="bi bi-files mr-1"></i>{t("Copy")}
												</Fragment>
										}
									</button>
								</span>
							</div>
						</div>
					</div>
					<div className="card-footer text-muted text-right">
						<button className="btn btn-primary" onClick={() => {onClickGetNameserversFromDomain()}}>{t("Lấy Nameservers")}</button>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

Agencyconnect.propTypes = {
	saveAgencyDomain: PropTypes.func.isRequired,
	getNameserversFromDomain: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	agency: state.agency
});
const mapDispatchToProps = {
	saveAgencyDomain,
	getNameserversFromDomain
}

export default connect(mapStateToProps, mapDispatchToProps)(Agencyconnect);