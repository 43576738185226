import { Fragment } from 'react';
import { t } from "i18next";
import { NumericFormat } from 'react-number-format';
import ProgressBar from "@ramonak/react-progress-bar";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
const { SearchBar } = Search;

const FormatterMoney = (props) => {
    var { money, suffix, style } = props;

    return (
        <NumericFormat value={ typeof money !== 'undefined' ? money : 0 } displayType={'text'} thousandSeparator={true} suffix={" " + (suffix ? t(suffix) : t("vnđ"))} style={typeof style !== 'undefined' ? style : {}} />
    );
};

const filterValueMoney = (props) => () => {
    var { money, suffix } = props;

	return (typeof money !== 'undefined' ? money : 0 ) + " " + (suffix ? t(suffix) : t("vnđ"));
};

const OptionUserRole = (props) => {
    var { value, name, user_role, onChange } = props;
    var { listuserroles } = props.data;

    return (
        <select onChange={(e) => {onChange(e)}} value={value} name={name} className="form-control">
            {
                listuserroles.map(function (v, i) {
                    return (
                        <option value={v.id} key={i} defaultValue={v.id === user_role ? "selected": ""}>{t(v.description)}</option>
                    );
                })
            }
        </select>
    );
};

const ReactTable = (props) => {
    var { data, columns, groupButton, handleTableChange, page, sizePerPage, totalSize, expandRow, alClassName } = props;

    return (
        <ToolkitProvider keyField="id" data={ data } columns={ columns } search >
            {
                props_table => (
                    <div>
                        <SearchBar { ...props_table.searchProps } />
                        {typeof groupButton === "function" ? groupButton() : ""}
                        <BootstrapTable classes={alClassName} remote={ { search: true, pagination: true, sort: false } } { ...props_table.baseProps } pagination={ paginationFactory({ page, sizePerPage, totalSize }) } filter={ filterFactory() } onTableChange={ handleTableChange } expandRow={ expandRow ? expandRow : {} } wrapperClasses="table-responsive" striped hover condensed />
                    </div>
                )
            }
        </ToolkitProvider>
    );
};

const formatterServiceType = (row = []) => () => {
	if (row.service_type === 0) {
		return t("Like");
	}
	if (row.service_type === 1) {
		return t("Comment");
	}
	if (row.service_type === 2) {
		return t("Share");
	}
	if (row.service_type === 3) {
		return t("Like Comment");
	}
	if (row.service_type === 4) {
		return t("Follow");
	}
	if (row.service_type === 5) {
		return t("Like Fanpage");
	}
	if (row.service_type === 6) {
		return t("Đánh giá và Check in Fanpage");
	}
	if (row.service_type === 7) {
		return t("Mắt Livestream");
	}
	if (row.service_type === 8) {
		return t("View Video");
	}
	if (row.service_type === 9) {
		return t("View Story");
	}
	if (row.service_type === 10) {
		return t("Thành viên Group");
	}
	if (row.service_type === 11) {
		return t("Yêu Thích");
	}
	if (row.service_type === 12) {
		return t("Retweet");
	}
	if (row.service_type === 13) {
		return t("Comment và Hình ảnh");
	}
	if (row.service_type === 14) {
		return t("License key");
	}
	if (row.service_type === 15) {
		return t("Traffic");
	}
	return "";
};

const formatterSocial = (row = []) => () => {
	if (row.social === 0) {
		return t("Facebook");
	}
	if (row.social === 1) {
		return t("Instagram");
	}
	if (row.social === 2) {
		return t("Youtube");
	}
	if (row.social === 3) {
		return t("Tiktok");
	}
	if (row.social === 4) {
		return t("Shopee");
	}
	if (row.social === 5) {
		return t("Google");
	}
	if (row.social === 6) {
		return t("Telegram");
	}
	if (row.social === 7) {
		return t("Twitter");
	}
	if (row.social === 8) {
		return t("Tripadvisor");
	}
	if (row.social === 9) {
		return t("FES");
	}
	return "";
};

const formatterCreateAt = (time = '0') => () => {
	var d = new Date(time*1000);
	var month = '' + (d.getMonth() + 1);
	var day = '' + d.getDate();
	var year = d.getFullYear();
	var hour = '' + (d.getHours());
	var min = '' + d.getMinutes();
	var sec = '' + d.getSeconds();

	if (month.length < 2)
		month = '0' + month;
	if (day.length < 2)
		day = '0' + day;
	if (hour.length < 2)
		hour = '0' + hour;
	if (min.length < 2)
		min = '0' + min;
	if (sec.length < 2)
		sec = '0' + sec;
	
	return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
};

const formatterProvider = (row = []) => () => {
	if (row.provider === 0) {
		return t("Tự chạy");
	}
	if (row.provider === 1) {
		return t("Mfb.vn");
	}
	if (row.provider === 2) {
		return t("FES");
	}
	return "";
};

const formatterService = (row = []) => () => {
	return formatterSocial(row)() + " - " +  row.name;
};

const convertIdServiceTypeToText = (type) => () => {
	var text_type = "";
	if (type === 0) {
		text_type = "like";
	}
	if (type === 1) {
		text_type = "comment";
	}
	if (type === 2) {
		text_type = "share";
	}
	if (type === 3) {
		text_type = "like comment";
	}
	if (type === 4) {
		text_type = "follow";
	}
	if (type === 5) {
		text_type = "like fanpage";
	}
	if (type === 6) {
		text_type = "đánh giá và check in fanpage";
	}
	if (type === 7) {
		text_type = "mắt livestream";
	}
	if (type === 8) {
		text_type = "view video";
	}
	if (type === 9) {
		text_type = "view story";
	}
	if (type === 10) {
		text_type = "thành viên group";
	}
	if (type === 11) {
		text_type = "yêu thích";
	}
	if (type === 12) {
		text_type = "retweet";
	}
	if (type === 13) {
		text_type = "comment và hình ảnh";
	}
	if (type === 14) {
		text_type = "license key";
	}
	if (type === 15) {
		text_type = "traffic";
	}
	return text_type;
};

const convertIdSocialToText = (social) => () => {
	var text_social = "";
	if (social === 0) {
		text_social = "facebook";
	}
	if (social === 1) {
		text_social = "instagram";
	}
	if (social === 2) {
		text_social = "youtube";
	}
	if (social === 3) {
		text_social = "tiktok";
	}
	if (social === 4) {
		text_social = "shopee";
	}
	if (social === 5) {
		text_social = "google";
	}
	if (social === 6) {
		text_social = "telegram";
	}
	if (social === 7) {
		text_social = "twitter";
	}
	if (social === 8) {
		text_social = "tripadvisor";
	}
	if (social === 9) {
		text_social = "fes";
	}
	return text_social;
};

const convertTextSocialToId = (social) => () => {
	var id_social = "";
	if (social === "facebook") {
		id_social = 0;
	}
	if (social === "instagram") {
		id_social = 1;
	}
	if (social === "youtube") {
		id_social = 2;
	}
	if (social === "tiktok") {
		id_social = 3;
	}
	if (social === "shopee") {
		id_social = 4;
	}
	if (social === "google") {
		id_social = 5;
	}
	if (social === "telegram") {
		id_social = 6;
	}
	if (social === "twitter") {
		id_social = 7;
	}
	if (social === "tripadvisor") {
		id_social = 8;
	}
	if (social === "fes") {
		id_social = 9;
	}
	return id_social;
};

const convertFullNameBankFromShortName = (data) => () => {
    var fullname_bank = "";

	if (data === "vietcombank") {
		fullname_bank = "Ngân hàng Ngoại thương Việt Nam (VIETCOMBANK)";
	}
	if (data === "bidv") {
		fullname_bank = "Ngân hàng Đầu tư và Phát triển Việt Nam (BIDV)";
	}
	if (data === "vietinbank") {
		fullname_bank = "Ngân hàng Công Thương Việt Nam (VIETINBANK)";
	}
	if (data === "vpbank") {
		fullname_bank = "Ngân hàng Việt Nam Thịnh Vượng (VPBANK)";
	}
	if (data === "acb") {
		fullname_bank = "Ngân hàng Á Châu (ACB)";
	}
	if (data === "techcombank") {
		fullname_bank = "Ngân hàng Kỹ thương Việt Nam (TECHCOMBANK)";
	}
	if (data === "mb") {
		fullname_bank = "Ngân hàng TMCP Quân Đội (MB)";
	}
	if (data === "hdbank") {
		fullname_bank = "Ngân hàng TMCP Phát triển TP. HCM (HD BANK)";
	}
	if (data === "lpbank") {
		fullname_bank = "Ngân hàn Bưu điện Liên Việt (LPBANK)";
	}
	if (data === "shb") {
		fullname_bank = "Ngân hàng TMCP Sài Gòn - Hà Nội (SHB)";
	}
	if (data === "tpbank") {
		fullname_bank = "Ngân hàng TMCP Tiên Phong (TPBANK)";
	}
	if (data === "seabank") {
		fullname_bank = "Ngân hàng TMCP Đông Nam Á (SEABANK)";
	}
	if (data === "msb") {
		fullname_bank = "Ngân hàng Hàng hải Việt Nam (MSB)";
	}
	if (data === "namabank") {
		fullname_bank = "Ngân hàng TMCP Nam Á (NAM A BANK)";
	}
	if (data === "vib") {
		fullname_bank = "Ngân hàng TMCP Quốc tế Việt Nam (VIB)";
	}
	if (data === "ocb") {
		fullname_bank = "Ngân hàng TMCP Phương Đông (OCB)";
	}
	if (data === "pgbank") {
		fullname_bank = "Ngân hàng TMCP Xăng dầu Petrolimex (PGBANK)";
	}
	if (data === "ncb") {
		fullname_bank = "Ngân hàng TMCP Quốc Dân (NCB)";
	}
	if (data === "eximbank") {
		fullname_bank = "Ngân hàng TMCP Xuất nhập khẩu Việt Nam (EXIMBANK)";
	}
	if (data === "sacombank") {
		fullname_bank = "Ngân hàng TMCP Sài Gòn Thương Tín (SACOMBANK)";
	}
	if (data === "abbank") {
		fullname_bank = "Ngân hàng An Bình (ABBANK)";
	}
	if (data === "kienlongbank") {
		fullname_bank = "Ngân hàng Kiên Long (KIEN LONG BANK)";
	}
	if (data === "vietabank") {
		fullname_bank = "Ngân hàng TMCP Việt Á (VIET A BANK)";
	}
	if (data === "vietbank") {
		fullname_bank = "Ngân hàng TMCP Việt Nam Thương Tín (VIETBANK)";
	}
	if (data === "saigonbank") {
		fullname_bank = "Ngân hàng TMCP Sài Gòn Công Thương (SAIGONBANK)";
	}
	if (data === "bacabank") {
		fullname_bank = "Ngân hàng Bắc Á (BAC A BANK)";
	}
	if (data === "vietcapitalbank") {
		fullname_bank = "Ngân hàng Bản Việt (VIET CAPITAL BANK)";
	}
	if (data === "scb") {
		fullname_bank = "Ngân hàng TMCP Sài Gòn (SCB)";
	}
	if (data === "agribank") {
		fullname_bank = "Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam (AGRIBANK)";
	}
	if (data === "pvcombank") {
		fullname_bank = "Ngân hàng TMCP Đại chúng Việt Nam (PVCOMBANK)";
	}
	if (data === "dongabank") {
		fullname_bank = "Ngân hàng Thương mại cổ phần Đông Á (DONG A BANK)";
	}
	if (data === "bvb") {
		fullname_bank = "Ngân hàng TMCP Bảo Việt (BVB)";
	}

    return fullname_bank;
};

const DataListCardBank = (props) => {
    var { list } = props;

    return (
        <datalist id={list}>
			<option value="vietcombank">{convertFullNameBankFromShortName("vietcombank")()}</option>
			<option value="bidv">{convertFullNameBankFromShortName("bidv")()}</option>
			<option value="vietinbank">{convertFullNameBankFromShortName("vietinbank")()}</option>
			<option value="vpbank">{convertFullNameBankFromShortName("vpbank")()}</option>
			<option value="acb">{convertFullNameBankFromShortName("acb")()}</option>
			<option value="techcombank">{convertFullNameBankFromShortName("techcombank")()}</option>
			<option value="mb">{convertFullNameBankFromShortName("mb")()}</option>
			<option value="hdbank">{convertFullNameBankFromShortName("hdbank")()}</option>
			<option value="lpbank">{convertFullNameBankFromShortName("lpbank")()}</option>
			<option value="shb">{convertFullNameBankFromShortName("shb")()}</option>
			<option value="tpbank">{convertFullNameBankFromShortName("tpbank")()}</option>
			<option value="seabank">{convertFullNameBankFromShortName("seabank")()}</option>
			<option value="msb">{convertFullNameBankFromShortName("msb")()}</option>
			<option value="namabank">{convertFullNameBankFromShortName("namabank")()}</option>
			<option value="vib">{convertFullNameBankFromShortName("vib")()}</option>
			<option value="ocb">{convertFullNameBankFromShortName("ocb")()}</option>
			<option value="pgbank">{convertFullNameBankFromShortName("pgbank")()}</option>
			<option value="ncb">{convertFullNameBankFromShortName("ncb")()}</option>
			<option value="eximbank">{convertFullNameBankFromShortName("eximbank")()}</option>
			<option value="sacombank">{convertFullNameBankFromShortName("sacombank")()}</option>
			<option value="abbank">{convertFullNameBankFromShortName("abbank")()}</option>
			<option value="kienlongbank">{convertFullNameBankFromShortName("kienlongbank")()}</option>
			<option value="vietabank">{convertFullNameBankFromShortName("vietabank")()}</option>
			<option value="vietbank">{convertFullNameBankFromShortName("vietbank")()}</option>
			<option value="saigonbank">{convertFullNameBankFromShortName("saigonbank")()}</option>
			<option value="bacabank">{convertFullNameBankFromShortName("bacabank")()}</option>
			<option value="vietcapitalbank">{convertFullNameBankFromShortName("vietcapitalbank")()}</option>
			<option value="scb">{convertFullNameBankFromShortName("scb")()}</option>
			<option value="agribank">{convertFullNameBankFromShortName("agribank")()}</option>
			<option value="pvcombank">{convertFullNameBankFromShortName("pvcombank")()}</option>
			<option value="dongabank">{convertFullNameBankFromShortName("dongabank")()}</option>
			<option value="bvb">{convertFullNameBankFromShortName("bvb")()}</option>
		</datalist>
    );
};

const IconServices = (props) => {
    var { id, type } = props;

    return (
        <Fragment>
            {
                id === "facebook" ?
                    <i className={"bi bi-facebook" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#0665d0", "fontSize": "22px" }}></i>
                : id === "instagram" ?
                    <i className={"bi bi-instagram" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#a900ff", "fontSize": "22px" }}></i>
                : id === "youtube" ?
                    <i className={"bi bi-youtube" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#ff0000", "fontSize": "22px" }}></i>
                : id === "tiktok" ?
                    <i className={"al-tiktok" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "width": "22px", "height": "22px", "marginLeft": (type === "navbar" ? "10px" : "inherit") }} ></i>
                : id === "shopee" ?
                    <i className={"al-shopee" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "width": "22px", "height": "32px", "marginLeft": (type === "navbar" ? "10px" : "inherit") }} ></i>
                : id === "google" ?
                    <i className={"bi bi-google" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "fontSize": "22px" }}></i>
                : id === "telegram" ?
                    <i className={"bi bi-telegram" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#0665d0", "fontSize": "22px" }}></i>
                : id === "twitter" ?
                    <i className={"bi bi-twitter" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#0665d0", "fontSize": "22px" }}></i>
                : id === "tripadvisor" ?
                    <i className={"fa fa-tripadvisor" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#65E19F", "fontSize": "22px" }}></i>
                : id === "fes" ?
					<i className={"al-feslogin" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "width": "22px", "height": "22px", "marginLeft": (type === "navbar" ? "10px" : "inherit") }} ></i>
                : id === "other" ?
                    <i className={"bi bi-grid-fill" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#0665d0", "fontSize": "22px" }}></i>
                : id === "tools" ?
                    <i className={"bi bi-tools" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#0665d0", "fontSize": "22px" }}></i>
                : id === "extensions" ?
                    <i className={"bi bi-plugin" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#0665d0", "fontSize": "22px" }}></i>
                : id === "banks" ?
                    <i className={"bi bi-cash-coin" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#d0c206", "fontSize": "22px" }}></i>
                : id === "logs" ?
                    <i className={"bi bi-graph-up-arrow" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#49d006", "fontSize": "22px" }}></i>
                : id === "agency" ?
                    <i className={"bi bi-shop" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "color": "#06ced0", "fontSize": "22px" }}></i>
                : <i className={"bi bi-link-45deg" + (type === "navbar" ? " micon" : " mr-1 ml-1")} style={{ "fontSize": "22px" }}></i>
            }
        </Fragment>
    );
}

const FormatterImage = (props) => {
    var { image } = props;
	var arr_images = image.split("\n");

    return (
        <img src={arr_images[0]} alt="" />
    );
};

const FormatterLogAction = (props) => {
    var { action, money, price, money_processed } = props;

    return (
		<Fragment>
			<span className="badge badge-warning al-badge">
				<FormatterMoney money={money} />
			</span>
			<span className="al-vaal-s">
				{(action === "buy" || action === "minus" || action === "extend" ? " - " : (action === "plus" || action === "refund" ? " + " : "") )}
			</span>
			<span className={"badge al-badge" + (action === "buy" || action === "minus" || action === "extend" ? " badge-danger" : (action === "plus" || action === "refund" ? " badge-success" : "") )}>
				<FormatterMoney money={price} />
			</span>
			<span className="al-vaal-s"> = </span>
			<span className="badge badge-primary al-badge">
				<FormatterMoney money={money_processed} />
			</span>
		</Fragment>
    );
};

const formatterLogServer = (props) => () => {
    var { name, server_name, action, servers_id } = props;

	if (action === "buy" || action === "refund" || action === "extend" || (action === "plus" && servers_id !== 0)) {
		return name + " - " + server_name;
	} else if (action === "minus" || action === "plus") {
		return t("Admin");
	} else {
		return "";
	}
};

function FormatterStatus(props) {
	var { status } = props;

	if (status === 0) {
		return <span className="badge badge-success al-badge"><i className="bi bi-check-lg mr-1"></i>{t("Hoàn thành")}</span>;
	} else if (status === 1) {
		return (
			<span className="badge badge-primary al-badge"><i className="bi bi-arrow-repeat mr-1"></i>{t("Đang thực hiện")}</span>
		);
	} else if (status === 2) {
		return (
			<span className="badge badge-danger al-badge"><i className="bi bi-x-circle mr-1"></i>{t("Hủy đơn")}</span>
		);
	} else if (status === 3) {
		return (
			<span className="badge badge-warning al-badge"><i className="bi bi-slash-circle mr-1"></i>{t("ID lỗi")}</span>
		);
	} else if (status === 4) {
		return (
			<span className="badge badge-info al-badge"><i className="bi bi-reply mr-1"></i>{t("Hoàn tiền")}</span>
		);
	} else {
		return ('');
	}
}

function FormatterApprove(props) {
	var { approve } = props;

	if (approve === 1) {
		return <span className="badge badge-outline-success al-badge"><i className="bi bi-check-lg mr-1"></i>{t("Xác nhận")}</span>;
	} else if (approve === 0) {
		return (
			<span className="badge badge-outline-primary al-badge"><i className="bi bi-clock-history mr-1"></i>{t("Chờ xác nhận")}</span>
		);
	} else {
		return ('');
	}
}

function FormatterCountSuccess(props) {
	var { count_success, quantity } = props.data;
	var percent = Math.ceil(count_success/quantity*100);
	return (
		<Fragment>
			<span className="text-center">{count_success} / {quantity}</span>
			<ProgressBar completed={percent} height="10px" labelSize="11px" className="al-count-success-progress" baseBgColor="#add8e6" bgColor="#0000ff" labelAlignment="center" />
		</Fragment>
	);
}

const NoticeVideo = (props) => {
    var { src } = props;
	
    var videoSrc = {
        type: "video",
        sources: [
            {
                src,
                provider: "youtube"
            }
        ]
    };

    return (
		<Plyr source={videoSrc} />
    );
};

const cleanObject = (obj) => () => {
    for (var propName in obj) {
		if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
			delete obj[propName];
		}
	}
	return obj;
};

const getObjectIdFromLink = (server_selected, value) => () => {
	var matches = null;
    var object_id = value;
	if (server_selected.social === 0) {
		if (value.indexOf("fb.com") > -1 || value.indexOf("facebook.com") > -1) {
			var a = value.match(/(.*)\/photo.php\?fbid=([0-9]{8,})/),
				k = value.match(/(.*)\/photo\/([0-9]{8,})\?/),
				i = value.match(/(.*)\/video.php\?v=([0-9]{8,})/),
				n = value.match(/(.*)\/watch\/\?v=([0-9]{8,})/),
				o = value.match(/(.*)\/watch\/live\/\?v=([0-9]{8,})/),
				c = value.match(/(.*)\/([0-9]{8,})/),
				f = value.match(/(.*)fbid=([0-9]{8,})/),
				d = value.match(/(.*)comment_id=([0-9]{8,})/),
				g = value.match(/(.*)set=a.([0-9]{8,})/),
				
				z = value.match(/live\/\?v=([0-9]+)/),
				x = value.match(/videos\/([0-9]+)/),
				y = value.match(/watch_permalink&v=([0-9]+)/),
				m = value.match(/ref=sharing&v=([0-9]+)/),
				p = value.match(/(.*)?v=([0-9]{8,})/);
			if (a !== null) {
				object_id = a[2];
			} else if (k !== null) {
				object_id = k[2];
			} else if (i !== null) {
				object_id = i[2];
			} else if (n !== null) {
				object_id = n[2];
			} else if (o !== null) {
				object_id = o[2];
			} else if (c !== null) {
				object_id = c[2];
			} else if (f !== null) {
				object_id = f[2];
			} else if (d !== null) {
				object_id = d[2];
			} else if (g !== null) {
				object_id = g[2];
			} else if (z !== null) {
				object_id = z[1];
			} else if (x !== null) {
				object_id = x[1];
			} else if (y !== null) {
				object_id = y[1];
			} else if (m !== null) {
				object_id = m[1];
			} else if (p !== null) {
				object_id = p[2];
			}
		}
	}
	if (server_selected.social === 3) {
		if (value.indexOf("tiktok.com") > -1) {
			matches = value.match(/\/[0-9]+/);
			if (server_selected.service_type === 4) {
				matches = value.match(/@(.*?)(\/|\?|$)/);
			}
			
			if (matches != null) {
				object_id = matches[0].replace(/@|\/|\?/g, "");
			}
		}
	}
	if (server_selected.social === 2) {
		if (value.indexOf("youtube.com") > -1) {
			matches = value.match(/v=(.*?)(&|$)/);
			// if (server_selected.service_type === 4) {
			// 	matches = value.match(/@(.*?)(\/|\?|$)/);
			// }
		
			if (matches != null) {
				object_id = matches[0].replace(/v=|&/g, "");
			}
		}
	}
	if (server_selected.social === 4) {
		if (value.indexOf("shopee.vn") > -1) {
			matches = value.match(/-i.(.*?)(\/|\?|$)/);
			if (server_selected.service_type === 4) {
				matches = value.match(/vn\/(.*?)(\/|\?|$)/);
			}
		
			if (matches != null) {
				if (server_selected.service_type === 4) {
					object_id = matches[0].replace(/vn\/|\/|\?/g, "");
				} else {
					var tmp = matches[0].replace(/-i.|\/|\?/g, "").split(".");
					object_id = tmp[1];
				}
			}
		}
	}
	if (server_selected.social === 7) {
		if (value.indexOf("twitter.com") > -1) {
			matches = value.match(/\/[0-9]+/);
			if (server_selected.service_type === 4) {
				matches = value.match(/com\/(.*?)(\/|\?|$)/);
			}
			
			if (matches != null) {
				object_id = matches[0].replace(/com\/|\/|\?/g, "");
			}
		}
	}
	return object_id;
};

const optionProviderType = (provider) => () => {
	var options = [];
	if (parseInt(provider) === 1) {
		options.push(<optgroup label="Facebook" key="Facebook">);
			options.push(<option value="0">Server người thật - Tăng like bài viết</option>);
			options.push(<option value="1">Server người thật - Tăng comment bài viết</option>);
			options.push(<option value="2">Server người thật - Tăng share bài viết</option>);
			options.push(<option value="3">Server người thật - Tăng like comment</option>);
			options.push(<option value="4">Server người thật - Tăng follow cá nhân</option>);
			options.push(<option value="5">Server người thật - Tăng like fanpage</option>);
			options.push(<option value="6">Server người thật - Tăng review fanpage</option>);
			options.push(<option value="7">Server người thật - Tăng mắt livestream v2</option>);
			options.push(<option value="8">Server auto - Tăng mem group giá rẻ (tốc độ: thường)</option>);
			options.push(<option value="9">Server auto - Tăng mem group giá rẻ (tốc độ: nhanh)</option>);
		options.push(</optgroup>);
		options.push(<optgroup label="Instagram" key="Instagram">);
			options.push(<option value="10">Tăng like - Server bấm tay (tốc độ: thường)</option>);
			options.push(<option value="11">Tăng like - Server bấm tay (tốc độ: nhanh)</option>);
			options.push(<option value="12">Tăng comment</option>);
			options.push(<option value="13">Tăng sub - Server bấm tay (tốc độ: thường)</option>);
			options.push(<option value="14">Tăng sub - Server bấm tay (tốc độ: nhanh)</option>);
			options.push(<option value="15">Tăng sub - Server bấm tay (tốc độ: cực nhanh)</option>);
		options.push(</optgroup>);
		options.push(<optgroup label="Tiktok" key="Tiktok">);
			options.push(<option value="16">Tăng like - (tốc độ: chậm)</option>);
			options.push(<option value="17">Tăng like - (tốc độ: trung bình)</option>);
			options.push(<option value="18">Tăng like - (tốc độ: nhanh)</option>);
			options.push(<option value="19">Tăng follow - (tốc độ: chậm)</option>);
			options.push(<option value="20">Tăng follow - (tốc độ: trung bình)</option>);
			options.push(<option value="21">Tăng follow - (tốc độ: nhanh)</option>);
			options.push(<option value="22">Tăng follow - (tốc độ: cực nhanh)</option>);
			options.push(<option value="23">Tăng comment - (tốc độ: trung bình)</option>);
			options.push(<option value="24">Tăng comment - (tốc độ: nhanh)</option>);
			options.push(<option value="25">Tăng view</option>);
			options.push(<option value="26">Tăng yêu thích</option>);
		options.push(</optgroup>);
		options.push(<optgroup label="Youtube" key="Youtube">);
			options.push(<option value="27">Tăng like - (tốc độ: tăng dần)</option>);
			options.push(<option value="28">Tăng like - (tốc độ: nhanh)</option>);
			options.push(<option value="29">Tăng comment</option>);
			options.push(<option value="30">Tăng sub</option>);
			options.push(<option value="31">Tăng view</option>);
		options.push(</optgroup>);
		options.push(<optgroup label="Shopee" key="Shopee">);
			options.push(<option value="32">Tăng like</option>);
			options.push(<option value="33">Tăng follow</option>);
			options.push(<option value="46">Tăng traffic</option>);
		options.push(</optgroup>);
		options.push(<optgroup label="Twitter" key="Twitter">);
			options.push(<option value="34">Tăng like</option>);
			options.push(<option value="35">Tăng follow</option>);
			options.push(<option value="36">Tăng comment</option>);
			options.push(<option value="37">Tăng retweet</option>);
		options.push(</optgroup>);
		options.push(<optgroup label="Google" key="Google">);
			options.push(<option value="38">Link map</option>);
			options.push(<option value="39">Link map + hình ảnh</option>);
			options.push(<option value="40">Tìm map và đánh giá</option>);
			options.push(<option value="41">Tìm map và đánh giá kèm hình ảnh</option>);
		options.push(</optgroup>);
		options.push(<optgroup label="Tripadvisor" key="Tripadvisor">);
			options.push(<option value="42">Đánh giá</option>);
		options.push(</optgroup>);
	}
	if (parseInt(provider) === 2) {
		options.push(<optgroup label="Feslogin" key="Feslogin">);
			options.push(<option value="43">License key (30 ngày)</option>);
			options.push(<option value="44">License key (365 ngày)</option>);
			options.push(<option value="45">License key (Vĩnh viễn)</option>);
		options.push(</optgroup>);
	}
	return options;
};

const isObject = (data) => () => {
	try {
		var obj = JSON.parse(data);
		if (typeof obj === "object") {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
}

const subStringText = (data) => () => {
	return data.length > 45 ? data.slice(0, 45 - 1) + "…" : data;
}

export {
    FormatterMoney,
    OptionUserRole,
    ReactTable,
    formatterSocial,
    formatterServiceType,
    formatterCreateAt,
    filterValueMoney,
	formatterProvider,
	formatterService,
	convertIdSocialToText,
	convertTextSocialToId,
	DataListCardBank,
	IconServices,
	convertFullNameBankFromShortName,
	FormatterImage,
	convertIdServiceTypeToText,
	FormatterLogAction,
	formatterLogServer,
	FormatterStatus,
	FormatterCountSuccess,
	FormatterApprove,
	NoticeVideo,
	cleanObject,
	getObjectIdFromLink,
	optionProviderType,
	isObject,
	subStringText
}