const initialState = {
	isLoading: false,
	isLoadingMenu: false,
	isLoadingServer: false,
	listservices: [],
	listmenus: [],
	listservers: [],
	listuserrolesprices: [],
	totalsize_admin_list_service: 0,
	totalsize_admin_list_service_server: 0,
	export_xlsx: []
};

function serviceReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_SERVICE':
			return {
				...state,
				isLoading: action.payload
			};
		case 'LOADING_SERVICE_MENU':
			return {
				...state,
				isLoadingMenu: action.payload
			};
		case 'LOADING_SERVICE_SERVER':
			return {
				...state,
				isLoadingServer: action.payload
			};
		case 'ADMIN_LIST_SERVICE':
			return {
				...state,
				listservices: action.payload.services,
				totalsize_admin_list_service: action.payload.totalsize
			};
		case 'ADMIN_LIST_SERVICE_MENU':
			return {
				...state,
				listmenus: action.payload.menus,
			};
		case 'ADMIN_LIST_SERVICE_SERVER':
			return {
				...state,
				listservers: action.payload.servers,
				totalsize_admin_list_service_server: action.payload.totalsize
			};
		case 'ADMIN_LIST_USER_ROLES_PRICES':
			return {
				...state,
				listuserrolesprices: action.payload.prices
			};
		case 'EXPORT_XLSX':
			return {
				...state,
				export_xlsx: action.payload.servers
			};
		default:
			return state;
	}
}

export default serviceReducer;