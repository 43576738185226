import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

function Preloader() {
	const [percent, setPercent] = useState(0);
	const [status] = useState("default");

	useEffect(() => {
		setTimeout(() => {
			const newValue = percent + 10;
			if (newValue <= 100) {
				setPercent(newValue);
			}
		}, 100);
	}, [percent]);

	return (
		<div className="pre-loader">
			<div className="pre-loader-box">
				<div className="loader-logo al-loader-logo">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
				<div className="loader-progress al-loader-progress" id="progress_div">
					<Progress
						status={status}
						percent={percent}
						theme={
							{
								error: {
									symbol: percent + '%',
									trailColor: 'pink',
									color: 'red'
								},
								default: {
									symbol: percent + '%',
									trailColor: 'lightblue',
									color: 'blue'
								},
								active: {
									symbol: percent + '%',
									trailColor: 'yellow',
									color: 'orange'
								},
								success: {
									symbol: percent + '%',
									trailColor: 'lime',
									color: 'green'
								}
							}
						}
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Preloader);