import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { setIsOpenSidebar } from "actions/websiteActions";
import { convertIdSocialToText, IconServices } from "components/theme_default/Function";
import { isMobile } from 'react-device-detect';

function Navbar(props) {
    const [toggleTab, setToggleTab] = useState("");
    const { role } = props.user.user;
    var { isOpenSidebar, listmenus } = props.website;
	const { convertIdSocialToText, setIsOpenSidebar } = props;

	useEffect(() => {
        var text_social = "";
        var path_order = window.location.pathname;
        var matches = path_order.match(/^\/(.*?)(\/|$)/);
        if (matches != null) {
            if (typeof matches[1] != 'undefined') {
                text_social = matches[1];
            }
        }
		setToggleTab(text_social);
	}, []);

    const onClick = (data) => {
        if (data === toggleTab) {
            data = "";
        }
        setToggleTab(data);
    }

    const onClickOpenSidebar = () => {
		setIsOpenSidebar();
	}

    const convertListMenus = (data) => {
        var new_data_item = [];
        var new_data_item_child = [];
        data.forEach((v, i) => {
            if (v.droppable || v.parent === 0) {
                new_data_item.push(v);
            } else {
                new_data_item_child.push(v);
            }
        });
        return [new_data_item, new_data_item_child];
    }

    const treeMenu = () => {
        var convert_listmenus = convertListMenus(listmenus);
        var convert_listmenus_item = convert_listmenus[0];
        var convert_listmenus_item_child = convert_listmenus[1];

        return (
            <Fragment>
                {
                    convert_listmenus_item.map((v, i) => {
                        return (
                            <Fragment key={i}>
                                <li className={(v.droppable ? "dropdown" : "") + (toggleTab === v.id && v.droppable ? " show" : "")}>
                                    {/* <Link to={(v.droppable ? "#" : convertIdSocialToText(v.data.social) + "/" + v.data.url)} className={"dropdown-toggle" + (v.droppable ? "" : " no-arrow")} data-option="off" onClick={v.droppable ? () => {onClick(v.id)} : isMobile && (() => {onClickOpenSidebar()})}>
                                        <IconServices id={v.id} type="navbar" />
                                        <span className="mtext">{t(v.text)}</span>
                                    </Link> */}
                                    {
                                        v.droppable ?
                                            <Fragment>
                                                <button className="btn al-btn-link dropdown-toggle" data-option="off" onClick={() => {onClick(v.id)}}>
                                                    <IconServices id={v.id} type="navbar" />
                                                    <span className="mtext">{t(v.text)}</span>
                                                </button>
                                                <ul className="submenu" style={{"display": (toggleTab === v.id ? "block" : "")}}>
                                                    {
                                                        convert_listmenus_item_child.map((v_child, i_child) => {
                                                            return (
                                                                <Fragment key={i_child}>
                                                                    {
                                                                        v.id === v_child.parent ?
                                                                            <li>
                                                                                <NavLink to={convertIdSocialToText(v_child.data.social) + "/" + v_child.data.url} onClick={isMobile ? () => {onClickOpenSidebar()} : ""}>{t(v_child.text)}</NavLink>
                                                                            </li>
                                                                        : ""
                                                                    }
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </Fragment>
                                        :
                                            <NavLink to={convertIdSocialToText(v.data.social) + "/" + v.data.url} className="dropdown-toggle no-arrow" data-option="off" onClick={() => { onClick(""); if (isMobile) {onClickOpenSidebar()} }}>
                                                <IconServices id={v.id} type="navbar" />
                                                <span className="mtext">{t(v.text)}</span>
                                            </NavLink>
                                    }
                                </li>
                            </Fragment>
                        );
                    })
                }
            </Fragment>
        )
    }

	return (
        <Fragment>
            <div className={"left-side-bar" + (isOpenSidebar ? "" : " open")}>
                <div className="brand-logo">
                    <Link to="/" className="text-dark">
                        {
                            window.location.hostname !== "localhost" && window.location.hostname !== "app.fesads.com" ?
                                window.location.hostname.toUpperCase()
                            :
                                <img src={require("./assets/vendors/images/deskapp-logo.png")} alt="" className="dark-logo" />
                        }
                    </Link>
                    <div className="close-sidebar" data-toggle="left-sidebar-close" onClick={() => {onClickOpenSidebar()}}>
                        <i className="ion-close-round"></i>
                    </div>
                </div>
                <div className="menu-block customscroll al-customscroll _mCS_3">
                    <div id="mCSB_3" className="mCustomScrollBox mCS-dark-2 mCSB_vertical mCSB_inside al-mCustomScrollBox" tabIndex="0">
                        <div id="mCSB_3_container" className="mCSB_container" dir="ltr">
                            <div className="sidebar-menu icon-style-1 icon-list-style-1">
                                <ul id="accordion-menu">
                                    {
                                        role === 0 || role === 1 ?
                                            <li className={"dropdown" + (toggleTab === "admin" ? " show" : "")}>
                                                <button className="btn al-btn-link dropdown-toggle" data-option="off" onClick={() => {onClick("admin")}}>
                                                    <span className="micon fa fa-user-secret"></span>
                                                    <span className="mtext">{t("Admin")}</span>
                                                </button>
                                                <ul className="submenu" style={{"display": (toggleTab === "admin" ? "block" : "")}}>
                                                    <li>
                                                        <NavLink to="/admin/notices" onClick={isMobile && (() => {onClickOpenSidebar()})}>{t("Quản lý thông báo")}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/admin/users" onClick={isMobile && (() => {onClickOpenSidebar()})}>{t("Quản lý tài khoản")}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/admin/services" onClick={isMobile && (() => {onClickOpenSidebar()})}>{t("Quản lý dịch vụ")}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/admin/banks" onClick={isMobile && (() => {onClickOpenSidebar()})}>{t("Quản lý bank")}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/admin/configs" onClick={isMobile && (() => {onClickOpenSidebar()})}>{t("Cấu hình website")}</NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                        : ""
                                    }
                                    {treeMenu()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"mobile-menu-overlay" + (isOpenSidebar ? "" : " show")} onClick={() => {onClickOpenSidebar()}}></div>
        </Fragment>
	);
}

Navbar.propTypes = {
	convertIdSocialToText: PropTypes.func.isRequired,
	setIsOpenSidebar: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    user: state.user,
    website: state.website
});
const mapDispatchToProps = {
    convertIdSocialToText,
    setIsOpenSidebar
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
