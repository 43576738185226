import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { saveAddUser } from "actions/userActions";
import Loading from "components/theme_default/Loading";
import { OptionUserRole } from "components/theme_default/Function";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaladduser(props) {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [repeatpassword, setRepeatPassword] = useState("");
    const [user_role, setUserRole] = useState(0);
    var { isOpenModalAddUser, openModal, saveAddUser } = props;
    var { listuserroles, isLoading } = props.user;

	const onClickSaveAddUser = () => {
		saveAddUser({ username, password, repeatpassword, user_role }, openModal);
	};

    const onChange = (e) => {
        if (e.target.name === "username") {
            setUserName(e.target.value);
        }
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
        if (e.target.name === "repeatpassword") {
            setRepeatPassword(e.target.value);
        }
        if (e.target.name === "user_role") {
            setUserRole(e.target.value);
        }
    }

	const onOpenModal = () => {
		openModal();
	};

    var optionUserRole = <OptionUserRole name="user_role" value={user_role} onChange={(e) => {onChange(e)}} data={{listuserroles, user_role}} />
    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => {onOpenModal()}} isOpen={isOpenModalAddUser}>
                <Loading isLoading={isLoading}/>
                <div className="modal-header">
                    <p className="mb-0 text-dark">{t("Thêm tài khoản")}</p>
                    <button onClick={() => {onOpenModal()}} type="button" className="close">×</button>
                </div>
                <ModalBody>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Tên tài khoản")}</label>
                        <div className="col-sm-12 col-md-9">
                            <input className="form-control" value={username} type="text" name="username" placeholder={t("username")} onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Mật khẩu")}</label>
                        <div className="col-sm-12 col-md-9">
                            <input className="form-control" value={password} type="password" name="password" placeholder={t("********")} onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Nhập lại mật khẩu")}</label>
                        <div className="col-sm-12 col-md-9">
                            <input className="form-control" value={repeatpassword} type="password" name="repeatpassword" placeholder={t("********")} onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Loại tài khoản")}</label>
                        <div className="col-sm-12 col-md-9">
                            {optionUserRole}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveAddUser()}} >{t("Lưu")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => {onOpenModal()}} >{t("Huỷ")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaladduser.propTypes = {
	saveAddUser: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
	user: state.user
});
const mapDispatchToProps = {
	saveAddUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaladduser);