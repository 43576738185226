import { connect } from "react-redux";
import { t } from "i18next";
import { Link } from "react-router-dom";

function Notfoundpage() {
	return (
		<div className="error-page d-flex align-items-center flex-wrap justify-content-center pd-20">
			<div className="pd-10">
				<div className="error-page-wrap text-center">
					<h1>{t("404")}</h1>
					<h3>{t("Error: 404 Page Not Found")}</h3>
					<p>{t("Rất tiếc, không thể truy cập trang bạn đang tìm kiếm")}<br/>{t("Vui lòng kiểm tra lại URL")}</p>
					<div className="pt-20 mx-auto max-width-200">
						<Link to="/" className="btn btn-primary btn-block btn-lg">
							{t("Trở lại trang chủ")}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Notfoundpage);