import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { saveEditUserPassword } from "actions/userActions";

function Changepassword(props) {
    const [password_old, setPasswordOld] = useState("");
    const [password_new, setPasswordNew] = useState("");
    const [repeatpassword_new, setRepeatPasswordNew] = useState("");
    const { saveEditUserPassword } = props;

	const onClickSaveEditUserPassword = () => {
		saveEditUserPassword({ password_old, password_new, repeatpassword_new });
	};

    const onChange = (e) => {
        if (e.target.name === "password_old") {
            setPasswordOld(e.target.value);
        }
        if (e.target.name === "password_new") {
            setPasswordNew(e.target.value);
        }
        if (e.target.name === "repeatpassword_new") {
            setRepeatPasswordNew(e.target.value);
        }
    }

	return (
        <Fragment>
            <div className="pd-20">
                <div className="form-group row">
                    <label className="col-sm-12 col-md-3 col-form-label">{t("Mật khẩu cũ")}</label>
                    <div className="col-sm-12 col-md-9">
                        <input className="form-control" value={password_old} type="password" name="password_old" placeholder={t("••••••••")} onChange={(e) => {onChange(e)}}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-12 col-md-3 col-form-label">{t("Mật khẩu mới")}</label>
                    <div className="col-sm-12 col-md-9">
                        <input className="form-control" value={password_new} type="password" name="password_new" placeholder={t("••••••••")} onChange={(e) => {onChange(e)}}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-12 col-md-3 col-form-label">{t("Nhập lại mật khẩu mới")}</label>
                    <div className="col-sm-12 col-md-9">
                        <input className="form-control" value={repeatpassword_new} type="password" name="repeatpassword_new" placeholder={t("••••••••")} onChange={(e) => {onChange(e)}}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-12 col-md-3 col-form-label"></label>
                    <div className="col-sm-12 col-md-9">
                        <button type="button" className="btn btn-primary" onClick={() => {onClickSaveEditUserPassword()}} >{t("Đổi mật khẩu")}</button>
                    </div>
                </div>
            </div>
        </Fragment>
	);
}

Changepassword.propTypes = {
    saveEditUserPassword: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    saveEditUserPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(Changepassword);