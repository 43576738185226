import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListLog } from "actions/logActions";
import { ReactTable, formatterCreateAt, FormatterLogAction, formatterLogServer } from "components/theme_default/Function";

function Listlogs(props) {
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(10);
	const [searchText, setSearchText] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [list_order_view, setListOrderView] = useState("");
    const { getListLog, formatterCreateAt, FormatterLogAction, formatterLogServer } = props;
    const { listlogs, totalsize } = props.log;
	const { role } = props.user.user;

	useEffect(() => {
		getListLog(page, sizePerPage, searchText, list_order_view);
	}, [getListLog, page, sizePerPage, searchText, list_order_view]);

	useEffect(() => {
		setTotalSize(totalsize);
	}, [totalsize]);
    
	const onClickView = (data) => {
		setPage(1);
		setSizePerPage(10);
		setListOrderView(data);
	}

	const columns = [{
		dataField: 'id',
		text: t("ID"),
		sort: true,
	}, {
		dataField: 'object_id',
		text: t("Object ID"),
		sort: true,
	}, {
		dataField: 'server_name',
		text: t("Server"),
		sort: true,
		formatter: (cell, row) => formatterLogServer(row)
	}, {
		dataField: 'money',
		text: t("Hành động"),
		sort: true,
		formatter: (cell, row) => FormatterLogAction(row)
	}, {
		dataField: 'note',
		text: t("Ghi chú"),
		sort: true,
		formatter: (cell, row) => t(cell)
	}, {
		dataField: 'create_at',
		text: t("Ngày tạo"),
		sort: true,
		formatter: (cell, row) => formatterCreateAt(cell)
	}, {
		dataField: 'username',
		text: t("User"),
		sort: true,
	}];

	const groupButton = () => {
		return (
			<div>
				{
					role === 0 || role === 1 ?
						<Fragment>
							<button onClick={() => {onClickView("all")}} type="button" className="btn btn-sm btn-primary mr-1">
								{t("Xem tất cả")}
							</button>
							<button onClick={() => {onClickView("")}} type="button" className="btn btn-sm btn-primary mr-1">
								{t("Xem riêng")}
							</button>
						</Fragment>
					: ""
				}
			</div>
		);
	}

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(10);
			setSearchText(searchText);
		} else {
			setPage(page);
			setSizePerPage(sizePerPage);
		}
	}

	return (
		<Fragment>
			<ReactTable columns={columns} data={listlogs} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" />
		</Fragment>
	);
}

Listlogs.propTypes = {
	getListLog: PropTypes.func.isRequired,
	formatterCreateAt: PropTypes.func.isRequired,
	FormatterLogAction: PropTypes.func.isRequired,
	formatterLogServer: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    log: state.log,
    user: state.user
});
const mapDispatchToProps = {
    getListLog,
    formatterCreateAt,
    FormatterLogAction,
    formatterLogServer
}

export default connect(mapStateToProps, mapDispatchToProps)(Listlogs);