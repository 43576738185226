import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import Createorder from "./createorder/Createorder";
import Listorders from "./listorders/Listorders";
import Loading from "components/theme_default/Loading";
import { useLocation } from "react-router-dom";

function Main(props) {
	const [tab, setTab] = useState("createorder");
    const { isLoading } = props.order;
    const location = useLocation();

    useEffect(() => {
        setTab("createorder");
    }, [location]);

	const onClick = (data) => {
		setTab(data);
	}

	return (
        <Fragment>
            <div className="pd-ltr-20 bg-white border-radius-4 box-shadow mb-30">
                <div className="tab">
                    <ul className="nav nav-pills" role="tablist">
                        <li className="nav-item col-lg-6">
						    <button className={"nav-link btn btn-sm al-btn-link btn-outline-primary text-blue text-center " + (tab === "createorder"? " active" : "")} to="#" onClick={(e) => {onClick("createorder")}}>{t("Dịch vụ")}</button>
                        </li>
                        <li className="nav-item col-lg-6 text-center">
						    <button className={"nav-link btn btn-sm al-btn-link btn-outline-primary text-blue text-center " + (tab === "listorders"? " active" : "")} to="#" onClick={(e) => {onClick("listorders")}}>{t("Lịch sử")}</button>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <Loading isLoading={isLoading}/>
                        <div className="tab-pane fade active show" id="home5" role="tabpanel">
                            <div className="pd-20">
                                {
                                    tab === "listorders" ?
                                        <Listorders/>
                                    : <Createorder/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
	);
}

const mapStateToProps = state => ({
    order: state.order
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);