const initialState = {
	isLoading: false,
	listbanks: [],
	listbanksapi: [],
	totalsize: 0
};

function bankReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_BANK':
			return {
				...state,
				isLoading: action.payload
			};
		case 'ADMIN_LIST_BANK':
			return {
				...state,
				listbanks: action.payload.banks,
				totalsize: action.payload.totalsize
			};
		case 'LIST_BANK_API':
			return {
				...state,
				listbanksapi: action.payload.data
			};
		default:
			return state;
	}
}

export default bankReducer;