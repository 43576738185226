import { Fragment } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { NoticeVideo } from "components/theme_default/Function";

function ThreeAndMoreItem(props) {
    var { index, arr_images, video, onClick } = props;

    var i = 0;
    if (video !== "") {
        i = -1;
    }

    return (
        <Fragment>
            <div className="row">
                <div className={video !== "" ? "col-sm-12 col-md-6 mb-3" : "col-6"}>
                    {
                        video !== "" ?
                            <NoticeVideo src={video} />
                        :
                            <Link className="h-100" to="/" data-glightbox="" data-gallery="image-popup" onClick={() => {onClick(i, index)}}>
                                <img className="rounded img-fluid" src={arr_images[i]} alt=""/>
                            </Link>
                    }
                </div>
                <div className={video !== "" ? "col-sm-12 col-md-6" : "col-6"}>
                    <Link to="/" data-glightbox="" data-gallery="image-popup" onClick={() => {onClick(i+1, index)}}>
                        <img className="rounded img-fluid" src={arr_images[i+1]} alt=""/>
                    </Link>
                    <div className="position-relative bg-dark mt-3 rounded" onClick={() => {onClick(i+2, index)}}>
                        <div className="hover-actions-item position-absolute top-50 start-50 translate-middle z-index-9">
                            <Link className="btn btn-link text-white" to="/">{t("Xem tất cả")}</Link>
                        </div>
                        <Link to="/" data-glightbox="" data-gallery="image-popup">
                            <img className="img-fluid opacity-50 rounded" src={arr_images[i+2]} alt=""/>
                        </Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

ThreeAndMoreItem.propTypes = {
};
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ThreeAndMoreItem);