import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { saveAddRole } from "actions/userActions";
import Loading from "components/theme_default/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaladdrole(props) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    var { isOpenModalAddRole, openModal, saveAddRole } = props;
    var { isLoadingRole } = props.user;

	const onClickSaveAddRole = () => {
		saveAddRole({ name, description }, openModal);
	};

	const onOpenModal = () => {
		openModal();
	};

	const onChange = (e) => {
        if (e.target.name === "name") {
            setName(e.target.value);
        }
        if (e.target.name === "description") {
            setDescription(e.target.value);
        }
	};

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => {onOpenModal()}} isOpen={isOpenModalAddRole}>
                <Loading isLoading={isLoadingRole}/>
                <div className="modal-header">
                    <p className="mb-0 text-dark">{t("Thêm loại tài khoản")}</p>
                    <button onClick={() => {onOpenModal()}} type="button" className="close">×</button>
                </div>
                <ModalBody>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Tên loại tài khoản")}</label>
                        <div className="col-sm-12 col-md-9">
                            <input className="form-control" value={name} type="text" name="name" placeholder={t("user")} onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Mô tả")}</label>
                        <div className="col-sm-12 col-md-9">
                            <input className="form-control" value={description} type="text" name="description" placeholder={t("Thành viên")} onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveAddRole()}} >{t("Lưu")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => {onOpenModal()}} >{t("Huỷ")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaladdrole.propTypes = {
	saveAddRole: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
	user: state.user
});
const mapDispatchToProps = {
	saveAddRole
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaladdrole);