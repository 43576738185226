import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getListNotice, deleteNotice } from "actions/noticeActions";
import { ReactTable, FormatterImage, subStringText } from "components/theme_default/Function";
import { Modaladdnotice, Modaleditnotice } from "./modal";

function Listnotices(props) {
	const [isOpenModalAddNotice, setIsOpenModalAddNotice] = useState(false);
	const [isOpenModalEditNotice, setIsOpenModalEditNotice] = useState(false);
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(10);
	const [searchText, setSearchText] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [editnotice, setEditNotice] = useState([]);
	const { listnotices, totalsize } = props.notice;
	const { getListNotice, deleteNotice, FormatterImage, subStringText } = props;
	
	useEffect(() => {
		getListNotice(page, sizePerPage, searchText);
	}, [getListNotice, page, sizePerPage, searchText]);

	useEffect(() => {
		setTotalSize(totalsize);
	}, [totalsize]);

	const onClickOpenModalAddNotice = () => {
		setIsOpenModalAddNotice(!isOpenModalAddNotice);
    };

	const onClickOpenModalEditNotice = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		setIsOpenModalEditNotice(!isOpenModalEditNotice);
		setEditNotice(row);
    };
	
	const onClickDeleteNotice = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		deleteNotice(row);
	};

	const columns = [{
		dataField: 'id',
		text: t("ID"),
		sort: true,
	}, {
		dataField: 'title',
		text: t("Tiêu đề"),
		sort: true,
	}, {
		dataField: 'content',
		text: t("Nội dung"),
		sort: true,
		formatter: (cell, row) => subStringText(cell)
	}, {
		dataField: 'video',
		text: t("Video"),
		sort: true,
		formatter: (cell, row) => <Link to={"https://www.youtube.com/watch?v=" + row.video} className="btn btn-link" target="_blank">{row.video}</Link>
	}, {
		dataField: 'image',
		text: t("Ảnh"),
		sort: true,
		formatter: (cell, row) =>  FormatterImage(row)
	}, {
		dataField: '-',
		text: t("Thao tác"),
		formatExtraData: {page, sizePerPage, searchText},
		formatter: (cell, row, rowIndex, formatExtraData) => 
			<Fragment>
				<button onClick={() => {onClickOpenModalEditNotice(row, formatExtraData)}} className="btn btn-primary btn-sm mr-1">
					<i className="fa fa-pencil-square-o"></i>
				</button>
				<button onClick={() => {onClickDeleteNotice(row, formatExtraData)}} className="btn btn-danger btn-sm mr-1">
					<i className="fa fa-trash-o"></i>
				</button>
			</Fragment>
	}];

	const groupButton = () => {
		return (
			<div>
				<button onClick={() => {onClickOpenModalAddNotice()}} type="button" className="btn btn-sm btn-success mr-1">
					<i className="fa fa-plus mr-1"></i> {t("Thêm thông báo")}
				</button>
			</div>
		);
	}

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(10);
			setSearchText(searchText);
		} else {
			setPage(page);
			setSizePerPage(sizePerPage);
		}
	}

	return (
		<Fragment>
			<ReactTable columns={columns} data={listnotices} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} />
			{isOpenModalAddNotice ? <Modaladdnotice openModal={onClickOpenModalAddNotice} isOpenModalAddNotice={isOpenModalAddNotice} /> : '' }
			{isOpenModalEditNotice ? <Modaleditnotice openModal={onClickOpenModalEditNotice} isOpenModalEditNotice={isOpenModalEditNotice} editnotice={editnotice} /> : '' }
		</Fragment>
	);
}

Listnotices.propTypes = {
	getListNotice: PropTypes.func.isRequired,
	deleteNotice: PropTypes.func.isRequired,
	FormatterImage: PropTypes.func.isRequired,
	subStringText: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	notice: state.notice
});
const mapDispatchToProps = {
	getListNotice,
	deleteNotice,
	FormatterImage,
	subStringText
}

export default connect(mapStateToProps, mapDispatchToProps)(Listnotices);