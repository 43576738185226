import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListRole, deleteRole } from "actions/userActions";
import { ReactTable } from "components/theme_default/Function";
import { Modaleditrole, Modaladdrole } from "./modal";

function Listuserroles(props) {
	const [isOpenModalEditRole, setIsOpenModalEditRole] = useState(false);
	const [isOpenModalAddRole, setIsOpenModalAddRole] = useState(false);
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(10);
	const [searchText, setSearchText] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [editrole, setEditRole] = useState([]);
	const { listuserroles, totalsize_admin_list_role } = props.user;
	const { getListRole, deleteRole } = props;

	useEffect(() => {
		getListRole(page, sizePerPage, searchText);
	}, [getListRole, page, sizePerPage, searchText]);

	useEffect(() => {
		setTotalSize(totalsize_admin_list_role);
	}, [totalsize_admin_list_role]);

	const onClickOpenModalEditRole = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		setIsOpenModalEditRole(!isOpenModalEditRole);
		setEditRole(row);
    };

	const onClickDeleteRole = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		deleteRole(row);
	};

	const onClickOpenModalAddRole = () => {
		setIsOpenModalAddRole(!isOpenModalAddRole);
	};

	const columns = [{
		dataField: 'id',
		text: t("ID"),
		sort: true,
	}, {
		dataField: 'name',
		text: t("Tên loại tài khoản"),
		sort: true,
	}, {
		dataField: 'slug',
		text: t("Slug"),
		sort: true,
	}, {
		dataField: 'description',
		text: t("Mô tả"),
		sort: true,
	}, {
		text: t("Thao tác"),
		dataField: '-',
		formatExtraData: {page, sizePerPage, searchText},
		formatter: (cell, row, rowIndex, formatExtraData) => 
			<Fragment>
				<button onClick={() => {onClickOpenModalEditRole(row, formatExtraData)}} className="btn btn-primary btn-sm mr-1">
					<i className="fa fa-pencil-square-o"></i>
				</button>
				<button onClick={() => {onClickDeleteRole(row, formatExtraData)}} className="btn btn-danger btn-sm mr-1">
					<i className="fa fa-trash-o"></i>
				</button>
			</Fragment>
	}];

	const groupButton = () => {
		return (
			<div>
				<button onClick={() => {onClickOpenModalAddRole()}} type="button" className="btn btn-sm btn-success mr-1">
					<i className="fa fa-fw fa-plus mr-1"></i> {t("Thêm loại tài khoản")}
				</button>
			</div>
		);
	}

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(10);
			setSearchText(searchText);
		} else {
			setPage(page);
			setSizePerPage(sizePerPage);
		}
	}

	return (
		<Fragment>
			<ReactTable columns={columns} data={listuserroles} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" />
			{isOpenModalEditRole ? <Modaleditrole openModal={onClickOpenModalEditRole} isOpenModalEditRole={isOpenModalEditRole} editrole={editrole} /> : '' }
			{isOpenModalAddRole ? <Modaladdrole openModal={onClickOpenModalAddRole} isOpenModalAddRole={isOpenModalAddRole} /> : '' }
		</Fragment>
	);
}

Listuserroles.propTypes = {
	getListRole: PropTypes.func.isRequired,
	deleteRole: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	user: state.user
});
const mapDispatchToProps = {
	getListRole,
	deleteRole
}

export default connect(mapStateToProps, mapDispatchToProps)(Listuserroles);