import { useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { logoutUser } from "actions/userActions";
import { setIsOpenSidebar } from "actions/websiteActions";
import { FormatterMoney } from "./Function";

function Header(props) {
    const [toggleMenuuser, setToggleMenuuser] = useState(false);
	const { username, fullname, money, gender } = props.user.user;
	const { logoutUser, setIsOpenSidebar } = props;

    const onBlurDropdown = (e) => {
		setTimeout(() => {
			setToggleMenuuser(false);
		}, 200);
		if (e.relatedTarget !== null) {
			if (e.relatedTarget.className === "dropdown-item al-drop-item-blur") {
				return false;
			}
		}
    }

    const onClickDropdown = () => {
        setToggleMenuuser(!toggleMenuuser);
    }

    const onClickLogout = () => {
		logoutUser();
	}

    const onClickOpenSidebar = () => {
		setIsOpenSidebar();
	}

	return (
        <div className="header">
			<div className="header-left">
				<div className="menu-icon bi bi-list" onClick={() => {onClickOpenSidebar()}}></div>
			</div>
			<div className="header-right">
				<div className="user-info-dropdown" onBlur={(e) => {onBlurDropdown(e)}}>
					<div className={"dropdown" + (toggleMenuuser ? " show" : "")}>
						<Link className="dropdown-toggle" to="#" onClick={() => {onClickDropdown()}} role="button" data-toggle="dropdown">
							<span className="user-icon">
								{
									gender === 0 ?
										<img src={require("components/theme_default/assets/vendors/images/employee_male_1.png")} alt="" />
									:
										<img src={require("components/theme_default/assets/vendors/images/employee_female_1.png")} alt="" />
								}
							</span>
							<span className="user-name">{fullname||username} - {t("Số dư:")} <FormatterMoney money={money}/></span>
						</Link>
						<div className={"dropdown-menu dropdown-menu-right dropdown-menu-icon-list" + (toggleMenuuser ? " show" : "")}>
							<Link to="/profile" className="dropdown-item al-drop-item-blur" onClick={() => {onClickDropdown()}}><i className="dw dw-user1"></i> {t("Thông tin")}</Link>
							<Link to="/" className="dropdown-item al-drop-item-blur" onClick={() => {onClickLogout()}}><i className="dw dw-logout"></i> {t("Đăng xuất")}</Link>
						</div>
					</div>
				</div>
				{
					window.location.hostname !== "localhost" && window.location.hostname !== "app.fesads.com" ?
						""
					:
						<div className="github-link">
							<Link to="https://t.me/feslogin" target="_blank">
								<img src={require("components/theme_default/assets/vendors/images/telegram.png")} alt="" />
							</Link>
						</div>
				}
			</div>
		</div>
	);
}

Header.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	setIsOpenSidebar: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	user: state.user
});
const mapDispatchToProps = {
	logoutUser,
	setIsOpenSidebar
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
