import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { saveEditUserInformation, saveEditUserSocial } from "actions/userActions";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function Settings(props) {
    const [fullname, setFullName] = useState(props.user.user.fullname);
    const [email, setEmail] = useState(props.user.user.email);
    const [birthday, setBirthday] = useState(props.user.user.birthday ? new Date(props.user.user.birthday) : "");
    const [gender, setGender] = useState(props.user.user.gender);
    const [phonenumber, setPhonenumber] = useState(props.user.user.phonenumber);
    const [address, setAddress] = useState(props.user.user.address);
    const [facebook, setFacebook] = useState(props.user.user.facebook);
    const [instagram, setInstagram] = useState(props.user.user.instagram);
    const [tiktok, setTiktok] = useState(props.user.user.tiktok);
    const [twitter, setTwitter] = useState(props.user.user.twitter);
    const [telegram, setTelegram] = useState(props.user.user.telegram);
    const { saveEditUserInformation, saveEditUserSocial } = props;
    
	const onClickSaveEditUserInformation = () => {
		saveEditUserInformation({ fullname, email, birthday, gender, phonenumber, address });
	};
    
	const onClickSaveEditUserSocial = () => {
		saveEditUserSocial({ facebook, instagram, tiktok, twitter, telegram });
	};

    const onChange = (e) => {
        if (e.target.name === "fullname") {
            setFullName(e.target.value);
        }
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
        if (e.target.name === "gender") {
            setGender(e.target.value);
        }
        if (e.target.name === "phonenumber") {
            setPhonenumber(e.target.value);
        }
        if (e.target.name === "address") {
            setAddress(e.target.value);
        }
        if (e.target.name === "facebook") {
            setFacebook(e.target.value);
        }
        if (e.target.name === "instagram") {
            setInstagram(e.target.value);
        }
        if (e.target.name === "tiktok") {
            setTiktok(e.target.value);
        }
        if (e.target.name === "twitter") {
            setTwitter(e.target.value);
        }
        if (e.target.name === "telegram") {
            setTelegram(e.target.value);
        }
    }
	
	const onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

	return (
        <Fragment>
            <ul className="profile-edit-list row">
                <li className="weight-500 col-md-6">
                    <h4 className="text-blue h5 mb-20">
                        {t("Thông tin cá nhân")}
                    </h4>
                    <div className="form-group">
                        <label>{t("Họ và tên")}</label>
                        <input className="form-control form-control-lg" value={fullname} type="text" name="fullname" placeholder="" onChange={(e) => {onChange(e)}} />
                    </div>
                    <div className="form-group">
                        <label>{t("Email")}</label>
                        <input className="form-control form-control-lg" value={email} type="text" name="email" placeholder="" onChange={(e) => {onChange(e)}} />
                    </div>
                    <div className="form-group">
                        <label>{t("Ngày sinh")}</label>
                        <DatePicker className="form-control form-control-lg" value={birthday} type="text" name="birthday" placeholder="" onChange={setBirthday} format="dd/MM/y" />
                    </div>
                    <div className="form-group">
                        <label>{t("Giới tính")}</label>
                        <div className="d-flex">
                            <div className="custom-control custom-radio mb-20 mr-20">
                                <input type="radio" id="gender_male" value="0" name="gender" className="custom-control-input" onChange={(e) => {onChange(e)}} defaultChecked={gender === 0} />
                                <label className="custom-control-label weight-400" htmlFor="gender_male"><span className='al-vaal-s'>{t("Nam")}</span></label>
                            </div>
                            <div className="custom-control custom-radio mb-20 mr-20">
                                <input type="radio" id="gender_female" value="1" name="gender" className="custom-control-input" onChange={(e) => {onChange(e)}} defaultChecked={gender === 1} />
                                <label className="custom-control-label weight-400" htmlFor="gender_female"><span className='al-vaal-s'>{t("Nữ")}</span></label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{t("Số điện thoại")}</label>
                        <input className="form-control form-control-lg" value={phonenumber} type="text" name="phonenumber" placeholder="" onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                    </div>
                    <div className="form-group">
                        <label>{t("Địa chỉ")}</label>
                        <textarea className="form-control al-form-control-textarea" value={address} type="text" name="address" placeholder="" rows="2" onChange={(e) => {onChange(e)}}></textarea>
                    </div>
                    <div className="form-group mb-0">
                        <button type="button" className="btn btn-primary" onClick={() => {onClickSaveEditUserInformation()}} >{t("Lưu")}</button>
                    </div>
                </li>
                <li className="weight-500 col-md-6">
                    <h4 className="text-blue h5 mb-20">
                        {t("Liên hệ")}
                    </h4>
                    <div className="form-group">
                        <label>{t("Facebook")}</label>
                        <input className="form-control form-control-lg" value={facebook} type="text" name="facebook" placeholder="" onChange={(e) => {onChange(e)}} />
                    </div>
                    <div className="form-group">
                        <label>{t("Instagram")}</label>
                        <input className="form-control form-control-lg" value={instagram} type="text" name="instagram" placeholder="" onChange={(e) => {onChange(e)}} />
                    </div>
                    <div className="form-group">
                        <label>{t("Tiktok")}</label>
                        <input className="form-control form-control-lg" value={tiktok} type="text" name="tiktok" placeholder="" onChange={(e) => {onChange(e)}} />
                    </div>
                    <div className="form-group">
                        <label>{t("Twitter")}</label>
                        <input className="form-control form-control-lg" value={twitter} type="text" name="twitter" placeholder="" onChange={(e) => {onChange(e)}} />
                    </div>
                    <div className="form-group">
                        <label>{t("Telegram")}</label>
                        <input className="form-control form-control-lg" value={telegram} type="text" name="telegram" placeholder="" onChange={(e) => {onChange(e)}} />
                    </div>
                    <div className="form-group mb-0">
                        <button type="button" className="btn btn-primary" onClick={() => {onClickSaveEditUserSocial()}} >{t("Cập nhật")}</button>
                    </div>
                </li>
            </ul>
        </Fragment>
	);
}

Settings.propTypes = {
    saveEditUserInformation: PropTypes.func.isRequired,
    saveEditUserSocial: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    saveEditUserInformation,
    saveEditUserSocial
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);