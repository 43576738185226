import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { OneItem, TwoItem, ThreeAndMoreItem } from "./";
import { isMobile } from 'react-device-detect';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function ItemContainer(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [imgIndex, setImgIndex] = useState(0);
    var { index, image, video } = props;

    const onClick = (data, index) => {
        setIsOpen(index);
        setImgIndex(data);
    }
    
    var images = [];
    var arr_images = [];
    if (image.length > 0) {
        arr_images = image.split("\n");
        arr_images.map((v, i) => {
            images.push({
                title: "",
                caption: "",
                url: v,
            });
            return false;
        });
    }
    var number_item = arr_images.length;
    if (video !== "") {
        if (isMobile) {
            if (number_item > 0) {
                number_item = 2;
            } else {
                number_item = 1;
            }
        } else {
            number_item = number_item + 1;
        }
    }

    return (
        <Fragment>
            {
                number_item >= 3 ?
                    <ThreeAndMoreItem index={index} arr_images={arr_images} video={video} onClick={onClick}/>
                : number_item === 2 ?
                    <TwoItem index={index} arr_images={arr_images} video={video} onClick={onClick}/>
                : number_item === 1 ?
                    <OneItem index={index} arr_images={arr_images} video={video} onClick={onClick}/>
                : ""
            }
            {isOpen === index && (
                <Lightbox
                    imageTitle={images[imgIndex].title}
                    imageCaption={images[imgIndex].caption}
                    mainSrc={images[imgIndex].url}
                    nextSrc={images[(imgIndex + 1) % images.length].url}
                    prevSrc={images[(imgIndex + images.length - 1) % images.length].url}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setImgIndex((imgIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() => setImgIndex((imgIndex + 1) % images.length)}
                />
            )}
        </Fragment>
    )
}

ItemContainer.propTypes = {
};
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemContainer);