import { connect } from "react-redux";
import Theme from './Theme';

function Maintheme(props) {
	var { theme_using } = props.website;
	const ThemeToRender = Theme[theme_using];

	return (
		<ThemeToRender />
	);
}

const mapStateToProps = state => ({
	website: state.website
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Maintheme);
