import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListService, getListMenu, saveMenu } from "actions/serviceActions";
import { DndProvider } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import {
    Tree,
    MultiBackend,
    getDescendants,
    getBackendOptions
} from "@minoru/react-dnd-treeview";
import { ExternalNode } from "./lib/ExternalNode";
import { CustomNode } from "./lib/CustomNode";
import { CustomDragPreview } from "./lib/CustomDragPreview";
import { Placeholder } from "./lib/Placeholder";
import styles from "./lib/App.module.css";
import sampleDataSocial from "./lib/sample_data_social.json";
import sampleDataOther from "./lib/sample_data_other.json";

function Listmenus(props) {
    const [tree, setTree] = useState([]);
    const [externalNodes, setExternalNodes] = useState([]);
    const [socialNodes, setSocialNodes] = useState([]);
    const [otherNodes, setOtherNodes] = useState([]);
    const [treeDefault, setTreeDefault] = useState([]);
    const [arr_social] = useState(["facebook", "instagram", "youtube", "tiktok", "shopee", "google", "telegram", "twitter", "tripadvisor", "fes", "other", "tools", "extensions"]);
    const [arr_other] = useState(["banks", "logs", "agency"]);
	const { listservices, listmenus } = props.service;
	const { getListService, getListMenu, saveMenu } = props;
    const ref = useRef(null);

	useEffect(() => {
		getListService();
	}, [getListService]);

	useEffect(() => {
		getListMenu();
	}, [getListMenu]);

	useEffect(() => {
        var listservices_tmp = diffBy(
            listservices,
            listmenus,
            (v, u) => v.id === u.id
        )
        var new_listservices = listservices_tmp.map((v, i) => {
            var v_tmp = {
                id: v.id,
                parent: 0,
                droppable: false,
                text: v.name,
                data: v
            };
            return v_tmp;
        });
        setExternalNodes(new_listservices);

        var new_socialNodes = diffBy(
            sampleDataSocial,
            listmenus,
            (v, u) => v.id === u.id
        )
        setSocialNodes(new_socialNodes);

        var new_otherNodes = diffBy(
            sampleDataOther,
            listmenus,
            (v, u) => v.id === u.id
        )
        setOtherNodes(new_otherNodes);
	}, [listservices, listmenus]);

    const diffBy = (a, b, f) => a.filter((v) => !b.some((u) => f(v, u)));

	useEffect(() => {
        setTree(listmenus);
        setTreeDefault(listmenus);

        setTimeout(() => {
            ref.current?.openAll();
        }, 100);
	}, [listmenus]);
  
    const handleDrop = (newTree, options) => {
        const { dropTargetId, monitor } = options;
        const itemType = monitor.getItemType();

        if (itemType === NativeTypes.TEXT) {
            const nodeJson = monitor.getItem().text;
            const node = JSON.parse(nodeJson);

            node.parent = dropTargetId;
            setTree([...newTree, node]);
            setExternalNodes(externalNodes.filter((exnode) => exnode.id !== node.id));
            setSocialNodes(socialNodes.filter((sonode) => sonode.id !== node.id));
            setOtherNodes(otherNodes.filter((otnode) => otnode.id !== node.id));
            return;
        }

        setTree(newTree);
    };

    const handleDelete = (node_delete) => {
        const deleteIds = [
            node_delete.id,
            ...getDescendants(tree, node_delete.id).map((node) => node.id)
        ];

        const newTree = tree.filter((node) => !deleteIds.includes(node.id));

        setTree(newTree);
        
        if (arr_social.includes(node_delete.id)) {
            setSocialNodes([...socialNodes, node_delete]);
            
            var arr_child_delete = tree.filter(v => v.parent === node_delete.id);
            var other_nodes_tmp = [...otherNodes];
            var external_nodes_tmp = [...externalNodes];
            for (var i = 0; i < arr_child_delete.length; i++) {
                if (arr_other.includes(arr_child_delete[i].id)) {
                    other_nodes_tmp.push(arr_child_delete[i]);
                } else {
                    external_nodes_tmp.push(arr_child_delete[i]);
                }
            }
            setOtherNodes(other_nodes_tmp);
            setExternalNodes(external_nodes_tmp);
        } else if (arr_other.includes(node_delete.id)) {
            setOtherNodes([...otherNodes, node_delete]);
        } else {
            setExternalNodes([...externalNodes, node_delete]);
        }
    };

	const onClickSaveMenu = () => {
		saveMenu({tree});
	};

    const onClick = () => {
        setTree(treeDefault);
		getListService();
		getListMenu();
    }
  
    return (
        <Fragment>
            <div className={"form-group row"}>
                <div className="col-sm-12 col-md-4 ">
                    <div className="card card-box bg-light mb-20">
                        <div className="card-header">{t("Mạng xã hội")}</div>
                        <div className="card-body al-card-body al-customscroll">
                            {socialNodes.map((node) => (
                                <ExternalNode key={node.id} node={node} />
                            ))}
                        </div>
                    </div>
                    <div className="card card-box bg-light mb-20">
                        <div className="card-header">{t("Dịch vụ")}</div>
                        <div className="card-body al-card-body al-customscroll">
                            {externalNodes.map((node) => (
                                <ExternalNode key={node.id} node={node} />
                            ))}
                        </div>
                    </div>
                    <div className="card card-box bg-light">
                        <div className="card-header">{t("Khác")}</div>
                        <div className="card-body al-card-body al-customscroll">
                            {otherNodes.map((node) => (
                                <ExternalNode key={node.id} node={node} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-8">
                    <div className="card card-box bg-light">
                        <div className="card-header">{t("Cài đặt Menu")}</div>
                        <div className="card-body al-card-body-2 al-customscroll">
                            <DndProvider backend={MultiBackend} options={getBackendOptions()} debugMode={true} >
                                <Tree
                                    rootId={0}
                                    ref={ref}
                                    tree={tree}
                                    extraAcceptTypes={[NativeTypes.TEXT]}
                                    classes={{
                                        root: styles.treeRoot,
                                        draggingSource: styles.draggingSource,
                                        dropTarget: styles.dropTarget,
                                        placeholder: styles.placeholderContainer,
                                    }}
                                    render={(node, options) => <CustomNode node={node} {...options} onDelete={handleDelete} />}
                                    dragPreviewRender={(monitorProps) => (
                                        <CustomDragPreview monitorProps={monitorProps} />
                                    )}
                                    sort={false}
                                    insertDroppableFirst={false}
                                    canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                                        var dragSourceText = {};
                                        if (dragSource !== null) {
                                            if (dragSource.dataTransfer && dragSource.text) {
                                                dragSourceText = JSON.parse(dragSource.text);
                                            }
                                        }
                                        if (arr_social.includes(dragSourceText.id) && dropTarget?.parent === 0) {
                                            return false;
                                        }
                                        if ((dragSource?.parent === 0 && dragSource?.droppable) && (dropTarget?.parent === 0 && dropTarget?.droppable)) {
                                            return false;
                                        }
                                        if (dragSource?.parent === dropTargetId) {
                                            return true;
                                        }
                                    }}
                                    onDrop={handleDrop}
                                    placeholderRender={(node, { depth }) => (
                                        <Placeholder node={node} depth={depth} />
                                    )}
                                    initialOpen={true}
                                />
                            </DndProvider>
                        </div>
                        <div className="card-footer">
                            <button type="button" className="btn btn-danger" onClick={() => {onClick()}} >{t("Đặt lại")}</button>
                            <button type="button" className="btn btn-primary float-right" onClick={() => {onClickSaveMenu()}} >{t("Lưu")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Listmenus.propTypes = {
	getListService: PropTypes.func.isRequired,
	getListMenu: PropTypes.func.isRequired,
	saveMenu: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	service: state.service
});
const mapDispatchToProps = {
    getListService,
    getListMenu,
    saveMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(Listmenus);