import { t } from "i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";

const getListLog = (page = 0, sizeperpage = 0, search = "", list_order_view = "") => (dispatch, getState) => {
	dispatch({type: 'LOADING_LOG', payload: true});
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	var param_list_order_view = "";
	if (list_order_view !== "") {
		param_list_order_view = `&view=${list_order_view}`;
	}
	axios.get(`/api/log/list?page=${page}&sizeperpage=${sizeperpage}${param_list_order_view}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_LOG', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_LOG',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_LOG', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

export {
	getListLog
}
