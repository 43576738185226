const initialState = {
	isLoading: false,
    all_config: []
};

function configReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_CONFIG':
			return {
				...state,
				isLoading: action.payload
			};
        case 'ALL_CONFIG':
            return {
                ...state,
                all_config: action.payload.configs
            };
		default:
			return state;
	}
}

export default configReducer;