const initialState = {
	isLoading: false,
	listnameservers: []
};

function agencyReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_AGENCY':
			return {
				...state,
				isLoading: action.payload
			};
		case 'AGENCY_NAMESERVERS':
			return {
				...state,
				listnameservers: action.payload.nameservers
			};
		default:
			return state;
	}
}

export default agencyReducer;