import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import Agencyconnect from "./agencyconnect/Agencyconnect";
import Loading from "components/theme_default/Loading";

function Main(props) {
	const [tab, setTab] = useState("agency");
	var { isLoading } = props.agency;

	const onClick = (data) => {
		setTab(data);
	}

	return (
        <Fragment>
            <div className="pd-ltr-20 bg-white border-radius-4 box-shadow mb-30">
                <div className="tab">
                    <ul className="nav nav-pills" role="tablist">
                        <li className="nav-item col-lg-12">
						    <button className={"nav-link btn btn-sm al-btn-link btn-outline-primary text-blue text-center " + (tab === "agency"? " active" : "")} to="#" onClick={(e) => {onClick("agency")}}>{t("Tích hợp đại lý")}</button>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <Loading isLoading={isLoading}/>
                        <div className="tab-pane fade active show" id="home5" role="tabpanel">
                            <div className="pd-20">
                                <Agencyconnect />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
	);
}

const mapStateToProps = state => ({
	agency: state.agency
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);