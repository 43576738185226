import { t } from "i18next";
import axios from "axios";
import Swal from "sweetalert2";

const tokenConfig = getState => {
	const token = getState().user.token;
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	if (token) {
		config.headers['al-token'] = token;
	}
	
	return config;
};

const setIsOpenSidebar = () => (dispatch, getState) => {
	dispatch({
		type: 'SET_IS_OPEN_SIDEBAR'
	});
};

const getDataFromApi = (dispatch, getState, type) => {
	var reduce_url = "";
	var reduce_type = "";
	if (type === "listallserver") {
		reduce_url = "/api/service/list-server-of-service";
		reduce_type = "LIST_ALL_SERVER";
	}
	if (type === "listallconfig") {
		reduce_url = "/api/config/get-all";
		reduce_type = "LIST_ALL_CONFIG";
	}
	if (type === "listmenu") {
		reduce_url = "/api/service/list-menu";
		reduce_type = "LIST_MENU";
	}

	if (reduce_url !== "" && reduce_type !== "") {
		axios.get(reduce_url, tokenConfig(getState)).then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: reduce_type,
					payload: res.data.data
				});
			}
		}).catch(err => {
		});
	}
};

// const getDataFromCache = (dispatch, getState, type) => {
// 	var reduce_type = "";
// 	if (type === "listallserver") {
// 		reduce_type = "LIST_ALL_SERVER_FROM_CACHE";
// 	}
// 	if (type === "listallconfig") {
// 		reduce_type = "LIST_ALL_CONFIG_FROM_CACHE";
// 	}
// 	if (type === "listmenu") {
// 		reduce_type = "LIST_MENU_FROM_CACHE";
// 	}

// 	if (reduce_type !== "") {
// 		dispatch({
// 			type: reduce_type,
// 			payload: JSON.parse(localStorage.getItem(type))
// 		});
// 	}
// };

const getDataFromLocalStorage = (type) => (dispatch, getState) => {
	try {
		if (localStorage.getItem(type) === null) {
			getDataFromApi(dispatch, getState, type);
		} else {
			// var data = JSON.parse(localStorage.getItem(type));
			// var checktime = {checktime:0};
			// if (Array.isArray(data)) {
			// 	checktime = data[data.length - 1];
			// } else {
			// 	checktime = data;
			// }
			// if (typeof checktime.checktime === 'undefined' || checktime.checktime === 0 || (Math.floor(Date.now() / 1000) - checktime.checktime > 300)) {
				getDataFromApi(dispatch, getState, type);
			// } else {
			// 	getDataFromCache(dispatch, getState, type);
			// }
		}
	} catch(error) {
		// console.log(error);
		getDataFromApi(dispatch, getState, type);
	}
};

const uploadImage = (file) => (dispatch, getState) => {
	const form = new FormData();
	form.append("anh", file);

	var options = {
		method: "POST",
		url: "/upload/image",
		headers: {
			"Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
			"al-token": getState().user.token
			
		},
		data: form
	};

	return axios.request(options).then(function (res) {
		return res.data.data.filename||"";
	}).catch(function (error) {
	});
};

const convertObjectIdFromUrl = (server_selected, url) => (dispatch, getState) => {
	var body = {
		server_selected,
		url
	}
	dispatch({type: 'LOADING_ORDER', payload: true});
	return axios.post("/api/order/convert-url", body, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_ORDER', payload: false});
		if (res.data.status === 200) {
			Swal.fire(t("Thành công"), t(res.data.message), 'success');
			return res.data.data.id;
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
			return "";
		}
	}).catch(err => {
		dispatch({type: 'LOADING_ORDER', payload: false});
		return "";
	});
};

export {
	tokenConfig,
	setIsOpenSidebar,
	getDataFromLocalStorage,
	uploadImage,
	convertObjectIdFromUrl
}
