import { connect } from "react-redux";
import { t } from "i18next";

function Loading(props) {
	const { isLoading } = props;

	return (
		<div className="loading" style={{"display": isLoading ? "block" : "none"}}>
			<div className="spinner-border text-primary" role="status">
				<span className="sr-only">{t("Loading...")}</span>
			</div>
			<div className="loading-bg">
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
