const initialState = {
	isLoading: false,
	listnotices: [],
	totalsize: 0
};

function noticeReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_NOTICE':
			return {
				...state,
				isLoading: action.payload
			};
		case 'ADMIN_LIST_NOTICE':
			return {
				...state,
				listnotices: action.payload.notices,
				totalsize: action.payload.totalsize
			};
		default:
			return state;
	}
}

export default noticeReducer;