import { combineReducers } from "redux";
import userReducer from "./userReducer";
import websiteReducer from "./websiteReducer";
import serviceReducer from "./serviceReducer";
import bankReducer from "./bankReducer";
import noticeReducer from "./noticeReducer";
import orderReducer from "./orderReducer";
import logReducer from "./logReducer";
import configReducer from "./configReducer";
import agencyReducer from "./agencyReducer";

export default combineReducers({
	user: userReducer,
	website: websiteReducer,
	service: serviceReducer,
	bank: bankReducer,
	notice: noticeReducer,
	order: orderReducer,
	log: logReducer,
	config: configReducer,
	agency: agencyReducer
});
