import { t } from "i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";

const getConfig = () => (dispatch, getState) => {
	dispatch({type: 'LOADING_CONFIG', payload: true});
	axios.get('/api/config/get-all', tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_CONFIG', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'ALL_CONFIG',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_CONFIG', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

const saveConfig = (data) => (dispatch, getState) => {
	var body = data;
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn lưu cấu hình này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_CONFIG', payload: true});
			axios.post('/api/config/save', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_CONFIG', payload: false});
				if (res.data.status === 200) {
					dispatch(getConfig());
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_CONFIG', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

export {
	getConfig,
	saveConfig
}
