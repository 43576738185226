import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { t } from "i18next";
import { AdminUsers, AdminServices, AdminBanks, AdminNotices, AdminConfigs } from "./layout/admin";
import { Banks, Home, Profile, Orders, Logs, Agency } from "./layout/user";
import Notfoundpage from "./Notfoundpage";
import PropTypes from "prop-types";
import { getDataFromLocalStorage } from "actions/websiteActions";

function Body(props) {
    const { role } = props.user.user;
	const { getDataFromLocalStorage } = props;

	useEffect(() => {
        getDataFromLocalStorage("listallserver");
        getDataFromLocalStorage("listallconfig");
        getDataFromLocalStorage("listmenu");
	}, [getDataFromLocalStorage]);

    const renderPaths = (paths, Element) =>
        paths.map((path) => <Route key={path} path={path} element={Element} />);

	return (
        <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
                <div className="min-height-200px">
                    <Routes>
                        {
                            role === 0 || role === 1 ?
                                <Fragment>
                                    <Route path="/admin/users" element={<AdminUsers />} />
                                    <Route path="/admin/services" element={<AdminServices />} />
                                    <Route path="/admin/banks" element={<AdminBanks />} />
                                    <Route path="/admin/notices" element={<AdminNotices />} />
                                    <Route path="/admin/configs" element={<AdminConfigs />} />
                                </Fragment>
                            : ""
                        }
                        <Route path="/" element={<Home />} />
                        <Route path="/banks" element={<Banks />} />
                        <Route path="/logs" element={<Logs />} />
                        <Route path="/agency" element={<Agency />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route exact path="/redirect-login-social"  element={t("Đang chuyển hướng...")} />
                        {renderPaths(["/facebook/*", "/instagram/*", "/youtube/*", "/tiktok/*", "/shopee/*", "/google/*", "/telegram/*", "/twitter/*", "/tripadvisor/*", "/fes/*"], <Orders />)}
                        {renderPaths(["/*", "/facebook", "/instagram/*", "/youtube/*", "/tiktok/*", "/shopee/*", "/google/*", "/telegram/*", "/twitter/*", "/tripadvisor/*", "/fes/*"], <Notfoundpage />)}
                    </Routes>
                </div>
            </div>
        </div>
	);
}

Body.propTypes = {
	getDataFromLocalStorage: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	user: state.user
});
const mapDispatchToProps = {
    getDataFromLocalStorage
}

export default connect(mapStateToProps, mapDispatchToProps)(Body);
