import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { saveAddBank, getListBankFromApi } from "actions/bankActions";
import Loading from "components/theme_default/Loading";
// import { DataListCardBank } from "components/theme_default/Function";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaladdbank(props) {
    const [card_numbers, setCardNumbers] = useState("");
    const [card_username, setCardUsername] = useState("");
    const [card_bank, setCardBank] = useState("");
    const [card_bankname, setCardBankName] = useState("");
    const [card_binbank, setCardBinBank] = useState("");
    const [card_branch, setCardBranch] = useState("");
    const [tranfer_content, setTranferContent] = useState("");
    const [description, setDescription] = useState("");
    var { isOpenModalAddBank, openModal, saveAddBank, getListBankFromApi } = props;
    var { isLoading, listbanksapi } = props.bank;

	useEffect(() => {
		getListBankFromApi();
	}, [getListBankFromApi]);

	const onClickSaveAddBank = () => {
		saveAddBank({ card_numbers, card_username, card_bank, card_bankname, card_binbank, card_branch, tranfer_content, description }, openModal);
	};

    const onChange = (e) => {
        if (e.target.name === "card_numbers") {
            setCardNumbers(e.target.value);
        }
        if (e.target.name === "card_username") {
            setCardUsername(e.target.value);
        }
        if (e.target.name === "card_bank") {
            var value = e.target.value.split(" - ");
            setCardBank(value[0]);
            setCardBankName(value[1]);
            setCardBinBank(value[2]);
        }
        if (e.target.name === "card_branch") {
            setCardBranch(e.target.value);
        }
        if (e.target.name === "tranfer_content") {
            setTranferContent(e.target.value);
        }
        if (e.target.name === "description") {
            setDescription(e.target.value);
        }
    }

	const onOpenModal = () => {
		openModal();
	};

    const optionBanksApi = () => {
        return listbanksapi.map((v, i) => {
            return (
                <option value={v.code+" - "+v.shortName+" - "+v.bin} data-value={v.shortName} data-bin={v.bin} key={i}>{v.name} - {v.shortName}</option>
            );
        });
    }

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => {onOpenModal()}} isOpen={isOpenModalAddBank}>
                <Loading isLoading={isLoading}/>
                <div className="modal-header">
                    <p className="mb-0 text-dark">{t("Thêm ngân hàng")}</p>
                    <button onClick={() => {onOpenModal()}} type="button" className="close">×</button>
                </div>
                <ModalBody>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label">{t("Số tài khoản")}</label>
                        <div className="col-sm-12 col-md-8">
                            <input className="form-control" value={card_numbers} type="text" name="card_numbers" placeholder="" onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label">{t("Tên tài khoản")}</label>
                        <div className="col-sm-12 col-md-8">
                            <input className="form-control" value={card_username} type="text" name="card_username" placeholder="" onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label">{t("Ngân hàng")}</label>
                        <div className="col-sm-12 col-md-8">
                            {/* <input className="form-control" value={card_bank} type="text" name="card_bank" placeholder="" onChange={(e) => {onChange(e)}} list="listCardBank" />
                            <DataListCardBank list="listCardBank" /> */}
                            <input className="form-control" value={card_bank} type="text" name="card_bank" placeholder="" onChange={(e) => {onChange(e)}} list="listCardBank" />
                            <datalist id="listCardBank">
                                {optionBanksApi()}
                            </datalist>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label">{t("Chi nhánh")}</label>
                        <div className="col-sm-12 col-md-8">
                            <input className="form-control" value={card_branch} type="text" name="card_branch" placeholder="" onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label">{t("Nội dung chuyển khoản")}</label>
                        <div className="col-sm-12 col-md-8">
                            <input className="form-control" value={tranfer_content} type="text" name="tranfer_content" placeholder={t("abc {username}")} onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label">{t("Ghi chú")}</label>
                        <div className="col-sm-12 col-md-8">
                            <input className="form-control" value={description} type="text" name="description" placeholder="" onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveAddBank()}} >{t("Lưu")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => {onOpenModal()}} >{t("Huỷ")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaladdbank.propTypes = {
	saveAddBank: PropTypes.func.isRequired,
	getListBankFromApi: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	bank: state.bank
});
const mapDispatchToProps = {
	saveAddBank,
	getListBankFromApi
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaladdbank);