import { useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import { Link } from "react-router-dom";
import Listservices from "./listservices/Listservices";
import Listservers from "./listservers/Listservers";
import Listmenus from "./listmenus/Listmenus";
import Loading from "components/theme_default/Loading";

function Main(props) {
	const [tab, setTab] = useState("listmenus");
    const { role } = props.user.user;

	const onClick = (data) => {
		setTab(data);
	}

	var loading = false;
	if (tab === "listmenus") {
		loading = props.service.isLoadingMenu;
	} else if (tab === "listservers") {
		loading = props.service.isLoadingServer;
	} else {
		loading = props.service.isLoading;
	}

	return (
		<div className="pd-20 bg-white border-radius-4 box-shadow mb-30">
			<div className="tab">
				<ul className="nav nav-tabs" role="tablist">
					<li className="nav-item">
						<Link className={"nav-link text-blue " + (tab === "listmenus"? " active" : "")} to="#" onClick={(e) => {onClick("listmenus")}}>{t("Menu")}</Link>
					</li>
					{
						role === 0 ?
							<li className="nav-item">
								<Link className={"nav-link text-blue " + (tab === "listservices"? " active" : "")} to="#" onClick={(e) => {onClick("listservices")}}>{t("Dịch vụ")}</Link>
							</li>
						 : ""
					}
					<li className="nav-item">
						<Link className={"nav-link text-blue " + (tab === "listservers"? " active" : "")} to="#" onClick={(e) => {onClick("listservers")}}>{t("Server")}</Link>
					</li>
				</ul>
				<div className="tab-content">
					<Loading isLoading={loading}/>
					<div className="tab-pane fade active show">
						<div className="pd-20">
							{
								tab === "listservices" && role === 0 ?
									<Listservices />
								: tab === "listservers" ?
									<Listservers />
								: <Listmenus />
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	user: state.user,
	service: state.service
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);