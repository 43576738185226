import { t } from "i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig, uploadImage } from "./websiteActions";
import { loadUser } from "./userActions";
import { convertTextSocialToId, filterValueMoney } from "components/theme_default/Function";

const getListOrder = (page = 0, sizeperpage = 0, search = "", list_servers_id = [], list_order_view = "") => (dispatch, getState) => {
	dispatch({type: 'LOADING_ORDER', payload: true});
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	var param_list_servers_id = "";
	if (list_servers_id.length > 0) {
		param_list_servers_id = `&servers_id=${list_servers_id.join(",")}`;
	}
	var param_list_order_view = "";
	if (list_order_view !== "") {
		param_list_order_view = `&view=${list_order_view}`;
	}
	axios.get(`/api/order/list?page=${page}&sizeperpage=${sizeperpage}${param_list_order_view}${param_list_servers_id}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_ORDER', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_ORDER',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_ORDER', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

const createOrder = (data, server_selected) => (dispatch, getState) => {
	var body = data;
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn mua đơn hàng này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then( async (result) => {
		if (result.value) {
			if (server_selected.keyword === 1) {
				var file_keyword_image = Array.from(data.keyword_image)[0];
				var filename_keyword_image = await uploadImage(file_keyword_image)(dispatch, getState);
	
				body = {
					...body,
					keyword_image: filename_keyword_image
				}
			}
			if (server_selected.content_comment_image === 1) {
				var content_comment_tmp = []
				for (var i = 0; i < data.content_comment.length; i++) {
					var file_content_comment = Array.from(data.content_comment[i]["content_comment_image-1"])[0];
					var filename_content_comment = await uploadImage(file_content_comment)(dispatch, getState);
					content_comment_tmp.push({
						"content_comment_image-0": data.content_comment[i]["content_comment_image-0"],
						"content_comment_image-1": filename_content_comment
					})
				}
				
				body = {
					...body,
					content_comment: content_comment_tmp
				}
			}

			dispatch({type: 'LOADING_ORDER', payload: true});
			axios.post('/api/order/create', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				if (res.data.status === 200) {
					dispatch(loadUser());
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message, {"quantity_min": server_selected.quantity_min, "quantity_max": server_selected.quantity_max, "multiples": server_selected.multiples}), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const getListServersOfServices = (data) => (dispatch, getState) => {
    var listserverofservice = [];
    var id_social = "";
    var text_social = "";
    var path_order = window.location.pathname;
    var matches = path_order.match(/^\/(.*?)\//);
    if (matches != null) {
        if (typeof matches[1] != 'undefined') {
            id_social = convertTextSocialToId(matches[1])();
            text_social = matches[1];
        }
    }
    var regex = new RegExp( "/"+ text_social + "/", 'g' );
    var url = path_order.replace(regex, "");
	if (data.length > 0) {
		listserverofservice = data.filter((v, i) => {
			if (v.social === id_social && v.url === url) {
				return v;
			}
			return "";
		})
	}
	dispatch({
		type: 'ORDER_LIST_SERVER_OF_SERVICE',
		payload: listserverofservice
	});
};

const clearListOrders = () => (dispatch, getState) => {
	dispatch({
		type: 'CLEAR_LIST_ORDER',
		payload: []
	});
};

const refundOrder = (id, formatExtraData, refund_fee) => (dispatch, getState) => {
	var { page, sizePerPage, searchText, list_servers_id, list_order_view } = formatExtraData;
	var body = {
		id
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5000 vnđ phí để tránh Spam!", {"5000 vnđ": filterValueMoney({money: refund_fee})()}),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_ORDER', payload: true});
			axios.post('/api/order/refund', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				if (res.data.status === 200) {
					dispatch(getListOrder(page, sizePerPage, searchText, list_servers_id, list_order_view));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const extendOrder = (id, formatExtraData) => (dispatch, getState) => {
	var { page, sizePerPage, searchText, list_servers_id, list_order_view } = formatExtraData;
	var body = {
		id
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn gia hạn đơn hàng này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_ORDER', payload: true});
			axios.post('/api/order/extend', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				if (res.data.status === 200) {
					dispatch(loadUser());
					dispatch(getListOrder(page, sizePerPage, searchText, list_servers_id, list_order_view));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

export {
	getListOrder,
	createOrder,
	getListServersOfServices,
	clearListOrders,
	refundOrder,
	extendOrder
}
