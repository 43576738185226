import {  Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { registerUser } from "actions/userActions";

function Register(props) {
    const [username, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [repeatpassword, setRepeatPassword] = useState("");
    const { handleTab, registerUser } = props;

    const handleRegister = () => {
        registerUser(username, email, phonenumber, password, repeatpassword);
    }

    const onChange = (e) => {
        if (e.target.name === "username") {
            setUserName(e.target.value);
        }
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
        if (e.target.name === "phonenumber") {
            setPhoneNumber(e.target.value);
        }
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
        if (e.target.name === "repeatpassword") {
            setRepeatPassword(e.target.value);
        }
    }

	const onKeyPress = (e) => {
		if (e.charCode === 13) {
            registerUser(username, email, phonenumber, password, repeatpassword);
		}
        if (e.target.name === "phonenumber") {
            if (!(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault();
            }
        }
	}

	const google = () => {
		window.open("/auth/google", "_self");
        // var newwindow = window.open("/auth/google", "", "height=500, width=500");
        // if (window.focus) {
        //     newwindow.focus();
        // }
        // return false;
	}

	const facebook = () => {
		window.open("/auth/facebook", "_self");
	}

	return (
        <Fragment>
            <div className="login-title">
                <h2 className="text-center text-primary">{t("Đăng ký tài khoản")}</h2>
            </div>
            <div className="input-group custom">
                <input type="text" className="form-control form-control-lg" placeholder={t("Tên tài khoản")} name="username" value={username} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                <div className="input-group-append custom">
                    <span className="input-group-text"><i className="dw dw-user1"></i></span>
                </div>
            </div>
            <div className="input-group custom">
                <input type="email" className="form-control form-control-lg" placeholder={t("Email")} name="email" value={email} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                <div className="input-group-append custom">
                    <span className="input-group-text"><i className="dw dw-email1"></i></span>
                </div>
            </div>
            <div className="input-group custom">
                <input type="text" className="form-control form-control-lg" placeholder={t("Số điện thoại")} name="phonenumber" value={phonenumber} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                <div className="input-group-append custom">
                    <span className="input-group-text"><i className="dw dw-phone-call"></i></span>
                </div>
            </div>
            <div className="input-group custom">
                <input type="password" className="form-control form-control-lg" placeholder={t("Mật khẩu")} name="password" value={password} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                <div className="input-group-append custom">
                    <span className="input-group-text"><i className="dw dw-padlock1"></i></span>
                </div>
            </div>
            <div className="input-group custom">
                <input type="password" className="form-control form-control-lg" placeholder={t("Nhập lại mật khẩu")} name="repeatpassword" value={repeatpassword} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                <div className="input-group-append custom">
                    <span className="input-group-text"><i className="dw dw-padlock1"></i></span>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="input-group mb-3">
                        <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={() => {handleRegister()}}>{t("Đăng ký")}</button>
                    </div>
                    <div className="input-group mb-3">
                        <Link to="#" className="btn btn-lg btn-block al-button-google" onClick={() => {google()}}>{t("Đăng ký với Google")}</Link>
                    </div>
                    <div className="input-group mb-0">
                        <Link to="#" className="btn btn-lg btn-block al-button-facebook" onClick={() => {facebook()}}>{t("Đăng ký với Facebook")}</Link>
                    </div>
                    <div className="font-16 weight-600 pt-10 pb-10 text-center" data-color="#707373" style={{"color": "rgb(112, 115, 115)"}}>
                        {t("HOẶC")}
                    </div>
                    <div className="input-group mb-0">
                        <Link to="#" className="btn btn-outline-primary btn-lg btn-block" onClick={() => {handleTab("login")}}>{t("Đã có tài khoản")}</Link>
                    </div>
                </div>
            </div>
        </Fragment>
	);
}

Register.propTypes = {
	registerUser: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    registerUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
