const initialState = {
	isLoading: false,
	listlogs: [],
	totalsize: 0
};

function logReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_LOG':
			return {
				...state,
				isLoading: action.payload
			};
		case 'LIST_LOG':
			return {
				...state,
				listlogs: action.payload.logs,
				totalsize: action.payload.totalsize
			};
		default:
			return state;
	}
}

export default logReducer;