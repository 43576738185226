import { t } from "i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";

const getListNotice = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
	dispatch({type: 'LOADING_NOTICE', payload: true});
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	axios.get(`/api/notice/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_NOTICE', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_NOTICE',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_NOTICE', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

const saveAddNotice = (data, closemodal) => (dispatch, getState) => {
	var body = {
		title: data.title,
		content: data.content,
		video: data.video,
		image: data.image
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn thêm thông báo này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_NOTICE', payload: true});
			axios.post('/api/notice/add', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_NOTICE', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListNotice(1, 10));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_NOTICE', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveEditNotice = (data, closemodal) => (dispatch, getState) => {
	var body = {
		id: data.id,
		title: data.title,
		content: data.content,
		video: data.video,
		image: data.image
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn sửa thông báo này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_NOTICE', payload: true});
			axios.post('/api/notice/edit', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_NOTICE', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListNotice(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_NOTICE', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const deleteNotice = (data) => (dispatch, getState) => {
	var body = {
		id: data.id
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn xóa thông báo này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_NOTICE', payload: true});
			axios.post('/api/notice/delete', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_NOTICE', payload: false});
				if (res.data.status === 200) {
					dispatch(getListNotice(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_NOTICE', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

export {
	getListNotice,
	saveAddNotice,
	saveEditNotice,
	deleteNotice
}
