const initialState = {
	isLoading: false,
	listserverofservice: [],
	listorders: [],
	totalsize: 0
};

function orderReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_ORDER':
			return {
				...state,
				isLoading: action.payload
			};
		case 'ORDER_LIST_SERVER_OF_SERVICE':
			return {
				...state,
				listserverofservice: action.payload
			};
		case 'LIST_ORDER':
			return {
				...state,
				listorders: action.payload.orders,
				totalsize: action.payload.totalsize
			};
		case 'CLEAR_LIST_ORDER':
			return {
				...state,
				listorders: []
			};
		default:
			return state;
	}
}

export default orderReducer;