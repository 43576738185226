import styles from "./CustomDragPreview.module.css";
import { IconServices } from "components/theme_default/Function";

export const CustomDragPreview = (props) => {
    const item = props.monitorProps.item;

    return (
        <div className={styles.root}>
            <div className={styles.icon}>
                <IconServices id={item.id} type="menu" />
            </div>
            <div className={styles.label}>{item.text}</div>
        </div>
    );
};
