import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListRole } from "actions/userActions";
import { saveSetPricesAutoUserRoles } from "actions/serviceActions";
import Loading from "components/theme_default/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modalsetpricesautouserroles(props) {
    var [to_user_roles, setToUserRoles] = useState("");
    var [price, setPrice] = useState("");
    var [type, setType] = useState("0");
    var [from_user_roles, setFromUserRoles] = useState("");
    var { isOpenModalSetPricesAutoUserRoles, openModal, getListRole, saveSetPricesAutoUserRoles } = props;
    var { isLoadingServer } = props.service;
    var { listuserroles } = props.user;

	useEffect(() => {
		getListRole();
	}, [getListRole]);

	const onClickSaveSetPricesAutoUserRoles = () => {
		saveSetPricesAutoUserRoles({ to_user_roles, price, type, from_user_roles }, openModal);
	};

	const onOpenModal = () => {
		openModal();
	};

	const onChange = (e) => {
        if (e.target.name === "to_user_roles") {
            setToUserRoles(e.target.value);
        }
        if (e.target.name === "price") {
            setPrice(e.target.value);
        }
        if (e.target.name === "type") {
            setType(e.target.value);
        }
        if (e.target.name === "from_user_roles") {
            setFromUserRoles(e.target.value);
        }
	};
	
	const onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    const optionUserRoles = () => {
        return listuserroles.map((v, i) => {
            return (
                <option value={v.id} key={i}>{v.name}</option>
            );
        });
    }

    const userRolesSetPrices = () => {
        return (
            <div className="form-group row">
                <div className="col-sm-12 col-md-3">
                    <select className="form-control" value={from_user_roles} name="from_user_roles" onChange={(e) => {onChange(e)}}>
                        <option value="">{t("---Chọn loại tài khoản---")}</option>
                        {optionUserRoles()}
                    </select>
                </div>
                <div className="col-sm-12 col-md-1"><label className="col-sm-12 col-form-label">+</label></div>
                <div className="col-sm-12 col-md-3 input-group bootstrap-touchspin al-input-group">
                    <input className="form-control" value={price} type="text" name="price" placeholder="" onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                    <span className="input-group-addon bootstrap-touchspin-postfix input-group-append al-postfix">
                        <select className="btn btn-light waves-effect bg-light" value={type} name="type" onChange={(e) => {onChange(e)}}>
                            <option value="0">%</option>
                            <option value="1">VNĐ</option>
                        </select>
                    </span>
                </div>
                <div className="col-sm-12 col-md-1"><label className="col-sm-12 col-form-label">=</label></div>
                <div className="col-sm-12 col-md-3">
                    <select className="form-control" value={to_user_roles} name="to_user_roles" onChange={(e) => {onChange(e)}}>
                        <option value="">{t("---Chọn loại tài khoản---")}</option>
                        {optionUserRoles()}
                    </select>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={() => {onOpenModal()}} isOpen={isOpenModalSetPricesAutoUserRoles}>
                <Loading isLoading={isLoadingServer}/>
                <div className="modal-header">
                    <p className="mb-0 text-dark">{t("Cài đặt giá tự động loại tài khoản")}</p>
                    <button onClick={() => {onOpenModal()}} type="button" className="close">×</button>
                </div>
                <ModalBody>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Giá")}</label>
                        <div className="col-sm-12 col-md-9 al-user-roles-prices">
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-3 col-form-label">{t("Loại tài khoản")}</label>
                                <label className="col-sm-12 col-md-1 col-form-label"></label>
                                <label className="col-sm-12 col-md-3 col-form-label">{t("Tăng giá thêm")}</label>
                                <label className="col-sm-12 col-md-1 col-form-label"></label>
                                <label className="col-sm-12 col-md-3 col-form-label">{t("Loại tài khoản")}</label>
                            </div>
                            {userRolesSetPrices()}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveSetPricesAutoUserRoles()}} >{t("Lưu")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => {onOpenModal()}} >{t("Huỷ")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modalsetpricesautouserroles.propTypes = {
	getListRole: PropTypes.func.isRequired,
	saveSetPricesAutoUserRoles: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	user: state.user,
	service: state.service
});
const mapDispatchToProps = {
	getListRole,
	saveSetPricesAutoUserRoles
}

export default connect(mapStateToProps, mapDispatchToProps)(Modalsetpricesautouserroles);