import { t } from "i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";

const getListService = (page = 0, sizeperpage = 0, search = "", filter = "", filter2 = "") => (dispatch, getState) => {
	dispatch({type: 'LOADING_SERVICE', payload: true});
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	var param_filter = "";
	if (filter !== "") {
		param_filter = `&filter=${filter}`;
	}
	var param_filter2 = "";
	if (filter2 !== "") {
		param_filter2 = `&filter2=${filter2}`;
	}
	axios.get(`/api/service/list?page=${page}&sizeperpage=${sizeperpage}${param_search}${param_filter}${param_filter2}`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_SERVICE', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_SERVICE',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_SERVICE', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

const saveAddService = (data, closemodal) => (dispatch, getState) => {
	var body = {
		name: data.name,
		url: data.url,
		social: data.social,
		service_type: data.service_type
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn thêm dịch vụ này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_SERVICE', payload: true});
			axios.post('/api/service/add', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListService(1, 10));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveEditService = (data, closemodal) => (dispatch, getState) => {
	var body = {
		id: data.id,
		name: data.name,
		url: data.url,
		social: data.social,
		service_type: data.service_type
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn sửa dịch vụ này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_SERVICE', payload: true});
			axios.post('/api/service/edit', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListService(data.page, data.sizePerPage, data.searchText, data.filterStatus, data.filterStatus2));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const deleteService = (data) => (dispatch, getState) => {
	var body = {
		id: data.id
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn xóa dịch vụ này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_SERVICE', payload: true});
			axios.post('/api/service/delete', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE', payload: false});
				if (res.data.status === 200) {
					dispatch(getListService(data.page, data.sizePerPage, data.searchText, data.filterStatus, data.filterStatus2));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const getListMenu = () => (dispatch, getState) => {
	dispatch({type: 'LOADING_SERVICE_MENU', payload: true});
	axios.get('/api/service/list-menu', tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_SERVICE_MENU', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_SERVICE_MENU',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_SERVICE_MENU', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

const saveMenu = (data) => (dispatch, getState) => {
	var body = {
		tree: data.tree,
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn lưu menu này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_SERVICE_MENU', payload: true});
			axios.post('/api/service/save-menu', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE_MENU', payload: false});
				if (res.data.status === 200) {
					dispatch(getListMenu(1, 10));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE_MENU', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const getListServer = (page = 0, sizeperpage = 0, search = "", filter = "") => (dispatch, getState) => {
	dispatch({type: 'LOADING_SERVICE_SERVER', payload: true});
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	var param_filter = "";
	if (filter !== "") {
		param_filter = `&filter=${filter}`;
	}
	axios.get(`/api/service/list-server?page=${page}&sizeperpage=${sizeperpage}${param_search}${param_filter}`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_SERVICE_SERVER',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

const saveAddServer = (data, closemodal) => (dispatch, getState) => {
	var body = {
		services_id: data.services_id,
		provider: data.provider,
		provider_type: data.provider_type,
		provider_price: data.provider_price,
		server_name: data.server_name,
		description: data.description,
		object_id: data.object_id,
		quantity: data.quantity,
		quantity_min: data.quantity_min,
		quantity_max: data.quantity_max,
		multiples: data.multiples,
		emoji_reaction: data.emoji_reaction,
		content_comment: data.content_comment,
		content_comment_image: data.content_comment_image,
		keyword: data.keyword,
		refundable: data.refundable,
		time_buff: data.time_buff,
		maintenance: data.maintenance,
		prices: data.prices
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn thêm server này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_SERVICE_SERVER', payload: true});
			axios.post('/api/service/add-server', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListServer(1, 10));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveEditServer = (data, closemodal) => (dispatch, getState) => {
	var body = {
		id: data.id,
		services_id: data.services_id,
		prices: data.prices
	};
	if (data.role === 0) {
		body = {
			...body,
			provider: data.provider,
			provider_type: data.provider_type,
			provider_price: data.provider_price,
			server_name: data.server_name,
			description: data.description,
			object_id: data.object_id,
			quantity: data.quantity,
			quantity_min: data.quantity_min,
			quantity_max: data.quantity_max,
			multiples: data.multiples,
			emoji_reaction: data.emoji_reaction,
			content_comment: data.content_comment,
			content_comment_image: data.content_comment_image,
			keyword: data.keyword,
			refundable: data.refundable,
			time_buff: data.time_buff,
			maintenance: data.maintenance
		};
	}
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn sửa dịch vụ này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_SERVICE_SERVER', payload: true});
			axios.post('/api/service/edit-server', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListServer(data.page, data.sizePerPage, data.searchText, data.filterStatus));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const deleteServer = (data) => (dispatch, getState) => {
	var body = {
		id: data.id
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn xóa server này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_SERVICE_SERVER', payload: true});
			axios.post('/api/service/delete-server', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				if (res.data.status === 200) {
					dispatch(getListServer(data.page, data.sizePerPage, data.searchText, data.filterStatus));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const getLoadListUserRolesPrices = (servers_id) => (dispatch, getState) => {
	dispatch({type: 'LOADING_SERVICE_SERVER', payload: true});
	var body = {
		servers_id
	}
	axios.post('/api/service/list-userroles-prices', body, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_USER_ROLES_PRICES',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

const saveSetPricesAuto = (data, closemodal) => (dispatch, getState) => {
	var body = {
		prices: data.prices,
		type: data.type
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn cài đặt giá tự động này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_SERVICE_SERVER', payload: true});
			axios.post('/api/service/set-prices-auto-server', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListServer(1, 10));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveSetPricesAutoUserRoles = (data, closemodal) => (dispatch, getState) => {
	var body = {
		to_user_roles: data.to_user_roles,
		price: data.price,
		type: data.type,
		from_user_roles: data.from_user_roles
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn cài đặt giá tự động theo loại tài khoản này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_SERVICE_SERVER', payload: true});
			axios.post('/api/service/set-prices-auto-userroles', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListServer(1, 10));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveSetPricesAutoUserRolesFromXLSX = (data) => (dispatch, getState) => {
	var body = {
		data
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn cài đặt giá tự động theo file XLSX này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			// console.log(body);
			dispatch({type: 'LOADING_SERVICE_SERVER', payload: true});
			axios.post('/api/service/set-prices-auto-userroles-from-xlsx', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				if (res.data.status === 200) {
					dispatch(getListServer(1, 10));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const exportXLSX = () => (dispatch, getState) => {
	var body = {
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn xuất file XLSX này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			// console.log(body);
			dispatch({type: 'LOADING_SERVICE_SERVER', payload: true});
			axios.post('/api/service/export-xlsx', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				if (res.data.status === 200) {
					dispatch({
						type: 'EXPORT_XLSX',
						payload: res.data.data
					});
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_SERVICE_SERVER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

export {
	getListService,
	saveAddService,
	saveEditService,
	deleteService,
	getListMenu,
	saveMenu,
	getListServer,
	saveAddServer,
	saveEditServer,
	deleteServer,
	getLoadListUserRolesPrices,
	saveSetPricesAuto,
	saveSetPricesAutoUserRoles,
	saveSetPricesAutoUserRolesFromXLSX,
	exportXLSX
}
