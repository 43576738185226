import { t } from "i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";

const getListBank = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
	dispatch({type: 'LOADING_BANK', payload: true});
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	axios.get(`/api/bank/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_BANK', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_BANK',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_BANK', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

const saveAddBank = (data, closemodal) => (dispatch, getState) => {
	var body = {
		card_numbers: data.card_numbers,
		card_username: data.card_username,
		card_bank: data.card_bank,
		card_bankname: data.card_bankname,
		card_binbank: data.card_binbank,
		card_branch: data.card_branch,
		tranfer_content: data.tranfer_content,
		description: data.description
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn thêm ngân hàng này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_BANK', payload: true});
			axios.post('/api/bank/add', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_BANK', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListBank(1, 10));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_BANK', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveEditBank = (data, closemodal) => (dispatch, getState) => {
	var body = {
		id: data.id,
		card_numbers: data.card_numbers,
		card_username: data.card_username,
		card_bank: data.card_bank,
		card_bankname: data.card_bankname,
		card_binbank: data.card_binbank,
		card_branch: data.card_branch,
		tranfer_content: data.tranfer_content,
		description: data.description
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn sửa ngân hàng này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_BANK', payload: true});
			axios.post('/api/bank/edit', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_BANK', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListBank(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_BANK', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const deleteBank = (data) => (dispatch, getState) => {
	var body = {
		id: data.id
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn xóa ngân hàng này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_BANK', payload: true});
			axios.post('/api/bank/delete', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_BANK', payload: false});
				if (res.data.status === 200) {
					dispatch(getListBank(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_BANK', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const getListBankFromApi = () => (dispatch, getState) => {
	dispatch({type: 'LOADING_BANK', payload: true});
	axios.get(`/api/bank/list-bank`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_BANK', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BANK_API',
				payload: res.data.data
			});
		} else {
			Swal.fire("Lỗi", res.data.message, 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_BANK', payload: false});
		Swal.fire("Lỗi", "Có lỗi xảy ra!", 'error');
	});
};

export {
	getListBank,
	saveAddBank,
	saveEditBank,
	deleteBank,
	getListBankFromApi
}
