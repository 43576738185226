import { Fragment } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import { Link } from "react-router-dom";

function Information(props) {
    const { fullname, username, description, email, birthday, gender, phonenumber, address, facebook, instagram, tiktok, twitter, telegram } = props.user.user;

    var convert_birthday = "";
    if (birthday) {
        var d = new Date(birthday);
        var day = '' + d.getDate();
        var month = '' + (d.getMonth() + 1);
        var year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
            
        convert_birthday = day + "/" + month + "/" + year;
    }

	return (
        <Fragment>
            <div className="profile-photo">
                {
                    gender === 0 ?
                        <img src={require("components/theme_default/assets/vendors/images/employee_male_1.png")} alt="" className="avatar-photo" />
                    :
                        <img src={require("components/theme_default/assets/vendors/images/employee_female_1.png")} alt="" className="avatar-photo" />
                }
            </div>
            <h5 className="text-center h5 mb-0">{fullname}</h5>
            <p className="text-center text-muted font-14">
                <span className="al-vaal-s">{username} - </span><span className="badge badge-primary al-badge">{description}</span>
            </p>
            <div className="profile-info">
                <h5 className="mb-20 h5 text-blue">{t("Thông tin liên hệ")}</h5>
                <ul>
                    <li>
                        <span>{t("Email")}</span>
                        {email}
                    </li>
                    <li>
                        <span>{t("Ngày sinh")}</span>
                        {convert_birthday}
                    </li>
                    <li>
                        <span>{t("Số điện thoại")}</span>
                        {phonenumber}
                    </li>
                    <li>
                        <span>{t("Địa chỉ")}</span>
                        {address}
                    </li>
                </ul>
            </div>
            <div className="profile-social">
                <h5 className="mb-20 h5 text-blue">{t("Liên hệ")}</h5>
                <ul className="clearfix">
                    {
                        facebook !== "" ?
                            <li>
                                <Link to={facebook} className="btn" style={{"color": "rgb(255, 255, 255)", "backgroundColor": "rgb(59, 89, 152)"}} target="_blank"><i className="fa fa-facebook"></i></Link>
                            </li>
                        : ""
                    }
                    {
                        instagram !== "" ?
                            <li>
                                <Link to={instagram} className="btn" style={{"color": "rgb(255, 255, 255)", "backgroundColor": "rgb(169, 0, 255)"}} target="_blank"><i className="fa fa-instagram"></i></Link>
                            </li>
                        : ""
                    }
                    {
                        tiktok !== "" ?
                            <li>
                                <Link to={tiktok} className="btn" style={{"color": "rgb(255, 255, 255)", "backgroundColor": "rgb(0, 0, 0)"}} target="_blank">
                                    <img src="" alt="" className="al-tiktok-white" style={{ "width": "13px", "height": "-webkit-fill-available" }} />
                                </Link>
                            </li>
                        : ""
                    }
                    {
                        twitter !== "" ?
                            <li>
                                <Link to={twitter} className="btn" style={{"color": "rgb(255, 255, 255)", "backgroundColor": "rgb(29, 161, 242)"}} target="_blank"><i className="fa fa-twitter"></i></Link>
                            </li>
                        : ""
                    }
                    {
                        telegram !== "" ?
                            <li>
                                <Link to={telegram} className="btn" style={{"color": "rgb(255, 255, 255)", "backgroundColor": "rgb(77, 155, 227)"}} target="_blank"><i className="fa fa-telegram"></i></Link>
                            </li>
                        : ""
                    }
                </ul>
            </div>
        </Fragment>
	);
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Information);