import {  Fragment } from "react";
import { connect } from "react-redux";

function Maincontainer() {
	return (
		<Fragment>
			theme_1
		</Fragment>
	);
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Maincontainer);
