import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getConfig, saveConfig } from "actions/configActions";
import { cleanObject } from "components/theme_default/Function";

function Website(props) {
    const [refund_fee, setRefundFee] = useState("");
    const [refund_fee_tmp, setRefundFeeTmp] = useState("");
    const [maintenance, setMaintenance] = useState("");
    const [maintenance_tmp, setMaintenanceTmp] = useState("");
    var { getConfig, saveConfig, cleanObject } = props;
    var { all_config } = props.config;
    var { role } = props.user.user;

	useEffect(() => {
		getConfig();
	}, [getConfig]);

	useEffect(() => {
		if (all_config.refund_fee) {
            setRefundFeeTmp(all_config.refund_fee);
        }
		if (all_config.maintenance) {
            setMaintenanceTmp(all_config.maintenance);
        }
	}, [all_config]);

    const onChange = (e) => {
        if (e.target.name === "refund_fee_tmp") {
            setRefundFee(e.target.value);
            setRefundFeeTmp(e.target.value);
        }
        if (e.target.name === "maintenance_tmp") {
            setMaintenance(e.target.value);
            setMaintenanceTmp(e.target.value);
        }
    }

    const onClickSaveConfig = (e) => {
        var data = {refund_fee, maintenance};
        var data_clean = cleanObject(data);
        saveConfig(data_clean);
    }

	const onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

	return (
		<Fragment>
            {
                role === 0 ?
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-2 col-form-label">{t("Bảo trì")}</label>
                        <div className="col-sm-12 col-md-10 d-flex">
                            <div className="custom-control custom-radio mr-20">
                                <input type="radio" id="maintenance_tmp_on" value="1" name="maintenance_tmp" className="custom-control-input" onChange={(e) => {onChange(e)}} checked={maintenance_tmp === "1"} />
                                <label className="custom-control-label weight-400" htmlFor="maintenance_tmp_on"><span className='al-vaal-s'>{t("Bật")}</span></label>
                            </div>
                            <div className="custom-control custom-radio">
                                <input type="radio" id="maintenance_off" value="0" name="maintenance_tmp" className="custom-control-input" onChange={(e) => {onChange(e)}} checked={maintenance_tmp === "0"} />
                                <label className="custom-control-label weight-400" htmlFor="maintenance_off"><span className='al-vaal-s'>{t("Tắt")}</span></label>
                            </div>
                        </div>
                    </div>
                : ""
            }
            <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">{t("Phí hủy đơn")}</label>
                <div className="col-sm-12 col-md-3 input-group bootstrap-touchspin al-input-group">
                    <input className="form-control" value={refund_fee_tmp} type="text" name="refund_fee_tmp" placeholder={t("0")} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}}/>
                    <span className="input-group-addon bootstrap-touchspin-postfix input-group-append al-postfix">
                        <span className="input-group-text">{t("vnđ")}</span>
                    </span>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label"></label>
                <div className="col-sm-12 col-md-3">
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveConfig()}} >{t("Lưu")}</button>
                </div>
            </div>
		</Fragment>
	);
}

Website.propTypes = {
	getConfig: PropTypes.func.isRequired,
	saveConfig: PropTypes.func.isRequired,
	cleanObject: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    config: state.config,
    user: state.user
});
const mapDispatchToProps = {
    getConfig,
    saveConfig,
    cleanObject
}

export default connect(mapStateToProps, mapDispatchToProps)(Website);