import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { saveEditUserRole } from "actions/userActions";
import Loading from "components/theme_default/Loading";
import { OptionUserRole } from "components/theme_default/Function";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaledituserrole(props) {
    const [user_id] = useState(props.edituser.id);
    const [user_role, setUserRole] = useState(props.edituser.role);
    var { isOpenModalEditUserRole, openModal, saveEditUserRole } = props;
    var { listuserroles, isLoading } = props.user;

	const onClickSaveEditUserRole = () => {
		saveEditUserRole({ user_id, user_role, page: props.edituser.page, sizePerPage: props.edituser.sizePerPage, searchText: props.edituser.searchText }, openModal);
	};
    
	const onOpenModal = () => {
		openModal();
	};

	const onChange = (e) => {
        setUserRole(e.target.value);
	};

    var optionUserRole = <OptionUserRole name="user_role" value={user_role} onChange={(e) => {onChange(e)}} data={{listuserroles, user_role}} />
    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => {onOpenModal()}} isOpen={isOpenModalEditUserRole}>
                <Loading isLoading={isLoading}/>
                <div className="modal-header">
                    <p className="mb-0 text-dark">{t("Sửa tài khoản #")}{user_id}</p>
                    <button onClick={() => {onOpenModal()}} type="button" className="close">×</button>
                </div>
                <ModalBody>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Loại tài khoản")}</label>
                        <div className="col-sm-12 col-md-9">
                            {optionUserRole}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveEditUserRole()}} >{t("Lưu")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => {onOpenModal()}} >{t("Huỷ")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaledituserrole.propTypes = {
	saveEditUserRole: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
	user: state.user
});
const mapDispatchToProps = {
	saveEditUserRole
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaledituserrole);