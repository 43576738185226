import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListNotice } from "actions/noticeActions";
import ItemContainer from "./itemcontainer/ItemContainer";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

function Main(props) {
    const { listnotices } = props.notice;
    const { getListNotice } = props;
	
	useEffect(() => {
		getListNotice();
	}, [getListNotice]);

    const Container = ({ index, image, video }) => {
        return (
            <Fragment>
                <ItemContainer index={index} image={image} video={video} />
            </Fragment>
        )
    }

	return (
        <Fragment>
            {
                listnotices.map((v, i) => {
                    return (
                        <div className="container pd-0" key={i}>
                            <div className="pd-20 card-box mb-30">
                                <div className="clearfix mb-20">
                                    <div className="pull-left">
                                        <div className="row align-items-center mb-20">
                                            <div className="col-auto pr-0">
                                                <Link to="/">
                                                    <img src={require("components/theme_default/assets/vendors/images/admin.png")} width="55" alt="" className="rounded-circle border"/>
                                                </Link>
                                            </div>
                                            <div className="col text-left">
                                                <div className="row align-items-center mt-1">
                                                    <div className="col">
                                                        <span className="font-weight-bold mb-0">
                                                            <span className="" style={{"color": "#1877F2"}}>{t("Quản Trị Viên")} </span>
                                                            <i className="fa fa-check-circle" style={{"color": "#1877F2"}}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <span className="font-14 mb-0 text-secondary">
                                                            <Moment format="DD">{v.create_at * 1000}</Moment> {t("tháng")} <Moment format="MM ">{v.create_at * 1000}</Moment>
                                                            {t("lúc")} <Moment format="HH:mm ">{v.create_at * 1000}</Moment>
                                                            <i className="fa fa-globe"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="h4">{v.title}</h4>
                                        <p className="font-14 ml-0 al-ws-bs">
                                            {v.content}
                                        </p>
                                    </div>
                                </div>
                                <div className="container">
                                    <Container index={i} image={v.image} video={v.video} />
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </Fragment>
	);
}

Main.propTypes = {
	getListNotice: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    notice: state.notice
});
const mapDispatchToProps = {
    getListNotice
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);