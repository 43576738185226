import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getListOrder, getListServersOfServices, clearListOrders, refundOrder, extendOrder } from "actions/orderActions";
import { ReactTable, formatterCreateAt, FormatterStatus, FormatterApprove, FormatterCountSuccess, FormatterMoney, isObject, subStringText } from "components/theme_default/Function";

function Listorders(props) {
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(10);
	const [searchText, setSearchText] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [list_servers_id, setListServersId] = useState([]);
	const [list_order_view, setListOrderView] = useState("");
	const { getListOrder, getListServersOfServices, formatterCreateAt, clearListOrders, refundOrder, extendOrder, FormatterStatus, FormatterApprove, FormatterCountSuccess, FormatterMoney, isObject, subStringText } = props;
	const { listallserver, listallconfig } = props.website;
	const { listserverofservice, listorders, totalsize } = props.order;
	const { role } = props.user.user;

    useEffect(() => {
        getListServersOfServices(listallserver);
    }, [getListServersOfServices, listallserver]);

    useEffect(() => {
		var list_servers_id_tmp = listserverofservice.map((v, i) => {
			return v.id
		});
		setListServersId(list_servers_id_tmp);
    }, [listserverofservice]);
	
	useEffect(() => {
		if (list_servers_id.length > 0) {
			getListOrder(page, sizePerPage, searchText, list_servers_id, list_order_view);
		}
	}, [getListOrder, page, sizePerPage, searchText, list_servers_id, list_order_view]);
	
	useEffect(() => {
		if (list_servers_id.length === 0) {
			clearListOrders();
		}
	}, [clearListOrders, list_servers_id]);

	useEffect(() => {
		setTotalSize(totalsize);
	}, [totalsize]);

	const onClickView = (data) => {
		setPage(1);
		setSizePerPage(10);
		setListOrderView(data);
	}

	const onClickRefundOrder = (row = [], formatExtraData) => {
		refundOrder(row.id, formatExtraData, listallconfig.refund_fee||0);
	}

	const onClickExtendOrder = (row = [], formatExtraData) => {
		extendOrder(row.id, formatExtraData);
	}

	const columns = [{
		dataField: 'id',
		text: t("ID"),
		sort: true,
	}, {
		dataField: 'server_name',
		text: t("Server"),
		sort: true,
	}, {
		dataField: 'object_id',
		text: t("Object ID"),
		sort: true,
		formatter: (cell, row) => {
			if (!isObject(cell)) {
				var redirect_link = row.link;
				// if (row.social === 0) {
				// 	redirect_link = "https://www.facebook.com/" + row.link;
				// }
				return <Link to={redirect_link} target="_blank" className="btn-link">{subStringText(cell)}</Link>;
			}
			var obj = JSON.parse(cell);
			return (
				<Fragment>
					{t("private_key")}: {obj.api_private_key}<br/>
					{t("code")}: {obj.api_code}<br/>
					{
						obj.api_is_active === 0 ?
							<Fragment>{t("active")}: {t("chưa kích hoạt")}</Fragment>
						:
							<Fragment>{t("hết hạn")}: {formatterCreateAt(obj.expired_at)}</Fragment>
					}
				</Fragment>
			);
		},
		classes: "al-text-ws-pre",
	}, {
		dataField: 'count_start',
		text: t("Start"),
		sort: true,
	}, {
		dataField: 'count_success',
		text: t("Thành công"),
		sort: true,
		formatter: (cell, row) =>  <FormatterCountSuccess data={row} />,
		classes: 'text-center'
	}, {
		dataField: 'price_total',
		text: t("Thành tiền"),
		sort: true,
		formatter: (cell, row) =>  <FormatterMoney money={cell} />
	}, {
		dataField: 'create_at',
		text: t("Ngày tạo"),
		sort: true,
		formatter: (cell, row) =>  formatterCreateAt(cell)
	}, {
		dataField: 'approve',
		text: t("Xác nhận"),
		sort: true,
		formatter: (cell, row) =>  <FormatterApprove approve={cell} />
	}, {
		dataField: 'status',
		text: t("Trạng thái"),
		sort: true,
		formatter: (cell, row) =>  <FormatterStatus status={cell} />
	}, {
		dataField: 'username',
		text: t("User"),
		sort: true,
	}, {
		dataField: '-',
		text: t("Thao tác"),
		formatExtraData: {page, sizePerPage, searchText, list_servers_id, list_order_view},
		formatter: (cell, row, rowIndex, formatExtraData) => 
			<Fragment>
				{/* <button onClick={() => {onClickOpenModalEditBank(row)}} className="btn btn-primary btn-sm mr-1">
					<i className="fa fa-pencil-square-o"></i>
				</button> */}
				{
					isObject(row.object_id) ?
						JSON.parse(row.object_id).expired_at < Math.floor(Date.now() / 1000) ? 
							<button onClick={() => {onClickExtendOrder(row, formatExtraData)}} className="btn btn-primary btn-sm mr-1">
								<i className="fa fa-clock-o"></i>
							</button>
						: ""
					: ""
				}
				<button onClick={() => {onClickRefundOrder(row, formatExtraData)}} className="btn btn-danger btn-sm mr-1">
					<i className="fa fa-trash-o"></i>
				</button>
			</Fragment>
	}];

	
	const expandRow = {
		onlyOneExpanding: true,
		renderer: row => (
			<Fragment>
				{
					row.emoji_reaction ?
						<div className="form-group row">
							<label className="col-sm-12 col-md-1 col-form-label">{t("Cảm xúc")}</label>
							<div className="col-sm-12 col-md-1">
								{
									row.emoji_reaction === "like" ?
										<img src={require("components/theme_default/assets/vendors/images/emoji_reaction/like.svg").default} alt="" className="ml-2"/>
									: row.emoji_reaction === "love" ?
										<img src={require("components/theme_default/assets/vendors/images/emoji_reaction/love.svg").default} alt="" className="ml-2"/>
									: row.emoji_reaction === "care" ?
										<img src={require("components/theme_default/assets/vendors/images/emoji_reaction/care.svg").default} alt="" className="ml-2"/>
									: row.emoji_reaction === "haha" ?
										<img src={require("components/theme_default/assets/vendors/images/emoji_reaction/haha.svg").default} alt="" className="ml-2"/>
									: row.emoji_reaction === "wow" ?
										<img src={require("components/theme_default/assets/vendors/images/emoji_reaction/wow.svg").default} alt="" className="ml-2"/>
									: row.emoji_reaction === "sad" ?
										<img src={require("components/theme_default/assets/vendors/images/emoji_reaction/sad.svg").default} alt="" className="ml-2"/>
									: row.emoji_reaction === "angry" ?
										<img src={require("components/theme_default/assets/vendors/images/emoji_reaction/angry.svg").default} alt="" className="ml-2"/>
									: ""
								}
							</div>
						</div>
					: ""
				}
				{
					row.content_comment ?
						<div className="form-group row">
							<label className="col-sm-12 col-md-1 col-form-label">{t("Nội dung")}</label>
							<div className="col-sm-12 col-md-3">
								<textarea className="form-control al-form-control-textarea" value={row.content_comment} type="text" name="content_comment" placeholder="" rows="2" disabled></textarea>
							</div>
						</div>
					: ""
				}
				<div className="form-group row">
					<label className="col-sm-12 col-md-1 col-form-label">{t("Ghi chú")}</label>
					<div className="col-sm-12 col-md-3">
						<textarea className="form-control al-form-control-textarea" value={row.note} type="text" name="note" placeholder="" rows="2" disabled></textarea>
					</div>
				</div>
			</Fragment>
		),
		showExpandColumn: true,
		expandColumnPosition: 'right',
		expandByColumnOnly: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			if (isAnyExpands) {
				return <i className="bi bi-caret-up-fill"></i>;
			}
			return <b><i className="bi bi-caret-down-fill"></i></b>;
		},
		expandColumnRenderer: ({ expanded }) => {
			if (expanded) {
				return (
					<b><i className="bi bi-caret-up-fill"></i></b>
				);
			}
			return (
				<b><i className="bi bi-caret-down-fill"></i></b>
			);
		}
	};

	const groupButton = () => {
		return (
			<div>
				{
					role === 0 || role === 1 ?
						<Fragment>
							<button onClick={() => {onClickView("all")}} type="button" className="btn btn-sm btn-primary mr-1">
								{t("Xem tất cả")}
							</button>
							<button onClick={() => {onClickView("")}} type="button" className="btn btn-sm btn-primary mr-1">
								{t("Xem riêng")}
							</button>
						</Fragment>
					: ""
				}
			</div>
		);
	}

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(10);
			setSearchText(searchText);
		} else {
			setPage(page);
			setSizePerPage(sizePerPage);
		}
	}

	return (
		<Fragment>
			<ReactTable columns={columns} data={listorders} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} expandRow={expandRow} alClassName="table-layout-inherit" />
		</Fragment>
	);
}

Listorders.propTypes = {
	getListOrder: PropTypes.func.isRequired,
	getListServersOfServices: PropTypes.func.isRequired,
	formatterCreateAt: PropTypes.func.isRequired,
	clearListOrders: PropTypes.func.isRequired,
	refundOrder: PropTypes.func.isRequired,
	extendOrder: PropTypes.func.isRequired,
	FormatterStatus: PropTypes.func.isRequired,
	FormatterCountSuccess: PropTypes.func.isRequired,
	FormatterMoney: PropTypes.func.isRequired,
	FormatterApprove: PropTypes.func.isRequired,
	isObject: PropTypes.func.isRequired,
	subStringText: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	order: state.order,
	website: state.website,
	user: state.user
});
const mapDispatchToProps = {
	getListOrder,
	getListServersOfServices,
	formatterCreateAt,
	clearListOrders,
	refundOrder,
	extendOrder,
	FormatterStatus,
	FormatterCountSuccess,
	FormatterMoney,
	FormatterApprove,
	isObject,
	subStringText
}

export default connect(mapStateToProps, mapDispatchToProps)(Listorders);