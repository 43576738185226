import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListBank, deleteBank } from "actions/bankActions";
import { ReactTable } from "components/theme_default/Function";
import { Modaladdbank, Modaleditbank } from "./modal";

function Listbanks(props) {
	const [isOpenModalAddBank, setIsOpenModalAddBank] = useState(false);
	const [isOpenModalEditBank, setIsOpenModalEditBank] = useState(false);
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(10);
	const [searchText, setSearchText] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [editbank, setEditBank] = useState([]);
	const { listbanks, totalsize } = props.bank;
	const { getListBank, deleteBank } = props;
	
	useEffect(() => {
		getListBank(page, sizePerPage, searchText);
	}, [getListBank, page, sizePerPage, searchText]);

	useEffect(() => {
		setTotalSize(totalsize);
	}, [totalsize]);
	
	const onClickOpenModalAddBank = () => {
		setIsOpenModalAddBank(!isOpenModalAddBank);
    };

	const onClickOpenModalEditBank = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		setIsOpenModalEditBank(!isOpenModalEditBank);
		setEditBank(row);
    };
	
	const onClickDeleteBank = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText
			}
		}
		deleteBank(row);
	};

	const columns = [{
		dataField: 'id',
		text: t("ID"),
		sort: true,
	}, {
		dataField: 'card_numbers',
		text: t("Số tài khoản"),
		sort: true,
	}, {
		dataField: 'card_username',
		text: t("Tên tài khoản"),
		sort: true,
	}, {
		dataField: 'card_bank',
		text: t("Ngân hàng"),
		sort: true,
	}, {
		dataField: 'card_branch',
		text: t("Chi nhánh"),
		sort: true,
	}, {
		dataField: 'tranfer_content',
		text: t("Nội dung chuyển khoản"),
		sort: true,
	}, {
		dataField: 'description',
		text: t("Ghi chú"),
		sort: true,
	}, {
		dataField: '-',
		text: t("Thao tác"),
		formatExtraData: {page, sizePerPage, searchText},
		formatter: (cell, row, rowIndex, formatExtraData) => 
			<Fragment>
				<button onClick={() => {onClickOpenModalEditBank(row, formatExtraData)}} className="btn btn-primary btn-sm mr-1">
					<i className="fa fa-pencil-square-o"></i>
				</button>
				<button onClick={() => {onClickDeleteBank(row, formatExtraData)}} className="btn btn-danger btn-sm mr-1">
					<i className="fa fa-trash-o"></i>
				</button>
			</Fragment>
	}];

	const groupButton = () => {
		return (
			<div>
				<button onClick={() => {onClickOpenModalAddBank()}} type="button" className="btn btn-sm btn-success mr-1">
					<i className="fa fa-plus mr-1"></i> {t("Thêm tài khoản")}
				</button>
			</div>
		);
	}

	const handleTableChange = (type, { page, sizePerPage, searchText }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(10);
			setSearchText(searchText);
		} else {
			setPage(page);
			setSizePerPage(sizePerPage);
		}
	}

	return (
		<Fragment>
			<ReactTable columns={columns} data={listbanks} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" />
			{isOpenModalAddBank ? <Modaladdbank openModal={onClickOpenModalAddBank} isOpenModalAddBank={isOpenModalAddBank} /> : '' }
			{isOpenModalEditBank ? <Modaleditbank openModal={onClickOpenModalEditBank} isOpenModalEditBank={isOpenModalEditBank} editbank={editbank} /> : '' }
		</Fragment>
	);
}

Listbanks.propTypes = {
	getListBank: PropTypes.func.isRequired,
	deleteBank: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	bank: state.bank
});
const mapDispatchToProps = {
	getListBank,
	deleteBank
}

export default connect(mapStateToProps, mapDispatchToProps)(Listbanks);