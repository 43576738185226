import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListRole } from "actions/userActions";
import { saveSetPricesAuto } from "actions/serviceActions";
import Loading from "components/theme_default/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modalsetpricesauto(props) {
    var [prices, setPrices] = useState({});
    var [type, setType] = useState("0");
    var { isOpenModalSetPricesAuto, openModal, getListRole, saveSetPricesAuto } = props;
    var { isLoadingServer } = props.service;
    var { listuserroles } = props.user;

	useEffect(() => {
		getListRole();
	}, [getListRole]);

	const onClickSaveSetPricesAuto = () => {
		saveSetPricesAuto({ prices, type }, openModal);
	};

	const onOpenModal = () => {
		openModal();
	};

	const onChange = (e) => {
        if (e.target.name === "prices") {
            prices[e.currentTarget.getAttribute('data-value')] = e.target.value;
            prices = JSON.stringify(prices);
            prices = JSON.parse(prices);
            setPrices(prices);
        }
        if (e.target.name === "type") {
            setType(e.target.value);
        }
	};
	
	const onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    const userRolesPrices = () => {
        return listuserroles.map((v, i) => {
            return (
                <div className="form-group row" key={i}>
                    <label className="col-sm-12 col-md-6 col-form-label">{v.name}</label>
                    <div className="col-sm-12 col-md-3 input-group bootstrap-touchspin al-input-group">
                        <input className="form-control" value={prices[v.id] ? prices[v.id] : 0} data-value={v.id} type="text" name="prices" placeholder={t("0")} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}} />
                        <span className="input-group-addon bootstrap-touchspin-postfix input-group-append al-postfix">
                            <select className="btn btn-light waves-effect bg-light" value={type} name="type" onChange={(e) => {onChange(e)}}>
                                <option value="0">%</option>
                                <option value="1">VNĐ</option>
                            </select>
                        </span>
                    </div>
                </div>
            );
        });
    }

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={() => {onOpenModal()}} isOpen={isOpenModalSetPricesAuto}>
                <Loading isLoading={isLoadingServer}/>
                <div className="modal-header">
                    <p className="mb-0 text-dark">{t("Cài đặt giá tự động")}</p>
                    <button onClick={() => {onOpenModal()}} type="button" className="close">×</button>
                </div>
                <ModalBody>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Giá")}</label>
                        <div className="col-sm-12 col-md-9 al-user-roles-prices">
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-6 col-form-label">{t("Loại tài khoản")}</label>
                                <label className="col-sm-12 col-md-6 col-form-label">{t("Tăng % giá so với site gốc")}</label>
                            </div>
                            {userRolesPrices()}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveSetPricesAuto()}} >{t("Lưu")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => {onOpenModal()}} >{t("Huỷ")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modalsetpricesauto.propTypes = {
	getListRole: PropTypes.func.isRequired,
	saveSetPricesAuto: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	user: state.user,
	service: state.service
});
const mapDispatchToProps = {
	getListRole,
	saveSetPricesAuto
}

export default connect(mapStateToProps, mapDispatchToProps)(Modalsetpricesauto);