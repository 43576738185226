import { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getListBank } from "actions/bankActions";
import { convertFullNameBankFromShortName } from "components/theme_default/Function";

function Main(props) {
	const { getListBank } = props;
	const { listbanks } = props.bank;
	
	useEffect(() => {
		getListBank();
	}, [getListBank]);

	return (
		<div className="pd-20 border-radius-4">
			<div className="container px-0">
				<div className="row">
					{
						listbanks.map((v, i) => {
							return (
								<div className="col-md-6 ml-md-auto mr-md-auto" key={i}>
									<div className="card-box pricing-card mt-15 mb-15 pl-4 pr-4">
										<div className="pricing-icon">
										</div>
										<ul>
											<li className="al-flex-container">
												<img src={v.qrcode} alt=""/>
											</li>
										</ul>
									</div>
								</div>
							);
						})
					}
				</div>
			</div>
		</div>
	);
}

Main.propTypes = {
	getListBank: PropTypes.func.isRequired,
	convertFullNameBankFromShortName: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	user: state.user,
	bank: state.bank
});
const mapDispatchToProps = {
	getListBank,
	convertFullNameBankFromShortName
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);