import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { NoticeVideo } from "components/theme_default/Function";

function OneItem(props) {
    var { index, arr_images, video, onClick } = props;

    return (
        <Fragment>
            <div className="row">
                <div className={video !== "" ? "col-sm-12 col-md-3" : "col-3"}>
                </div>
                <div className={video !== "" ? "col-sm-12 col-md-6" : "col-6"}>
                    {
                        video !== "" ?
                        <NoticeVideo src={video} />
                        :
                            <Link className="h-100" to="/" data-glightbox="" data-gallery="image-popup" onClick={() => {onClick(0, index)}}>
                                <img className="rounded img-fluid" src={arr_images[0]} alt=""/>
                            </Link>
                    }
                </div>
                <div className={video !== "" ? "col-sm-12 col-md-3" : "col-3"}>
                </div>
            </div>
        </Fragment>
    )
}

OneItem.propTypes = {
};
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OneItem);