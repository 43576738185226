import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { getListService, deleteService } from "actions/serviceActions";
import { ReactTable, formatterSocial, formatterServiceType } from "components/theme_default/Function";
import { Modaladdservice, Modaleditservice } from "./modal";
import { selectFilter } from 'react-bootstrap-table2-filter';

function Listservices(props) {
	const [isOpenModalAddService, setIsOpenModalAddService] = useState(false);
	const [isOpenModalEditService, setIsOpenModalEditService] = useState(false);
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(10);
	const [searchText, setSearchText] = useState("");
	const [filterStatus, setFilterStatus] = useState("");
	const [filterStatus2, setFilterStatus2] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [editservice, setEditService] = useState([]);
	const { listservices, totalsize_admin_list_service } = props.service;
	const { deleteService, formatterSocial, formatterServiceType, getListService } = props;
	const selectOptions = {
		0: t("Facebook"),
		1: t("Instagram"),
		2: t("Youtube"),
		3: t("Tiktok"),
		4: t("Shopee"),
		5: t("Google"),
		6: t("Telegram"),
		7: t("Twitter"),
		8: t("Tripadvisor"),
		9: t("FES")
	};
	const selectOptions2 = {
		0: t("Like"),
		1: t("Comment"),
		2: t("Share"),
		3: t("Like Comment"),
		4: t("Follow"),
		5: t("Like Fanpage"),
		6: t("Đánh giá và Check in Fanpage"),
		7: t("Mắt Livestream"),
		8: t("View Video"),
		9: t("View Story"),
		10: t("Thành viên Group"),
		11: t("Yêu Thích"),
		12: t("Retweet"),
		13: t("Comment và Hình ảnh"),
		14: t("License key"),
		15: t("Traffic"),
	};

	useEffect(() => {
		getListService(page, sizePerPage, searchText, filterStatus, filterStatus2);
	}, [getListService, page, sizePerPage, searchText, filterStatus, filterStatus2]);

	useEffect(() => {
		setTotalSize(totalsize_admin_list_service);
	}, [totalsize_admin_list_service]);

	const onClickOpenModalAddService = () => {
		setIsOpenModalAddService(!isOpenModalAddService);
	};

	const onClickOpenModalEditService = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText,
				filterStatus: formatExtraData.filterStatus,
				filterStatus2: formatExtraData.filterStatus2
			}
		}
		setIsOpenModalEditService(!isOpenModalEditService);
		setEditService(row);
    };

	const onClickDeleteService = (row = [], formatExtraData) => {
		if (typeof formatExtraData !== "undefined") {
			row = {
				...row,
				page: formatExtraData.page,
				sizePerPage: formatExtraData.sizePerPage,
				searchText: formatExtraData.searchText,
				filterStatus: formatExtraData.filterStatus,
				filterStatus2: formatExtraData.filterStatus2
			}
		}
		deleteService(row);
	};

	const columns = [{
		dataField: 'id',
		text: t("ID"),
		sort: true,
	}, {
		dataField: 'name',
		text: t("Tên dịch vụ"),
		sort: true,
	}, {
		dataField: 'url',
		text: t("URL"),
		sort: true,
    }, {
		dataField: 'social',
		text: "",
		sort: true,
        filter: selectFilter({
            placeholder: t("Mạng xã hội..."),
            options: selectOptions
        }),
		formatter: (cell, row) =>  formatterSocial(row)
    }, {
		dataField: 'service_type',
		text: "",
		sort: true,
        filter: selectFilter({
            placeholder: t("Loại dịch vụ..."),
            options: selectOptions2
        }),
		formatter: (cell, row) =>  formatterServiceType(row)
	}, {
		text: t("Thao tác"),
		dataField: '-',
		formatExtraData: {page, sizePerPage, searchText, filterStatus, filterStatus2},
		formatter: (cell, row, rowIndex, formatExtraData) => 
			<Fragment>
				<button onClick={() => {onClickOpenModalEditService(row, formatExtraData)}} className="btn btn-primary btn-sm mr-1">
					<i className="fa fa-pencil-square-o"></i>
				</button>
				<button onClick={() => {onClickDeleteService(row, formatExtraData)}} className="btn btn-danger btn-sm mr-1">
					<i className="fa fa-trash-o"></i>
				</button>
			</Fragment>
	}];

	const groupButton = () => {
		return (
			<div>
				<button onClick={() => {onClickOpenModalAddService()}} type="button" className="btn btn-sm btn-success mr-1">
					<i className="fa fa-fw fa-plus mr-1"></i> {t("Thêm dịch vụ")}
				</button>
			</div>
		);
	}

	const handleTableChange = (type, { page, sizePerPage, searchText, filters }) => {
		if (type === "search") {
			setPage(1);
			setSizePerPage(10);
			setSearchText(searchText);
		} else if (type === "filter") {
            var filterVal = "";
            if (filters.social) {
                filterVal = filters.social.filterVal;
            }
            var filterVal2 = "";
            if (filters.service_type) {
                filterVal2 = filters.service_type.filterVal;
            }
            setPage(1);
            setSizePerPage(10);
            setFilterStatus(filterVal);
            setFilterStatus2(filterVal2);
		} else {
			setPage(page);
			setSizePerPage(sizePerPage);
		}
	}

	return (
		<Fragment>
			<ReactTable columns={columns} data={listservices} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit" />
			{isOpenModalAddService ? <Modaladdservice openModal={onClickOpenModalAddService} isOpenModalAddService={isOpenModalAddService} /> : '' }
			{isOpenModalEditService ? <Modaleditservice openModal={onClickOpenModalEditService} isOpenModalEditService={isOpenModalEditService} editservice={editservice} /> : '' }
		</Fragment>
	);
}

Listservices.propTypes = {
	getListService: PropTypes.func.isRequired,
	deleteService: PropTypes.func.isRequired,
	formatterSocial: PropTypes.func.isRequired,
	formatterServiceType: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	service: state.service
});
const mapDispatchToProps = {
    getListService,
    deleteService,
    formatterSocial,
    formatterServiceType
}

export default connect(mapStateToProps, mapDispatchToProps)(Listservices);