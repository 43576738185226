import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { enable2FA, disable2FA, confirm2FA } from "actions/userActions";

function Twofa(props) {
    const [confirm_verifycode, setConfirmVerifyCode] = useState("");
    const { enable2FA, disable2FA, confirm2FA } = props;
    const { config2fa, config2fa_secret, config2fa_uri, config2fa_qr } = props.user.user;

	const onClickEnable2FA = () => {
		enable2FA();
	};

	const onClickDisable2FA = () => {
		disable2FA();
	};

	const onClickConfirm2FA = () => {
		confirm2FA({ confirm_verifycode });
	};

    const onChange = (e) => {
        if (e.target.name === "confirm_verifycode") {
            setConfirmVerifyCode(e.target.value);
        }
    }

	return (
        <Fragment>
            <div className="pd-20">
                <div className="form-group row">
                    <label className="col-sm-12 col-md-4 col-form-label">{t("Two-factor authentication (2FA)")}</label>
                    <div className="col-sm-12 col-md-8">
                        {
                            config2fa_secret === "" && config2fa_uri === "" && config2fa_qr === "" ?
                                <button type="button" className="btn btn-primary" onClick={() => {onClickEnable2FA()}}>{t("Tạo mã 2FA")}</button>
                            :
                                <button type="button" className="btn btn-danger" onClick={() => {onClickDisable2FA()}}>{t("Tắt mã 2FA")}</button>
                        }
                    </div>
                </div>
                {
                    config2fa_uri !== "" && config2fa_qr !== "" ?
                        <div className="form-group row">
                            <label className="col-sm-12 col-md-4 col-form-label">{t("2FA QR")}</label>
                            <div className="col-sm-12 col-md-8">
                                <img src={config2fa_qr} alt="" />
                            </div>
                        </div>
                    : ''
                }
                {
                    config2fa_uri !== "" && config2fa_secret !== "" ?
                        <div className="form-group row">
                            <label className="col-sm-12 col-md-4 col-form-label">{t("2FA mã khôi phục")}</label>
                            <div className="col-sm-12 col-md-8">
                                <span>{config2fa_secret}</span>
                            </div>
                        </div>
                    : ''
                }
                {
                    config2fa === 0 && config2fa_secret !== "" && config2fa_uri !== "" && config2fa_qr !== "" ?
                        <Fragment>
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-4 col-form-label">Nhập mã xác minh:</label>
                                <div className="col-sm-12 col-md-8">
                                    <input className="form-control" value={confirm_verifycode} type="confirm_verifycode" name="confirm_verifycode" placeholder="" onChange={(e) => {onChange(e)}}/>
                                    <h6 className="text-danger mt-2">Lưu ý: Nhập mã xác minh lần đầu để kích hoạt chức năng 2FA lên!</h6>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-12 col-md-4 col-form-label"></label>
                                <div className="col-sm-12 col-md-8">
                                    <button type="button" className="btn btn-primary" onClick={() => {onClickConfirm2FA()}} >{t("Kích hoạt")}</button>
                                </div>
                            </div>
                        </Fragment>
                    : ""
                }

                <div className="form-group row">
                    <label className="col-sm-12 col-md-4 col-form-label"></label>
                    <div className="col-sm-12 col-md-8">
                        Hướng dẫn và tải app Google Authenticator: <Link to="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank" className="text-primary" >iPhone</Link> | <Link to="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en" target="_blank" className="text-primary" >Android</Link>
                    </div>
                </div>
            </div>
        </Fragment>
	);
}

Twofa.propTypes = {
    enable2FA: PropTypes.func.isRequired,
    disable2FA: PropTypes.func.isRequired,
    confirm2FA: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    enable2FA,
    disable2FA,
    confirm2FA
}

export default connect(mapStateToProps, mapDispatchToProps)(Twofa);