import { useState } from 'react';
import { t } from "i18next";
import styles from "./CustomNode.module.css";
import { formatterSocial, IconServices } from "components/theme_default/Function";

export const CustomNode = (props) => {
    const [hover, setHover] = useState(false);
    const { id, droppable } = props.node;
    const indent = props.depth * 24;

    const handleToggle = (e) => {
        e.stopPropagation();
        props.onToggle(props.node.id);
    };

    return (
        <div className={`tree-node ${styles.root}`} style={{ paddingInlineStart: indent }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
            <div className={`${styles.expandIconWrapper} ${ props.isOpen ? styles.isOpen : "" }`} onClick={(e) => {handleToggle(e)}} >
                {props.node.droppable && (
                    <i className="fa fa-play" style={{ "fontSize": "10px" }}></i>
                )}
            </div>
            <IconServices id={id} type="menu" />
            <div className={"al-wrap-menu-trash-setup " + styles.labelGridItem}>
                <span className="mr-3">
                    {
                        droppable ?
                            t(props.node.text)
                        : formatterSocial(props.node.data)() + (props.node.data.social === "-" ? "" : " - ") + t(props.node.text)
                    }
                </span>
                {hover && (
                    <div className={styles.actionButton} onClick={() => props.onDelete(props.node)}>
                        <i className="fa fa-trash-o"></i>
                    </div>
                )}
            </div>
        </div>
    );
};
