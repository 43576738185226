import { t } from "i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";

const loadUser = () => (dispatch, getState) => {
	axios.get("/api/user", tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'USER_LOADED',
				payload: res.data.data
			});
		} else {
			if (res.data.status !== 401) {
				dispatch({
					type: 'AUTH_ERROR'
				});
			}
			if (res.data.status === 401) {
				Swal.fire(t("Lỗi"), t(res.data.message), 'error');
			}
		}
	}).catch(err => {
		dispatch({
			type: 'AUTH_ERROR'
		});
	});
};

const loginUser = (username, password, confirm_verifycode) => (dispatch, getState) => {
	dispatch({type: 'LOADING_USER', payload: true});
	if (username.trim() === '' || password.trim() === '') {
		dispatch({type: 'LOADING_USER', payload: false});
		Swal.fire(t("Lỗi"), t("Vui lòng nhập đầy đủ tên tài khoản và mật khẩu!"), 'error');
		return false;
	}
	var body = {
		username,
		password,
		confirm_verifycode
	}
	axios.post("/api/user/login", body, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_USER', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'LOGIN_SUCCESS',
				payload: res.data.data
			});
			dispatch(loadUser());
		} else if (res.data.status === 401) {
			dispatch({
				type: 'UPDATE_IS_ENABLE_2FA',
				payload: true
			});
			Swal.fire(t("Thông báo"), t(res.data.message), 'warning');
		} else {
			dispatch({
				type: 'LOGIN_FAIL'
			});
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({
			type: 'LOGIN_FAIL'
		});
		Swal.fire(t("Lỗi"), t("Sai tên tài khoản hoặc mật khẩu!"), 'error');
	});
};

const registerUser = (username, email, phonenumber, password, repeatpassword) => (dispatch, getState) => {
	var body = {
		username,
		email,
		phonenumber,
		password,
		repeatpassword
	}
	dispatch({type: 'LOADING_USER', payload: true});
	axios.post("/api/user/register", body, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'REGISTER_SUCCESS',
				payload: res.data.data
			});
		} else {
			dispatch({
				type: 'REGISTER_FAIL'
			});
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({
			type: 'REGISTER_FAIL'
		});
		Swal.fire(t("Lỗi"), t("Đăng ký thất bại!"), 'error');
	});
};

const logoutUser = () => (dispatch, getState) => {
	dispatch({
		type: 'LOGOUT_SUCCESS',
	});
};

const getListUser = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
	dispatch({type: 'LOADING_USER', payload: true});
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	axios.get(`/api/user/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_USER', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_USER',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_USER', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

const getListRole = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
	dispatch({type: 'LOADING_ROLE', payload: true});
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	axios.get(`/api/user/list-role?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({type: 'LOADING_ROLE', payload: false});
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_ROLE',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Lỗi"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({type: 'LOADING_ROLE', payload: false});
		Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
	});
};

const deleteUser = (data) => (dispatch, getState) => {
	var body = {
		id: data.id
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn xóa tài khoản này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_USER', payload: true});
			axios.post('/api/user/delete', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_USER', payload: false});
				if (res.data.status === 200) {
					dispatch(getListUser(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_USER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const deleteRole = (data) => (dispatch, getState) => {
	var body = {
		id: data.id
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn xóa loại tài khoản này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_ROLE', payload: true});
			axios.post('/api/user/delete-role', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_ROLE', payload: false});
				if (res.data.status === 200) {
					dispatch(getListRole(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ROLE', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveEditUserRole = (data, closemodal) => (dispatch, getState) => {
	var body = {
		id: data.user_id,
		role: data.user_role,
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn sửa tài khoản này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_USER', payload: true});
			axios.post('/api/user/edit-user-role', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_USER', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListUser(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_USER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveEditRole = (data, closemodal) => (dispatch, getState) => {
	var body = {
		id: data.id,
		name: data.name,
		description: data.description,
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn sửa loại tài khoản này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_ROLE', payload: true});
			axios.post('/api/user/edit-role', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_ROLE', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListRole(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ROLE', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveEditUserMoney = (data, closemodal) => (dispatch, getState) => {
	var body = {
		id: data.user_id,
		calculation: data.user_calculation,
		money_tmp: data.user_money_tmp
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn sửa tài khoản này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_USER', payload: true});
			axios.post('/api/user/edit-money', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_USER', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListUser(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_USER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveAddUser = (data, closemodal) => (dispatch, getState) => {
	var body = {
		username: data.username,
		password: data.password,
		repeatpassword: data.repeatpassword,
		user_role: data.user_role,
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn thêm tài khoản này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_USER', payload: true});
			axios.post('/api/user/add', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_USER', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListUser(1, 10));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_USER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveAddRole = (data, closemodal) => (dispatch, getState) => {
	var body = {
		name: data.name,
		description: data.description,
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn thêm loại tài khoản này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_ROLE', payload: true});
			axios.post('/api/user/add-role', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_ROLE', payload: false});
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListRole(1, 10));
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ROLE', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveEditUserPassword = (data) => (dispatch, getState) => {
	var body = {
		password_old: data.password_old,
		password_new: data.password_new,
		repeatpassword_new: data.repeatpassword_new
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn đổi mật khẩu mới này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_USER', payload: true});
			axios.post('/api/user/change-password', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_USER', payload: false});
				if (res.data.status === 200) {
					dispatch(loadUser());
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_USER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveEditUserInformation = (data) => (dispatch, getState) => {
	var body = {
		fullname: data.fullname,
		email: data.email,
		birthday: data.birthday,
		gender: data.gender,
		phonenumber: data.phonenumber,
		address: data.address
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn sửa thông tin cá nhân này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_USER', payload: true});
			axios.post('/api/user/edit-user-information', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_USER', payload: false});
				if (res.data.status === 200) {
					dispatch(loadUser());
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_USER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const saveEditUserSocial = (data) => (dispatch, getState) => {
	var body = {
		facebook: data.facebook,
		instagram: data.instagram,
		tiktok: data.tiktok,
		twitter: data.twitter,
		telegram: data.telegram
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn sửa liên hệ này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_USER', payload: true});
			axios.post('/api/user/edit-user-social', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_USER', payload: false});
				if (res.data.status === 200) {
					dispatch(loadUser());
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_USER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const loginSocialUser = (data) => (dispatch, getState) => {
	dispatch({
		type: 'LOGIN_SUCCESS',
		payload: data
	});
	// dispatch(loadUser());
	setTimeout(() => {
		window.location.href = "/";
	}, 500);
};

const enable2FA = () => (dispatch, getState) => {
	var body = {};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn bật 2FA này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_USER', payload: true});
			axios.post('/api/user/enable-2fa', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_USER', payload: false});
				if (res.data.status === 200) {
					dispatch(loadUser());
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_USER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const disable2FA = () => (dispatch, getState) => {
	var body = {};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn tắt 2FA này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_USER', payload: true});
			axios.post('/api/user/disable-2fa', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_USER', payload: false});
				if (res.data.status === 200) {
					dispatch(loadUser());
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_USER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

const confirm2FA = (data) => (dispatch, getState) => {
	var body = {
		confirm_verifycode: data.confirm_verifycode
	};
	Swal.fire({
		title: t("Bạn có chắc?"),
		text: t("Bạn có chắc chắn muốn kích hoạt 2FA này?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Đồng ý"),
		cancelButtonText: t("Không")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_USER', payload: true});
			axios.post('/api/user/confirm-2fa', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_USER', payload: false});
				if (res.data.status === 200) {
					dispatch(loadUser());
					Swal.fire(t("Thành công"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Lỗi"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_USER', payload: false});
				Swal.fire(t("Lỗi"), t("Có lỗi xảy ra!"), 'error');
			});
		}
	})
};

export {
	loadUser,
	loginUser,
	registerUser,
	logoutUser,
	getListUser,
	getListRole,
	deleteUser,
	deleteRole,
	saveEditUserRole,
	saveEditRole,
	saveEditUserMoney,
	saveAddUser,
	saveAddRole,
	saveEditUserPassword,
	saveEditUserInformation,
	saveEditUserSocial,
	loginSocialUser,
	enable2FA,
	disable2FA,
	confirm2FA
}
