import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { saveEditUserMoney } from "actions/userActions";
import Loading from "components/theme_default/Loading";
import { FormatterMoney } from "components/theme_default/Function";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaleditusermoney(props) {
    const [user_id] = useState(props.edituser.id);
    const [user_money] = useState(props.edituser.money);
    var [user_calculation, setUserCalculation] = useState("+");
    var [user_money_tmp, setUserMoneyTmp] = useState("0");
    var [user_result, setUserResult] = useState(props.edituser.money);
    var { isOpenModalEditUserMoney, openModal, saveEditUserMoney } = props;
    var { isLoading } = props.user;

	const onClickSaveEditUserMoney = () => {
		saveEditUserMoney({ user_id, user_money, user_calculation, user_money_tmp, user_result, page: props.edituser.page, sizePerPage: props.edituser.sizePerPage, searchText: props.edituser.searchText }, openModal);
	};

	const onOpenModal = () => {
		openModal();
	};

	const onChange = (e) => {
		if (e.target.name === "user_calculation") {
			user_calculation = e.target.value;
		}
		if (e.target.name === "user_money_tmp") {
			user_money_tmp = e.target.value;
		}

		if (user_calculation === "+") {
			user_result = parseFloat(user_money) + parseFloat(user_money_tmp);
		}
		if (user_calculation === "-") {
			user_result = parseFloat(user_money) - parseFloat(user_money_tmp);
		}

        setUserCalculation(user_calculation);
        setUserMoneyTmp(user_money_tmp);
        setUserResult(user_result);
	};
	
	const onKeyPress = (e) => {
		if (e.charCode !== 46 && !(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => {onOpenModal()}} isOpen={isOpenModalEditUserMoney}>
                <Loading isLoading={isLoading}/>
                <div className="modal-header">
                    <p className="mb-0 text-dark">{t("Sửa tiền #")}{user_id}</p>
                    <button onClick={() => {onOpenModal()}} type="button" className="close">×</button>
                </div>
                <ModalBody>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-2 col-form-label">{t("Số dư hiện tại:")}</label>
                        <label className="col-sm-12 col-md-2 col-form-label"><FormatterMoney money={user_money}/></label>
                        <div className="col-sm-12 col-md-2 input-group bootstrap-touchspin al-input-group">
                            <select onChange={(e) => {onChange(e)}} value={user_calculation} name="user_calculation" className="form-control">
                                <option value="+">+</option>
                                <option value="-">-</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <input className="form-control" value={user_money_tmp} type="text" name="user_money_tmp" placeholder={t("1000")} onChange={(e) => {onChange(e)}} onKeyPress={(e) => {onKeyPress(e)}}/>
                        </div>
                        <label className="col-sm-12 col-md-4 col-form-label">= <FormatterMoney money={user_result}/></label>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveEditUserMoney()}} >{t("Lưu")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => {onOpenModal()}} >{t("Huỷ")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaleditusermoney.propTypes = {
	saveEditUserMoney: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
	user: state.user
});
const mapDispatchToProps = {
	saveEditUserMoney
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaleditusermoney);