import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import PropTypes from "prop-types";
import { saveAddService } from "actions/serviceActions";
import Loading from "components/theme_default/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaladdservice(props) {
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [social, setSocial] = useState(0);
    const [service_type, setServiceType] = useState(0);
    var { isOpenModalAddService, openModal, saveAddService } = props;
    var { isLoading } = props.service;

	const onClickSaveAddService = () => {
		saveAddService({ name, url, social, service_type }, openModal);
	};

	const onOpenModal = () => {
		openModal();
	};

	const onChange = (e) => {
        if (e.target.name === "name") {
            setName(e.target.value);
        }
        if (e.target.name === "url") {
            setUrl(e.target.value);
        }
        if (e.target.name === "social") {
            setSocial(e.target.value);
        }
        if (e.target.name === "service_type") {
            setServiceType(e.target.value);
        }
	};

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => {onOpenModal()}} isOpen={isOpenModalAddService}>
                <Loading isLoading={isLoading}/>
                <div className="modal-header">
                    <p className="mb-0 text-dark">{t("Thêm dịch vụ")}</p>
                    <button onClick={() => {onOpenModal()}} type="button" className="close">×</button>
                </div>
                <ModalBody>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Tên dịch vụ")}</label>
                        <div className="col-sm-12 col-md-9">
                            <input className="form-control" value={name} type="text" name="name" placeholder="" onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("URL")}</label>
                        <div className="col-sm-12 col-md-9">
                            <input className="form-control" value={url} type="text" name="url" placeholder="" onChange={(e) => {onChange(e)}} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Mạng xã hội")}</label>
                        <div className="col-sm-12 col-md-9">
                            <select className="form-control" value={social} name="social" onChange={(e) => {onChange(e)}}>
                                <option value="0">{t("Facebook")}</option>
                                <option value="1">{t("Instagram")}</option>
                                <option value="2">{t("Youtube")}</option>
                                <option value="3">{t("Tiktok")}</option>
                                <option value="4">{t("Shopee")}</option>
                                <option value="5">{t("Google")}</option>
                                <option value="6">{t("Telegram")}</option>
                                <option value="7">{t("Twitter")}</option>
                                <option value="8">{t("Tripadvisor")}</option>
                                <option value="9">{t("FES")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-md-3 col-form-label">{t("Loại dịch vụ")}</label>
                        <div className="col-sm-12 col-md-9">
                            <select className="form-control" value={service_type} name="service_type" onChange={(e) => {onChange(e)}}>
                                <option value="0">{t("Like")}</option>
                                <option value="1">{t("Comment")}</option>
                                <option value="2">{t("Share")}</option>
                                <option value="3">{t("Like Comment")}</option>
                                <option value="4">{t("Follow")}</option>
                                <option value="5">{t("Like Fanpage")}</option>
                                <option value="6">{t("Đánh giá và Check in Fanpage")}</option>
                                <option value="7">{t("Mắt Livestream")}</option>
                                <option value="8">{t("View Video")}</option>
                                <option value="9">{t("View Story")}</option>
                                <option value="10">{t("Thành viên Group")}</option>
                                <option value="11">{t("Yêu Thích")}</option>
                                <option value="12">{t("Retweet")}</option>
                                <option value="13">{t("Comment và Hình ảnh")}</option>
                                <option value="14">{t("License key")}</option>
                                <option value="15">{t("Traffic")}</option>
                            </select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary" onClick={() => {onClickSaveAddService()}} >{t("Lưu")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => {onOpenModal()}} >{t("Huỷ")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaladdservice.propTypes = {
	saveAddService: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	service: state.service
});
const mapDispatchToProps = {
	saveAddService
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaladdservice);